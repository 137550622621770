import React, { useRef, useState } from 'react'
import "./ForgotPassword.scss"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { backEndIP } from '../MyConfiguration';

const ForgotPassword = (props) => {
    const { setuserCookie, userCookie,themeGlobal} = props;

    const OTPElementRef = useRef();
    const userEmailElementRef = useRef();
    const passwordElementRef = useRef();
    const confirmPasswordElementRef = useRef();
    const errorMessageElementRef = useRef();
    const navigate = useNavigate();


    const handleRegisterBtnClick = () => {
        let otp = OTPElementRef.current.value;
        let userEmail = userEmailElementRef.current.value;
        let password = passwordElementRef.current.value;
        let confirmPassword = confirmPasswordElementRef.current.value;
        // console.log(otp, password, confirmPassword)

        if (password.length > 5 && userEmail.length > 5) {
            if (password === confirmPassword && password.length >= 6) {
                errorMessageElementRef.current.style.opacity = 0;
                axios({
                    url: backEndIP + "/admin/change-password",
                    method: "POST",
                    headers: {
                    },
                    data: { useremail: userEmail, otp: otp, password: password }
                }).then(async (loginData) => {
                    if (loginData.data.status === "success") {
                        window.alert(loginData.data.message)
                    }
                    console.log(loginData.data)
                    navigate("/")

                }).catch((err) => {
                    window.alert(err.response.data.message)
                    console.log(err);
                })
            }
            else {
                if (password.length < 6) {
                    errorMessageElementRef.current.innerHTML = "minimum length of password should be 6"
                    errorMessageElementRef.current.style.opacity = 1;
                }
                else {
                    errorMessageElementRef.current.innerHTML = "password does not match"
                    errorMessageElementRef.current.style.opacity = 1;
                }

            }
        }
        else {

            errorMessageElementRef.current.innerHTML = "pls enter password"
            errorMessageElementRef.current.style.opacity = 1;
        }
    }
    const [isOTPSent, setIsOTPSent] = useState(false);
    const handleSendOTPBtnClick = () => {
        let userEmail = userEmailElementRef.current.value;
        if (!isOTPSent) {
            setIsOTPSent(true)
            axios({
                url: backEndIP + "/admin/get-otp",
                method: "POST",
                headers: {
                },
                data: { useremail: userEmail }
            }).then(async (loginData) => {
                if (loginData.data.status === "success") {
                    window.alert(loginData.data.message)
                }
                console.log(loginData.data)
                // navigate("/")
                
                setTimeout(() => {
                    setIsOTPSent(false)
                }, 60000);

            }).catch((err) => {
                window.alert(err.response.data.message)
                console.log(err);
                setIsOTPSent(false)
            })

        }
        else {
            window.alert("Otp Already sent to you email, If not recieved retry after 1 minute")
        }

    }

    return (
        <div className="forgotpassword-page" style={{ height: "100vh", width: "100vw" }}>
            <div className="forgotpassword-page__leftHalf">
            <div className="login-page-body__leftHalf__welcomeBox">
                    <img className="login-page-body__leftHalf__welcomeBox__logo"
                        src="clear sky logo mark.svg" alt="logo"
                    >
                    </img>
                    <div className="login-page-body__leftHalf__welcomeBox__welcomeMsg">Welcome to ClearSky</div>
                    <div className="login-page-body__leftHalf__welcomeBox__register-message">Reset Password</div>
                </div>
                <div className="forgotpassword-page__leftHalf__otp-container">

                    <input ref={userEmailElementRef} className="forgotpassword-page__leftHalf__otp-container__input-password" type={"email"} placeholder={"User Email Id"}></input>
                    {isOTPSent && <div className="forgotpassword-page__leftHalf__otp-container__spinner"></div>}
                    <button onClick={handleSendOTPBtnClick}
                        className="forgotpassword-page__leftHalf__otp-container__btn-getOTP">Get OTP</button>
                </div>
                <div className="forgotpassword-page__leftHalf__register-container">

                    <input ref={OTPElementRef} className="forgotpassword-page__leftHalf__register-container__input-email" type={"text"} placeholder={"OTP"}></input>
                    <input ref={passwordElementRef} className="forgotpassword-page__leftHalf__register-container__input-password" type={"password"} placeholder={"Password"}></input>
                    <input ref={confirmPasswordElementRef} className="forgotpassword-page__leftHalf__register-container__input-email" type={"password"} placeholder={"Confirm Password"}></input>
                    {/* <div className="login-page-body__leftHalf__login-container__forgotPassword">Forgot password?</div> */}
                    <button onClick={handleRegisterBtnClick}
                        className="forgotpassword-page__leftHalf__register-container__btn-SignIn">Change Password</button>
                    <div
                        className="forgotpassword-page__leftHalf__register-container__register-msg1">
                       
                        <span
                            className="forgotpassword-page__leftHalf__register-container__register-msg2"
                            onClick={() => { navigate("/") }}
                        >Login
                        </span>
                    </div>
                    <div ref={errorMessageElementRef}
                        className="forgotpassword-page__leftHalf__register-container__error-msg"
                    >message</div>
                </div>
            </div>
            <div className="forgotpassword-page__rightHalf">
                <img className="forgotpassword-page__rightHalf__img"
                    src="drone_in_ancient_cave_processed.png" alt="img"></img>
            </div>
        </div>
    )
}

export default ForgotPassword