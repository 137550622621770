import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { VictoryLabel, VictoryPie, VictoryTheme, VictoryTooltip } from 'victory';

const dataMap = {
  "Number of forward flights": 1,
  "Number of reverse flights": 2,
  "Number of Test flights": 3,
  "Number of Order flights": 4,
}

const calculateFlightData = (tableData) => {
  const forward = tableData[dataMap["Number of forward flights"]].value;
  const reverse = tableData[dataMap["Number of reverse flights"]].value;
  const Test = tableData[dataMap["Number of Test flights"]].value;
  const Order = tableData[dataMap["Number of Order flights"]].value;

  return [forward, reverse, Test, Order]

  // return [
  //   { x: "Forward", y: forward },
  //   { x: "Return", y: reverse },
  //   { x: "Test", y: Test },
  //   { x: "Order", y: Order },
  // ]
}

const AllFlightsPieChart = ({ tableData, SizeMultiplier }) => {

  const [pieAllFlightsData, setPieAllFlightsData] = useState(calculateFlightData(tableData))

  useEffect(() => {
    const data = calculateFlightData(tableData);
    // console.log("tableData", tableData, data)
    setPieAllFlightsData(data)
  }, [tableData])



  return (
    <div className='FlightDataViewer-container__viewer-cont__charts__pie'>
      <Doughnut data={
      {
        labels: ['Forward', 'Return', 'Test', 'Order'],
        datasets: [
          {
            label: 'All Flights',
            data: pieAllFlightsData,
            backgroundColor: [
              'rgba(54, 162, 235, 1)',
              'rgba(255, 99, 132, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
            ],
            borderColor: [
              'rgba(54, 162, 235, 1)',
              'rgba(255, 99, 132, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
            ],
            borderWidth: 1,
            cutout: '50%', // Creates the donut hole (cutout size as percentage of chart diameter)
          },
        ],
      }
    } options={{
      responsive: true,
      plugins: {

        legend: {
          position: 'center', // Positions: 'top', 'left', 'bottom', 'right'
          display: false
        },
        datalabels: {
          color: '#fff', // Text color on the pie sections
          formatter: (value, context) => {
            const label = context.chart.data.labels[context.dataIndex];
            return label; // Only show the label on the pie sections
          },
          font: {
            size: 12,
            weight: 'bold',
          },
          align: 'center', // Center the label inside the section
          anchor: 'center', // Anchor the label inside the section
        },
        title: {
          display: true,
          text: 'Custom Chart Title'
      },

        tooltip: {
          callbacks: {
            label: (tooltipItem) =>
              `${tooltipItem.raw} ${tooltipItem.label} Flights `,
          },
        },
      },
    }} />
      {/* <svg viewBox={`${-30 * SizeMultiplier['--heightmultiplier']} ${-15 * SizeMultiplier['--heightmultiplier']} ${450 * SizeMultiplier['--heightmultiplier']} ${450 * SizeMultiplier['--heightmultiplier']}`}>
        <VictoryPie
          standalone={false}
          labelPlacement="perpendicular"
          labelIndicator
          width={400 * SizeMultiplier['--widthmultiplier']}
          height={400 * SizeMultiplier['--heightmultiplier']}
          data={ pieAllFlightsData}
          innerRadius={80 * SizeMultiplier['--heightmultiplier']}
          labelRadius={160 * SizeMultiplier['--heightmultiplier']}
          theme={VictoryTheme.material}
          labels={({ datum }) =>
            `${datum.y} ${datum.x}\nFlights`
          }
          style={{
            data: {
              // fillOpacity: 0.9,
              // stroke: "#c43a31",
              // strokeWidth: 3,
            },
            labels: {
              fontSize: 20 * SizeMultiplier['--heightmultiplier'],
              fill: "#c43a31",
            },
          }}
          // labelComponent={<VictoryTooltip />}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontSize: 20 }}
          x={225 * SizeMultiplier['--widthmultiplier']}
          y={225 * SizeMultiplier['--heightmultiplier']}
          text="All Flights"
        />
      </svg> */}
    </div>
  )
}


export default AllFlightsPieChart