import React, { useEffect, useRef, useState } from 'react'
import './ChecklistItemRow.scss'

const InputTextnNumber = ({ item, index, socket, setUpdateList }) => {

  const handleInputValueChange = (e) => {
    console.log(e.target.value)
    item.value = e.target.value;
    setUpdateList(index)
  }

  return (
    <div className='ChecklistItemRow-InputTextnNumber-cont'>
      <div className='ChecklistItemRow-InputTextnNumber-cont__label'>{item.paraName}</div>:
      <input className='ChecklistItemRow-InputTextnNumber-cont__input' type={item.paraType}
        value={item.value} onChange={handleInputValueChange}></input>
    </div>

  )
}
const InputDropDown = ({ item, index, socket, setUpdateList }) => {
  console.log(item)
  const handleInputValueChange = (e) => {
    console.log(e.target.value)
    item.value = e.target.value;
    setUpdateList(index)
  }
  return (
    <div className='ChecklistItemRow-InputDropDown-cont'>
      <div className='ChecklistItemRow-InputDropDown-cont__label'>{item.paraName}</div>:
      <select className='ChecklistItemRow-InputDropDown-cont__selectInput' defaultValue={item.value}
        onChange={handleInputValueChange}>
        <option value={"Select"}>Select</option>
        {item.paraOptions.map((paraOps, i) => {
          return <option key={`${item.paraName}${paraOps}${i}`} value={paraOps}>{paraOps}</option>
        })}
      </select>
    </div>

  )
}
const ChecklistItemRow = ({ item, socket, url, index, setIsChanged, checklistUpdateObj, isEditable }) => {

  // console.log(item)
  const parametersContRef = useRef();
  const labelContRef = useRef();
  const [rowHeight, setRowHeight] = useState("100%");
  const [updateSelf, setUpdateSelf] = useState(false);

  const setUpdateList = (index) => {
    checklistUpdateObj[index] = item
    // console.log("checklistUpdateObj =", checklistUpdateObj)
    setIsChanged(true)
    setUpdateSelf(prev => !prev)
  }

  useEffect(() => {
    if (parametersContRef.current && labelContRef.current) {
      const max = Math.max(parametersContRef.current.clientHeight, labelContRef.current.clientHeight)
      setRowHeight(`${max}px`)
      // console.log("parametersContRef.current.clientHeight =", parametersContRef.current.clientHeight)
      // console.log("labelContRef.current.clientHeight =", labelContRef.current.clientHeight)
    }
  }, [parametersContRef.current, labelContRef.current])
  //style={{height:rowHeight}}

  return (

    <tr className='ChecklistItemRow-container__row'>
      <td className='ChecklistItemRow-container__row__SrNo' style={{ height: rowHeight }}>{index + 1}</td>
      <td className='ChecklistItemRow-container__row__confirm' style={{ height: rowHeight }}>
        <input type='checkbox' className='ChecklistItemRow-container__row__confirm__checkbox' //style={{height:"50px",width:"50px"}}
          checked={item.confirm}
          onChange={isEditable?(e) => {
            // console.log(e.target.checked)
            item.confirm = e.target.checked;
            setUpdateList(index);
          }:null}></input>
      </td>
      <td ref={labelContRef} className='ChecklistItemRow-container__row__label' style={{ height: rowHeight }}>{item.label}</td>
      <td ref={parametersContRef} className='ChecklistItemRow-container__row__parameters' style={item.parameters.length === 0 ? { height: rowHeight } : {}}>
        {item.parameters.map((para, i) => {
          if (isEditable) {
            if (para.paraType === "DropDown") {
              return <InputDropDown item={para} socket={socket} index={index} setUpdateList={setUpdateList} />
            }
            else {
              return <InputTextnNumber item={para} socket={socket} index={index} setUpdateList={setUpdateList} />
            }
          }
          else {
            return <div className='ChecklistItemRow-container__row__parameters2__notEditable'>
              <div className='ChecklistItemRow-container__row__parameters2__notEditable__name'>{para.paraName}</div>:
              <div className='ChecklistItemRow-container__row__parameters2__notEditable__value'>{para.value}</div>
            </div>
          }
        })}
        {item.parameters.length === 0 ? "-" : null}
      </td>
      <td className='ChecklistItemRow-container__row__notes' style={{ height: rowHeight }}>
        {isEditable?<textarea className='ChecklistItemRow-container__row__notes__inputArea' value={item.notes}
          placeholder='Add your comments here'
          onChange={isEditable?(e) => {
            // console.log(e.target.value);
            item.notes = e.target.value;
            setUpdateList(index);
          }:null}>
        </textarea>: <div className='ChecklistItemRow-container__row__notes__inputArea'style={{}}>{item.notes!==""?item.notes:"Empty"}</div>}
        
      </td>
    </tr>
  )
}

export default ChecklistItemRow