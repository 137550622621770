import { createSlice } from '@reduxjs/toolkit'
// const initialState= {
//     allOrders: [],
//     filterBy: "ALL",
//     duration: new Date()
// }
export const HelpContentDataSlice = createSlice({
    name: 'helpContentData',
    initialState: {
        allhelpContentPages: {},
        allhelpContentPagesLoading: "idle",
        allhelpContentTopics: {},
        allhelpContentTopicsLoading: "idle",
        allhelpPoints: {},
        allhelpPointsLoading: "idle",
        scrollTop: 0
    },
    reducers: {
        setAllhelpContentPages: (state, action) => {
            // console.log("setAllhelpContentPages", action.payload)
            state.allhelpContentPages = action.payload
        },

        setAllhelpContentPagesLoading: (state, action) => {
            // console.log("setAllhelpContentPagesLoading", action.payload)
            state.allhelpContentPagesLoading = action.payload;
        },
        setAllhelpContentTopics: (state, action) => {
            // console.log("setAllhelpContentTopics", action.payload)
            state.allhelpContentTopics = action.payload
        },
        setSpecificHelpContentTopics: (state, action) => {
            // console.log("setAllhelpContentTopics", action.payload)
            const payload = action.payload
            const tempData = { ...state.allhelpContentTopics }
            tempData[payload.pageId] = payload.data;
            state.allhelpContentTopics = tempData;
        },
        setAllhelpContentTopicsLoading: (state, action) => {
            // console.log("setAllhelpContentTopicsLoading", action.payload)
            state.allhelpContentTopicsLoading = action.payload;
        },
        setAllhelpPoints: (state, action) => {
            // console.log("setAllhelpPoints", action.payload)
            state.allhelpContentTopics = action.payload
        },
        updateHelpTopicPoints: (state, action) => {
            // console.log("setAllhelpPoints", action.payload)
            const tempAllPoints = { ...state.allhelpContentTopics };
            const payload = action.payload
            tempAllPoints[payload.topicId] = payload.data
            state.allhelpContentTopics = tempAllPoints
        },
        setAllhelpPointsLoading: (state, action) => {
            // console.log("setAllhelpPointsLoading", action.payload)
            state.allhelpContentTopicsLoading = action.payload;
        },
        setHelpPagesScrollTop: (state, action) => {
            // console.log("setHelpPagesScrollTop", action.payload)
            state.scrollTop = action.payload;
        },

    }
}
)

export const { setAllhelpContentPages, setAllhelpContentPagesLoading,
    setAllhelpContentTopics, setAllhelpContentTopicsLoading,
    setAllhelpPoints, setAllhelpPointsLoading,
    setHelpPagesScrollTop } = HelpContentDataSlice.actions
// // console.log(HelpContentDataSlice)
// export const selecthelpContentData = (state) => state.helpContentData
export const selectAllhelpContentPages = (state) => state.helpContentData.allhelpContentPages
export const selectAllhelpContentPagesLoading = (state) => state.helpContentData.allhelpContentPagesLoading
export const selectAllhelpContentTopics = (state) => state.helpContentData.allhelpContentTopics
export const selectAllhelpContentTopicsLoading = (state) => state.helpContentData.allhelpContentTopicsLoading
export const selectAllhelpPoints = (state) => state.helpContentData.allhelpPoints
export const selectAllhelpPointsLoading = (state) => state.helpContentData.allhelpPointsLoading
export const selectHelpPagesScrollTop = (state) => state.helpContentData.scrollTop

export default HelpContentDataSlice.reducer