import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import HubAddPopUp from "../HubAddPopUp/HubAddPopUp";
import { backEndIP } from "../MyConfiguration";
import "./CreateHubPanel.scss";

const InputFieldBox = ({ item, setNewHubData, }) => {
  const InputRef = useRef()
  const handleInputChange = () => {
    setNewHubData((prev) => {
      let obj = { ...prev };
      obj[item.key] = InputRef.current.value;
      return obj;
    });
  };

  return (
    <div className="CreateNewHubContainer__input-row-cont" key={item.key}>
      <div className="CreateNewHubContainer__input-row-cont__label"
      >{item.label}</div>
      <div className="CreateNewHubContainer__input-row-cont__colon"> : </div>
      <input ref={InputRef}
        className="CreateNewHubContainer__input-row-cont__input"
        type={item.type}
        onChange={handleInputChange}
      ></input>
    </div>
  );
};

const CreateHubPanel = ({ userCookie, setFormPopup, setAllHub, allHub, allLocations, handleNotifyAlert, SetLoadingVisibility, adminData }) => {
  const modalRef = useRef();
  const [newHubdata, setNewHubData] = useState([]);
  const [showAddPopup, setShowAddPopup] = useState(false)
  const [selectCountry, setSelectCountry] = useState(allLocations.countryNames[adminData.country]);
  const [selectZone, setSelectZone] = useState(allLocations.zonesNames[adminData.zone]);
  const [selectState, setSelectState] = useState(allLocations.stateNames[adminData.state]);
  const [userData, setUserData] = useState({})
  //console.log(newHubdata);
  const content = {
    outurl: "/hub/add_hub",
    input: [{ label: "Latitude", key: "latitude", type: "number" },
    { label: "Longitude", key: "longitude", type: "number" },
    { label: "Location Name", key: "location_name", type: "text" },
    { label: "District", key: "district", type: "text" },
    { label: "Full Address", key: "address", type: "text" },
    { label: "Pin code", key: "pin_code", type: "number" },
    { label: "Tag Name", key: "tag_name", type: "text" }],
    drop_down: [{ label: "Country", key: "country_id", inurl: "/country/get-country" },
    { label: "Zone", key: "zone_id", inurl: "/zone/get_zone" },
    { label: "State", key: "state_id", inurl: "/state/get_state" }]
  }

  const closeModal = e => {
    if (modalRef.current === e.target) {
      //window.location.reload(false)
      //setSelectedTab("All Flights")
      setFormPopup(false);
    }
  };

  // console.log("New Hub Data", newHubdata)
  //console.log("Country", selectCountry)
 // console.log("Zone", allLocations)
  const handleCreateNewHub = () => {
    //console.log(newHubdata);
    SetLoadingVisibility(true)
    // console.log(newHubdata, selectState)
    axios({
      url: backEndIP + "/hub/add_hub",
      method: "POST",
      headers: {
        authorization: userCookie.token,
      },
      data: { ...newHubdata },
    })
      .then((resp) => {
        SetLoadingVisibility(false)
        setFormPopup(false);
        handleNotifyAlert(
          "notify",
          `Hub Added successfully`,
          "success",
          () => { }
        );
        // console.log(resp.data);
        //setShowAddPopup(true);
        setAllHub([...allHub, resp.data.data])
      })
      .catch((err) => {
        // if (err.response.data.message) window.alert(err.response.data.message);
        // else window.alert("some error occurered");
        // console.log(err);
        console.log(err);
        SetLoadingVisibility(false);
        setFormPopup(false);
        handleNotifyAlert(
          "alert",
          `${err.response.message}`,
          "failed",
          () => { }
        );
      });
  }
  // console.log("Country", selectCountry)
  console.log("All Locatin", allLocations)
  // console.log("userCookie", userCookie)


  return (
    <div onClick={closeModal} ref={modalRef} className="CreateNewHubBackground">
      <div className="CreateNewHubContainer">
        <div className="CreateNewHubContainer__form">Add New Hub</div>
        <div className="CreateNewHubContainer__subContainer">
          <div className="CreateNewHubContainer__left">
            {content.input.map((item) => {
              return <InputFieldBox key={item.key} item={item} setNewHubData={setNewHubData} userCookie={userCookie}></InputFieldBox>
            })}
          </div>
          {/* {content.drop_down.map((item) => {
                return <InputDropDownBox key={item.key} item={item} setInfoToSend={setInfoToSend} userCookie={userCookie}></InputDropDownBox>
            })} */}
          <div className="CreateNewHubContainer__right">
            <div className="CreateNewHubContainer__field">
              <div className="CreateNewHubContainer__field__title">Country</div>
              <div className="CreateNewHubContainer__field__colon">:</div>
              <div className="CreateNewHubContainer__field__value">
                <select className="CreateNewHubContainer__field__value__option"
                  onChange={(e) => {
                    if (e.currentTarget.value !== "") {
                      setSelectCountry(allLocations.countryNames[e.currentTarget.value])
                      setNewHubData((prev) => {
                        // console.log("select", selectCountry)
                        let obj = { ...prev };
                        obj[content.drop_down[0].key] = e.target.value;
                        return obj;
                      });
                    } else {
                      setSelectCountry(allLocations.countryNames[adminData.country]);
                      setSelectZone(allLocations.zonesNames[adminData.zone]);
                      setSelectState(allLocations.stateNames[adminData.state]);
                    }
                    // console.log(e.currentTarget.value);
                  }}>
                  <option value={""} className="CreateNewHubContainer__field__value__option">{selectCountry}</option>
                  {allLocations.country.map((item, i) => {
                    return <option value={item._id} key={i+1} className="CreateNewHubContainer__field__value__option">{item.name}</option>
                  })}
                </select>
              </div>
            </div>
             <div className="CreateNewHubContainer__field">
              <div className="CreateNewHubContainer__field__title">Zone</div>
              <div className="CreateNewHubContainer__field__colon">:</div>
              <div className="CreateNewHubContainer__field__value">
                <select className="CreateNewHubContainer__field__value__option"
                  onChange={(e) => {
                    if (e.currentTarget.value !== "") {
                      setSelectZone(allLocations.zonesNames[e.currentTarget.value])
                      setNewHubData((prev) => {
                        // console.log("select", selectZone)
                        let obj = { ...prev };
                        obj[content.drop_down[1].key] = e.target.value;
                        return obj;
                      });
                    }
                    else {
                      setSelectZone(allLocations.zonesNames[adminData.zone]);
                      setSelectState(allLocations.stateNames[adminData.state]);
                    }
                    // console.log(e.currentTarget.value);
                  }}>
                  <option className="CreateNewHub-container__field__value__option" value={""}>{selectZone}</option>
                  {allLocations.zones.map((item, i) => {
                    return <option value={item._id} key={i+1} className="CreateNewHub-container__field__value__option">{item.name}</option>
                  })}
                </select>
              </div>
            </div>
            <div className="CreateNewHubContainer__field">
              <div className="CreateNewHubContainer__field__title">State</div>
              <div className="CreateNewHubContainer__field__colon">:</div>
              <div className="CreateNewHubContainer__field__value">
                <select className="CreateNewHubContainer__field__value__option"
                  onChange={(e) => {
                    if (e.currentTarget.value !== "") {
                      setSelectState(allLocations.stateNames[e.currentTarget.value])
                      setNewHubData((prev) => {
                        // console.log("select", selectState)
                        let obj = { ...prev };
                        obj[content.drop_down[2].key] = e.target.value;
                        return obj;
                      });
                    }
                    else {
                      setSelectState(allLocations.stateNames[adminData.state]);
                    }
                    // console.log(e.currentTarget.value);
                  }}>
                  <option value={""} className="CreateNewHubContainer__field__value__option">{selectState}</option>
                  {allLocations.states[selectZone].map((item, i) => {
                    return <option value={item._id} key={i+1} className="CreateNewHubContainer__field__value__option">{item.name}</option>
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="CreateNewHubContainer__bottom">
          {selectState !== "" && <button className="CreateNewHubContainer__bottom__createHubBtn"
            onClick={handleCreateNewHub}
          >
            Create Hub
          </button>}
        </div>
      </div>

      {/* {showAddPopup ? <HubAddPopUp setOpenModal={setShowAddPopup} setSelectedTab={setSelectedTab} messageToDisplay={{ message: "New Hub Added Succesfully" , func: () => { } }} /> : null} */}
    </div>
  );
};

export default CreateHubPanel;
