import { Marker, MarkerClusterer } from '@react-google-maps/api'
import React from 'react'

const HubAndLocMarker = ({ allHubAndNodes, locationsTree, allLocationsData }) => {
    // console.log("{allHubAndNodes, locationsTree,allLocationsData}", { allHubAndNodes, locationsTree, allLocationsData })
    return (
        <>
            {Object.keys(locationsTree).map((hubId) => {
                return <MarkerClusterer key={`MarkerClusterer${hubId}`} gridSize={100} minimumClusterSize={locationsTree[hubId].length > 1 ? locationsTree[hubId].length : 2}
                    options={
                        {
                            imagePath: './HubMarker',
                            imageExtension: "svg",
                            // clusterClass:"hub-marker-test-class",
                            // enableRetinaIcons:false
                            imageSizes: [50],
                            // styles:["hub-marker-test-class"]
                        }
                    }

                    title={`${allLocationsData[hubId].tag}:${allLocationsData[hubId].name}`}
                    calculator={() => {
                        return {
                            text: allLocationsData[hubId].name,
                            index: 0,
                            // title: allLocationsData[hubId].name,
                            html: `<div class="cluster-hub-marker-cont">
                                <p class="cluster-hub-marker">${allLocationsData[hubId].tag}:${allLocationsData[hubId].name}
                                </p>
                            </div>`
                        }

                    }}
                >
                    {
                        (clusterer) =>
                            locationsTree[hubId].map((location, i) => {
                                // console.log("allLocationsData[location]", location, allLocationsData[location])
                                if (allLocationsData[location].tag === "HUB") {
                                    return <Marker cursor={"default"} clickable={false} zIndex={5} label={{ text: `${allLocationsData[location].tag}:${allLocationsData[location].name}`, className: "hub-markerLabelCss" }} key={`marker${allLocationsData[location].tag}${i}`} position={{ lat: allLocationsData[location].lat, lng: allLocationsData[location].lng }}
                                        clusterer={clusterer} icon={{
                                            url: "./HubMarkerYellow.svg", anchor: new window.google.maps.Point(17, 40),
                                        }} />
                                }
                                else {
                                    return <Marker cursor={"default"} clickable={false} zIndex={4} label={{ text: `${allLocationsData[location].tag}:${allLocationsData[location].name}`, className: "markerLabelCss" }} key={`marker${allLocationsData[location].tag}${i}`} position={{ lat: allLocationsData[location].lat, lng: allLocationsData[location].lng }}
                                        clusterer={clusterer} icon={{ url: "./NodeMarker.svg", anchor: new window.google.maps.Point(17, 40) }} />
                                }

                            })
                    }
                </MarkerClusterer>
            })}

        </>

    )
}

export default HubAndLocMarker