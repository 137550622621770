import React, { useEffect, useRef, useState } from "react";
import Lottie from 'react-lottie-player'
import "./LoadingScreen.scss";
import lottieJson from './loadingAnimation.json'
function LoadingScreen({}) {


  return (
    <div className="LoadingModalBackground">
      {/* <div className="LoadingScreen__modalContainer" >
      </div> */}
      <Lottie
      speed={2}
      loop
      animationData={lottieJson}
      play
      style={{ width: "100%", height: "100%" }}
    />
    </div>

  );
}

export default LoadingScreen;
