const allForms = {
    "Create Flight": {
        outurl: "/flight/flight_details",
        input: [{ label: "Add Mission File", key: "mission_file_name", type: "text" },
        { label: "Payload", key: "payload", type: "number" }, { label: "Order No", key: "order_no", type: "text" }],
        drop_down: [
            { label: "Type of Flight", key: "flight_type", options: ["Test Flight", "Order Flight", "Order Return Flight"] },
            { label: "Start Location", key: "start_location",options:[], inurl: "/admin/get_hub_location" },
            { label: "End Location", key: "end_location",options:[], inurl: "/location/get_location" },
            { label: "Select Drone", key: "drone_id",options:[], inurl: "/drone/get_drone" },
        ],
        date: [{ label: "Start Time", key: "start_time" }, { label: "End Time", key: "end_time" }]
    },
    "Orders": {
        inurl: "/order/get-location-orders",
        input: [],
        drop_down: []
    },
    "All Flights": {
        url: "",
        input: [],
        drop_down: []
    },
    "Get Flight": {
        url: "",
        input: [],
        drop_down: []
    }
}

module.exports = { allForms };