import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import "./ModelsDetailsPage.scss";

function ModelDetailPage({ setDetailsPopUp, modelDetails }) {
  const modalRef = useRef();

  const closeModal = e => {
    if (modalRef.current === e.target) {
        setDetailsPopUp();
    }
  };
  const list = {
    "date": "Date",
  }
  const CompileContent = (data) => {
    if(list[data]==="Date"){
      return dayjs(modelDetails[data]).format("YYYY-MM-DD HH:mm").toString();
    }
    // else if(list[data]==="Location"){
    //   return [modelDetails[data]]
    // }
    // else if(list[data]==="time"){
    //   return dayjs().set('hour',0).set('minute',0).set('second',0).add(modelDetails[data],'second').format('HH [Hours] : mm [Minutes] : ss [Seconds]');
    // }
    return modelDetails[data];
  }

  const [models, setModels] = useState([])
  const model_Data = {
    "_id": "Model ID",
    "full_model_name": "Model Full Name",
    "drone_type": "Drone Type",
    "vertical_propulsion_type": "Vertical Propulsion Type",
    "forward_propulsion": "Forward Propulsion Type",
    "payload_capacity": "Payload Capacity",
    "weight": "Model Weight",
    "length": "Model Length",
    "width": "Model Width",
    "height": "Model Height",
    "max_range": "Maximum Range",
    "short_name": "Short Name",
    "version": "Model Version",
    "date": "Date Of Creation",
    "noOfDrones": "No of Drones Present",
    "current_flight_id": "Current Flight Id",
  }
  useEffect(() => {
    const ownFlight = [];
    const keys = Object.keys(modelDetails);
    keys.forEach(key => {
      ownFlight.push([model_Data[key], CompileContent(key)])
    });
    ownFlight.pop()
    setModels(ownFlight)


  }, [modelDetails])

  console.log(modelDetails)
  return (
    <div onClick={closeModal} ref={modalRef} className="FlightDetailsPopup-modalBackground">
      <div className="FlightDetailsPopup-modalContainer" >
        {models.map((item) => {
          return <div className="FlightDetailsPopup-modalContainer__item">
            <div className="FlightDetailsPopup-modalContainer__item__title">{item[0]}</div>
            :
            <div className="FlightDetailsPopup-modalContainer__item__value"> {item[1]}</div>
            </div>
        })}

        <div className="FlightDetailsPopup-modalContainer__OkayBtn" onClick={() => { modalRef.current.click() }}>OK</div>
      </div>
    </div>
  );
}

export default ModelDetailPage;
