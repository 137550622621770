import { createSlice } from '@reduxjs/toolkit'

export const HubNodeLocationsSlice = createSlice({
    name: 'hubNodeLocations',
    initialState: { entities: {}, loading: 'idle' },
    reducers: {
        setHubNodeLocations: (state, action) => {
            // console.log("setHubNodeLocations", action.payload)
            state.entities = action.payload;
            state.loading = "done"
        },
        setHubNodeLocationsLoading: (state, action) => {
            // console.log("setHubNodeLocationsLoading", action.payload)
            state.loading = action.payload;
        }
    }
})

export const { setHubNodeLocations,setHubNodeLocationsLoading } = HubNodeLocationsSlice.actions;
export const selectHubNodeLocations = (state)=>state.hubNodeLocations.entities;
export const selectHubNodeLocationsLoading = (state)=>state.hubNodeLocations.loading;
export default HubNodeLocationsSlice.reducer;