import { createSlice } from '@reduxjs/toolkit'
// const initialState= {
//     allOrders: [],
//     filterBy: "ALL",
//     duration: new Date()
// }
export const ordersDataSlice = createSlice({
    name: 'allOrdersData',
    initialState:{
        allOrders: [],
        filterBy: "ALL",
        duration: "date",
        scrollTop:0
    },
    reducers: {
        setAllOrdersData: (state, action) => {
            // console.log("setAllOrdersData", action.payload)
            state.allOrders = action.payload
        },
        updateSpecificOrder: (state, action) => {
            // console.log("updateSpecificOrder", action.payload)
            const tempOrders = [...state.allOrders]
            for (let i = 0; i < tempOrders.length; i++) {
                // console.log(i)
                if (tempOrders[i].order_no === action.payload.order_no) {
                    tempOrders[i] = action.payload
                    // console.log(tempOrders)
                    break;
                }
            }
            state.allOrders = tempOrders
        },
        addNewOrderToAllOrders: (state, action) => {
            // console.log("addNewOrderToAllOrders", action.payload)
            const tempOrders = [action.payload,...state.allOrders];
            state.allOrders = tempOrders;
        },
        setOrderFilterBy: (state, action) => {
            // console.log("setOrdersFilterBy", action.payload)
            state.filterBy = action.payload;
        },
        setOrderDuration: (state, action) => {
            // console.log("setOrdersDuration", action.payload)
            state.duration = action.payload;
        },
        setOrdersScrollTop:(state, action) => {
            // console.log("setOrdersScrollTop", action.payload)
            state.scrollTop = action.payload;
        }

    }
})

export const { setAllOrdersData, setOrderFilterBy, setOrderDuration,updateSpecificOrder,setOrdersScrollTop,addNewOrderToAllOrders } = ordersDataSlice.actions
// console.log(ordersDataSlice)
export const selectAllOrdersData = (state) => state.allOrdersData
export const selectAllOrders = (state) => state.allOrdersData.allOrders
export const selectOrdersFilterBy= (state) => state.allOrdersData.filterBy
export const selectOrdersDuration = (state) => state.allOrdersData.duration
export const selectOrdersScrollTop = (state) => state.allOrdersData.scrollTop
export default ordersDataSlice.reducer