export const commandParametersList = {
  WAYPOINT: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  TAKEOFF: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  LAND: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  LAND_LOCAL: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  TAKEOFF_LOCAL: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  RETURN_TO_LAUNCH: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  LOITER_UNLIM: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  LOITER_TURNS: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  LOITER_TIME: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  FOLLOW: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  CONTINUE_AND_CHANGE_ALT: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  LOITER_TO_ALT: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_FOLLOW: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_FOLLOW_REPOSITION: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  ROI: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  PATHPLANNING: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  SPLINE_WAYPOINT: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  ALTITUDE_WAIT: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  VTOL_TAKEOFF: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  VTOL_LAND: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  GUIDED_ENABLE: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DELAY: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  PAYLOAD_PLACE: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  LAST: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  CONDITION_DELAY: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  CONDITION_CHANGE_ALT: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  CONDITION_DISTANCE: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  CONDITION_YAW: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  CONDITION_LAST: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_SET_MODE: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_JUMP: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_CHANGE_SPEED: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_SET_HOME: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_SET_PARAMETER: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_SET_RELAY: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_REPEAT_RELAY: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_SET_SERVO: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_REPEAT_SERVO: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_FLIGHTTERMINATION: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_CHANGE_ALTITUDE: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_LAND_START: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_RALLY_LAND: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_GO_AROUND: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_REPOSITION: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_PAUSE_CONTINUE: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_SET_REVERSE: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_SET_ROI_LOCATION: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_SET_ROI_WPNEXT_OFFSET: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_SET_ROI_NONE: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_SET_ROI_SYSID: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_CONTROL_VIDEO: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_SET_ROI: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_DIGICAM_CONFIGURE: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_DIGICAM_CONTROL: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_MOUNT_CONFIGURE: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_MOUNT_CONTROL: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_SET_CAM_TRIGG_DIST: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_FENCE_ENABLE: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_PARACHUTE: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_MOTOR_TEST: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_INVERTED_FLIGHT: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_GRIPPER: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_AUTOTUNE_ENABLE: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  SET_YAW_SPEED: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_SET_CAM_TRIGG_INTERVAL: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_SET_RESUME_REPEAT_DIST: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_SPRAYER: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_SEND_SCRIPT_MESSAGE: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_AUX_FUNCTION: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_MOUNT_CONTROL_QUAT: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_GUIDED_MASTER: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_GUIDED_LIMITS: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_ENGINE_CONTROL: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_SET_MISSION_CURRENT: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  DO_LAST: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  PREFLIGHT_CALIBRATION: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  PREFLIGHT_SET_SENSOR_OFFSETS: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  PREFLIGHT_UAVCAN: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  PREFLIGHT_STORAGE: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
  PREFLIGHT_REBOOT_SHUTDOWN: ["P1", "P2", "P3", "Yaw", "Lat", "Long", "Alt"],
};
export const commandNames = Object.keys(commandParametersList);

export const commandMacroNumbers = {
  WAYPOINT: 16,
  TAKEOFF: 22,
  LAND: 21,
  LAND_LOCAL: 23,
  TAKEOFF_LOCAL: 24,
  RETURN_TO_LAUNCH: 20,
  LOITER_UNLIM: 17,
  LOITER_TURNS: 18,
  LOITER_TIME: 19,
  FOLLOW: 25,
  CONTINUE_AND_CHANGE_ALT: 30,
  LOITER_TO_ALT: 31,
  DO_FOLLOW: 32,
  DO_FOLLOW_REPOSITION: 33,
  ROI: 80,
  PATHPLANNING: 81,
  SPLINE_WAYPOINT: 82,
  ALTITUDE_WAIT: 83,
  VTOL_TAKEOFF: 84,
  VTOL_LAND: 85,
  GUIDED_ENABLE: 92,
  DELAY: 93,
  PAYLOAD_PLACE: 94,
  LAST: 95,
  CONDITION_DELAY: 112,
  CONDITION_CHANGE_ALT: 113,
  CONDITION_DISTANCE: 114,
  CONDITION_YAW: 115,
  CONDITION_LAST: 159,
  DO_SET_MODE: 176,
  DO_JUMP: 177,
  DO_CHANGE_SPEED: 178,
  DO_SET_HOME: 179,
  DO_SET_PARAMETER: 180,
  DO_SET_RELAY: 181,
  DO_REPEAT_RELAY: 182,
  DO_SET_SERVO: 183,
  DO_REPEAT_SERVO: 184,
  DO_FLIGHTTERMINATION: 185,
  DO_CHANGE_ALTITUDE: 186,
  DO_LAND_START: 189,
  DO_RALLY_LAND: 190,
  DO_GO_AROUND: 191,
  DO_REPOSITION: 192,
  DO_PAUSE_CONTINUE: 193,
  DO_SET_REVERSE: 194,
  DO_SET_ROI_LOCATION: 195,
  DO_SET_ROI_WPNEXT_OFFSET: 196,
  DO_SET_ROI_NONE: 197,
  DO_SET_ROI_SYSID: 198,
  DO_CONTROL_VIDEO: 200,
  DO_SET_ROI: 201,
  DO_DIGICAM_CONFIGURE: 202,
  DO_DIGICAM_CONTROL: 203,
  DO_MOUNT_CONFIGURE: 204,
  DO_MOUNT_CONTROL: 205,
  DO_SET_CAM_TRIGG_DIST: 206,
  DO_FENCE_ENABLE: 207,
  DO_PARACHUTE: 208,
  DO_MOTOR_TEST: 209,
  DO_INVERTED_FLIGHT: 210,
  DO_GRIPPER: 211,
  DO_AUTOTUNE_ENABLE: 212,
  SET_YAW_SPEED: 213,
  DO_SET_CAM_TRIGG_INTERVAL: 214,
  DO_SET_RESUME_REPEAT_DIST: 215,
  DO_SPRAYER: 216,
  DO_SEND_SCRIPT_MESSAGE: 217,
  DO_AUX_FUNCTION: 218,
  DO_MOUNT_CONTROL_QUAT: 220,
  DO_GUIDED_MASTER: 221,
  DO_GUIDED_LIMITS: 222,
  DO_ENGINE_CONTROL: 223,
  DO_SET_MISSION_CURRENT: 224,
  DO_LAST: 240,
  PREFLIGHT_CALIBRATION: 241,
  PREFLIGHT_SET_SENSOR_OFFSETS: 242,
  PREFLIGHT_UAVCAN: 243,
  PREFLIGHT_STORAGE: 245,
  PREFLIGHT_REBOOT_SHUTDOWN: 246,
};
export const commandMacroNames = {
  16: "WAYPOINT",
  17: "LOITER_UNLIM",
  18: "LOITER_TURNS",
  19: "LOITER_TIME",
  20: "RETURN_TO_LAUNCH",
  21: "LAND",
  22: "TAKEOFF",
  23: "LAND_LOCAL",
  24: "TAKEOFF_LOCAL",
  25: "FOLLOW",
  30: "CONTINUE_AND_CHANGE_ALT",
  31: "LOITER_TO_ALT",
  32: "DO_FOLLOW",
  33: "DO_FOLLOW_REPOSITION",
  80: "ROI",
  81: "PATHPLANNING",
  82: "SPLINE_WAYPOINT",
  83: "ALTITUDE_WAIT",
  84: "VTOL_TAKEOFF",
  85: "VTOL_LAND",
  92: "GUIDED_ENABLE",
  93: "DELAY",
  94: "PAYLOAD_PLACE",
  95: "LAST",
  112: "CONDITION_DELAY",
  113: "CONDITION_CHANGE_ALT",
  114: "CONDITION_DISTANCE",
  115: "CONDITION_YAW",
  159: "CONDITION_LAST",
  176: "DO_SET_MODE",
  177: "DO_JUMP",
  178: "DO_CHANGE_SPEED",
  179: "DO_SET_HOME",
  180: "DO_SET_PARAMETER",
  181: "DO_SET_RELAY",
  182: "DO_REPEAT_RELAY",
  183: "DO_SET_SERVO",
  184: "DO_REPEAT_SERVO",
  185: "DO_FLIGHTTERMINATION",
  186: "DO_CHANGE_ALTITUDE",
  189: "DO_LAND_START",
  190: "DO_RALLY_LAND",
  191: "DO_GO_AROUND",
  192: "DO_REPOSITION",
  193: "DO_PAUSE_CONTINUE",
  194: "DO_SET_REVERSE",
  195: "DO_SET_ROI_LOCATION",
  196: "DO_SET_ROI_WPNEXT_OFFSET",
  197: "DO_SET_ROI_NONE",
  198: "DO_SET_ROI_SYSID",
  200: "DO_CONTROL_VIDEO",
  201: "DO_SET_ROI",
  202: "DO_DIGICAM_CONFIGURE",
  203: "DO_DIGICAM_CONTROL",
  204: "DO_MOUNT_CONFIGURE",
  205: "DO_MOUNT_CONTROL",
  206: "DO_SET_CAM_TRIGG_DIST",
  207: "DO_FENCE_ENABLE",
  208: "DO_PARACHUTE",
  209: "DO_MOTOR_TEST",
  210: "DO_INVERTED_FLIGHT",
  211: "DO_GRIPPER",
  212: "DO_AUTOTUNE_ENABLE",
  213: "SET_YAW_SPEED",
  214: "DO_SET_CAM_TRIGG_INTERVAL",
  215: "DO_SET_RESUME_REPEAT_DIST",
  216: "DO_SPRAYER",
  217: "DO_SEND_SCRIPT_MESSAGE",
  218: "DO_AUX_FUNCTION",
  220: "DO_MOUNT_CONTROL_QUAT",
  221: "DO_GUIDED_MASTER",
  222: "DO_GUIDED_LIMITS",
  223: "DO_ENGINE_CONTROL",
  224: "DO_SET_MISSION_CURRENT",
  240: "DO_LAST",
  241: "PREFLIGHT_CALIBRATION",
  242: "PREFLIGHT_SET_SENSOR_OFFSETS",
  243: "PREFLIGHT_UAVCAN",
  245: "PREFLIGHT_STORAGE",
  246: "PREFLIGHT_REBOOT_SHUTDOWN",
};

export const commandActualParametersList = {
  WAYPOINT: {
    P1: "Hold(sec)",
    P2: "Accept Radius(m)",
    P3: "Pass Radius(m)",
    Yaw: "Yaw(deg)",
    Lat: "Latitude",
    Long: "Longitude",
    Alt: "Altitude(m)",
  },
  TAKEOFF: {
    P1: "Pitch(deg)",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Yaw(deg)",
    Lat: "Latitude",
    Long: "Longitude",
    Alt: "Altitude(m)",
  },
  LAND: {
    P1: "Abort Alt(m)",
    P2: "Land Mode(0/1/2)",
    P3: "Pass Radius(m)",
    Yaw: "Yaw(deg)",
    Lat: "Latitude",
    Long: "Longitude",
    Alt: "Altitude(m)",
  },
  LAND_LOCAL: {
    P1: "Target",
    P2: "Offset(m)",
    P3: "Descend Rate(m/s)",
    Yaw: "Yaw(deg)",
    Lat: "Latitude",
    Long: "Longitude",
    Alt: "Altitude(m)",
  },
  TAKEOFF_LOCAL: {
    P1: "Pitch(deg)",
    P2: "Empty",
    P3: "Ascend Rate(m/s)",
    Yaw: "Yaw(deg)",
    Lat: "Latitude",
    Long: "Longitude",
    Alt: "Altitude(m)",
  },
  RETURN_TO_LAUNCH: {
    P1: "Empty",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  LOITER_UNLIM: {
    P1: "Empty",
    P2: "Empty",
    P3: "Radius(m)",
    Yaw: "Yaw(deg)",
    Lat: "Latitude",
    Long: "Longitude",
    Alt: "Altitude(m)",
  },
  LOITER_TURNS: {
    P1: "Turns",
    P2: "Heading Required(1/0)",
    P3: "Radius(m)",
    Yaw: "Xtrack Location(deg)",
    Lat: "Latitude",
    Long: "Longitude",
    Alt: "Altitude(m)",
  },
  LOITER_TIME: {
    P1: "Time(sec)",
    P2: "Heading Required(1/0)",
    P3: "Radius(m)",
    Yaw: "Xtrack Location(deg)",
    Lat: "Latitude",
    Long: "Longitude",
    Alt: "Altitude(m)",
  },
  FOLLOW: {
    P1: "Following",
    P2: "Ground Speed(m/s)",
    P3: "Radius(m)",
    Yaw: "Xtrack Location(deg)",
    Lat: "Latitude",
    Long: "Longitude",
    Alt: "Altitude(m)",
  },
  CONTINUE_AND_CHANGE_ALT: {
    P1: "Action:[Climb or Descend (0 = Neutral, command completes when within 5m of this command's altitude, 1 = Climbing, command completes when at or above this command's altitude, 2 = Descending, command completes when at or below this command's altitude.]",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Altitude(m)",
  },
  LOITER_TO_ALT: {
    P1: "Heading Required:[Leave loiter circle only once heading towards the next waypoint (0 = False) (1 =True)]",
    P2: "Radius(m)",
    P3: "Empty",
    Yaw: "Xtrack Location(deg)",
    Lat: "Latitude",
    Long: "Longitude",
    Alt: "Altitude(m)",
  },
  DO_FOLLOW: {
    P1: "System ID:[System ID (of the FOLLOW_TARGET beacon). Send 0 to disable follow-me and return to the default position hold mode.(min:0, max:255)]",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Altitude Mode:[Altitude mode: 0: Keep current altitude, 1: keep altitude difference to target, 2: go to a fixed altitude above home.]",
    Lat: "Altitude(m)",
    Long: "Empty",
    Alt: "Time to Land(sec)",
  },
  DO_FOLLOW_REPOSITION: {
    P1: "Camera Q1(where 0 is on the ray from the camera to the tracking device)",
    P2: "Camera Q2(where 0 is on the ray from the camera to the tracking device)",
    P3: "Camera Q3(where 0 is on the ray from the camera to the tracking device)",
    Yaw: "Camera Q4(where 0 is on the ray from the camera to the tracking device)",
    Lat: "Altitude Offset(m)",
    Long: "X Offset(m)",
    Alt: "Y Offset(m)",
  },
  ROI: {
    P1: "ROI Mode:[Region of interest mode.]",
    P2: "WP Index",
    P3: "ROI Index",
    Yaw: "Empty",
    Lat: "X:[x the location of the fixed ROI (see MAV_FRAME)]",
    Long: "Y",
    Alt: "Z",
  },
  PATHPLANNING: {
    P1: "Local Ctrl:[0: Disable local obstacle avoidance / local path planning (without resetting map), 1: Enable local path planning, 2: Enable and reset local path planning]",
    P2: "Global Ctrl:[0: Disable full path planning (without resetting map), 1: Enable, 2: Enable and reset map/occupancy grid, 3: Enable and reset planned route, but not occupancy grid]",
    P3: "Empty",
    Yaw: "Yaw(deg)",
    Lat: "Latitude/X",
    Long: "Longitude/Y",
    Alt: "Altitude/Z",
  },
  SPLINE_WAYPOINT: {
    P1: "Hold(sec)",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Latitude/X",
    Long: "Longitude/Y",
    Alt: "Altitude/Z",
  },
  ALTITUDE_WAIT: 83,
  VTOL_TAKEOFF: {
    P1: "Empty",
    P2: "Transition Heading",
    P3: "Empty",
    Yaw: "Yaw(deg)",
    Lat: "Latitude",
    Long: "Longitude",
    Alt: "Altitude(m)",
  },
  VTOL_LAND: {
    P1: "Land Options",
    P2: "Empty",
    P3: "Approach Altitude(m)",
    Yaw: "Yaw(deg)",
    Lat: "Latitude",
    Long: "Longitude",
    Alt: "Altitude(m)",
  },
  GUIDED_ENABLE: {
    P1: "Enable:[On / Off (> 0.5f on)(min:0 max:1)]",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DELAY: {
    P1: "Delay:[Delay (-1 to enable time-of-day fields)](sec)",
    P2: "Hour:[hour (24h format, UTC, -1 to ignore)]",
    P3: "Minute:[minute (24h format, UTC, -1 to ignore)]",
    Yaw: "Second:[second (24h format, UTC, -1 to ignore)]",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  PAYLOAD_PLACE: {
    P1: "Max Descent(m)",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Latitude",
    Long: "Longitude",
    Alt: "Altitude(m)",
  },
  LAST: {
    P1: "Empty",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  CONDITION_DELAY: {
    P1: "Delay(sec)",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  CONDITION_CHANGE_ALT: {
    P1: "Rate(Descent / Ascend rate.)(m/s)",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Target Altitude",
  },
  CONDITION_DISTANCE: {
    P1: "Distance(m)",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  CONDITION_YAW: {
    P1: "Angle:[target angle, 0 is north](deg)",
    P2: "Angular Speed(deg/s)",
    P3: "Direction:[-1: counter clockwise, 1: clockwise]",
    Yaw: "Relative:[0: absolute angle, 1: relative offset]",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  CONDITION_LAST: {
    P1: "Empty",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_SET_MODE: {
    P1: "Mode",
    P2: "Custom mode - this is system specific, please refer to the individual autopilot specifications for details.",
    P3: "Custom sub mode - this is system specific, please refer to the individual autopilot specifications for details.",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_JUMP: {
    P1: "Sequence number",
    P2: "Repeat count",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_CHANGE_SPEED: {
    P1: "	Speed type (0=Airspeed, 1=Ground Speed, 2=Climb Speed, 3=Descent Speed)",
    P2: "Speed (-1 indicates no change, -2 indicates return to default vehicle speed)(m/s)",
    P3: "Throttle (-1 indicates no change, -2 indicates return to default vehicle throttle value)%",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_SET_HOME: {
    P1: "Use current (1=use current location, 0=use specified location)",
    P2: "Repeat count",
    P3: "Empty",
    Yaw: "Yaw(deg)",
    Lat: "Latitude",
    Long: "Longitude",
    Alt: "Altitude(m)",
  },
  DO_SET_PARAMETER: {
    P1: "Parameter number",
    P2: "Parameter value",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_SET_RELAY: {
    P1: "Relay instance number.",
    P2: "	Setting. (1=on, 0=off, others possible depending on system hardware)",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_REPEAT_RELAY: {
    P1: "Relay instance number.",
    P2: "Cycle count.",
    P3: "	Cycle time.(sec)",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_SET_SERVO: {
    P1: "Servo instance number.",
    P2: "Pulse Width Modulation.(us)",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_REPEAT_SERVO: {
    P1: "Servo instance number.",
    P2: "Pulse Width Modulation.(us)",
    P3: "Cycle count.",
    Yaw: "Cycle time.(sec)",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_FLIGHTTERMINATION: {
    P1: "Flight termination activated if > 0.5. Otherwise not activated and ACK with MAV_RESULT_FAILED.",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_CHANGE_ALTITUDE: {
    P1: "Altitude(m)",
    P2: "Frame of new altitude.",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_LAND_START: {
    P1: "Empty",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Latitude",
    Long: "Longitude",
    Alt: "Altitude(m)",
  },
  DO_RALLY_LAND: {
    P1: "Break altitude(m)",
    P2: "Landing speed(m/s)",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_GO_AROUND: {
    P1: "Altitude(m)",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_REPOSITION: {
    P1: "Ground speed, less than 0 (-1) for default-(m/s)",
    P2: "Bitmask of option flags.",
    P3: "Radius(m):[	Loiter radius for planes. Positive values only, direction is controlled by Yaw value. A value of zero or NaN is ignored.]",
    Yaw: "Yaw(deg)",
    Lat: "Latitude",
    Long: "Longitude",
    Alt: "Altitude(m)",
  },
  DO_PAUSE_CONTINUE: {
    P1: "	0: Pause current mission or reposition command, hold current position. 1: Continue mission. A VTOL capable vehicle should enter hover mode (multicopter and VTOL planes). A plane should loiter with the default loiter radius.",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_SET_REVERSE: {
    P1: "Direction (0=Forward, 1=Reverse)",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_SET_ROI_LOCATION: {
    P1: "Gimbal device ID:[Component ID of gimbal device to address (or 1-6 for non-MAVLink gimbal), 0 for all gimbal device components. Send command multiple times for more than one gimbal (but not all gimbals).]",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Latitude of ROI location",
    Long: "Longitude of ROI location",
    Alt: "Altitude of ROI location",
  },
  DO_SET_ROI_WPNEXT_OFFSET: {
    P1: "Gimbal device ID:[Component ID of gimbal device to address (or 1-6 for non-MAVLink gimbal), 0 for all gimbal device components. Send command multiple times for more than one gimbal (but not all gimbals).]",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Pitch Offset:[Pitch offset from next waypoint, positive pitching up]",
    Long: "Roll Offset:[Roll offset from next waypoint, positive rolling to the right]",
    Alt: "Yaw Offset:[Yaw offset from next waypoint, positive yawing to the right]",
  },
  DO_SET_ROI_NONE: {
    P1: "Gimbal device ID:[Component ID of gimbal device to address (or 1-6 for non-MAVLink gimbal), 0 for all gimbal device components. Send command multiple times for more than one gimbal (but not all gimbals).]",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_SET_ROI_SYSID: {
    P1: "System ID(min:1 max:255)",
    P2: "Gimbal device ID:[Component ID of gimbal device to address (or 1-6 for non-MAVLink gimbal), 0 for all gimbal device components. Send command multiple times for more than one gimbal (but not all gimbals).]",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_CONTROL_VIDEO: {
    P1: "Camera ID (-1 for all)",
    P2: "Transmission: 0: disabled, 1: enabled compressed, 2: enabled raw",
    P3: "Interval:[Transmission mode: 0: video stream, >0: single images every n seconds]",
    Yaw: "Recording: 0: disabled, 1: enabled compressed, 2: enabled raw",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_SET_ROI: {
    P1: "ROI Mode:Region of interest mode.]",
    P2: "	Waypoint index/ target ID (depends on param 1).",
    P3: "	Region of interest index. (allows a vehicle to manage multiple ROI's)",
    Yaw: "Empty",
    Lat: "MAV_ROI_WPNEXT: pitch offset from next waypoint, MAV_ROI_LOCATION: latitude",
    Long: "MAV_ROI_WPNEXT: roll offset from next waypoint, MAV_ROI_LOCATION: longitude",
    Alt: "MAV_ROI_WPNEXT: yaw offset from next waypoint, MAV_ROI_LOCATION: altitude",
  },
  DO_DIGICAM_CONFIGURE: {
    P1: "Modes: P, TV, AV, M, Etc.",
    P2: "Shutter speed: Divisor number for one second.",
    P3: "Aperture: F stop number.",
    Yaw: "ISO number e.g. 80, 100, 200, Etc.",
    Lat: "Exposure type enumerator.",
    Long: "Command Identity.",
    Alt: "Main engine cut-off time before camera trigger. (0 means no cut-off)",
  },
  DO_DIGICAM_CONTROL: {
    P1: "	Session control e.g. show/hide lens",
    P2: "Zoom Absolute:Zoom's absolute position",
    P3: "Zoom Relative: Zooming step value to offset zoom from the current position",
    Yaw: "Focus:Focus Locking, Unlocking or Re-locking",
    Lat: "Shooting Command",
    Long: "Command Identity",
    Alt: "Shot ID:Test shot identifier. If set to 1, image will only be captured, but not counted towards internal frame count.",
  },
  DO_MOUNT_CONFIGURE: {
    P1: "Mode:Mount operation mode",
    P2: "stabilize roll? (1 = yes, 0 = no)",
    P3: "stabilize pitch? (1 = yes, 0 = no)",
    Yaw: "stabilize yaw? (1 = yes, 0 = no)",
    Lat: "roll input (0 = angle body frame, 1 = angular rate, 2 = angle absolute frame)",
    Long: "pitch input (0 = angle body frame, 1 = angular rate, 2 = angle absolute frame)",
    Alt: "yaw input (0 = angle body frame, 1 = angular rate, 2 = angle absolute frame)",
  },
  DO_MOUNT_CONTROL: {
    P1: "Pitch:pitch depending on mount mode (degrees or degrees/second depending on pitch input).",
    P2: "Roll:roll depending on mount mode (degrees or degrees/second depending on roll input).",
    P3: "Yaw:	yaw depending on mount mode (degrees or degrees/second depending on yaw input).",
    Yaw: "Altitude:	altitude depending on mount mode.",
    Lat: "latitude, set if appropriate mount mode.",
    Long: "longitude, set if appropriate mount mode.",
    Alt: "Mount mode.",
  },
  DO_SET_CAM_TRIGG_DIST: {
    P1: "Distance(m):Camera trigger distance. 0 to stop triggering.",
    P2: "Shutter(ms):Camera shutter integration time. -1 or 0 to ignore",
    P3: "Trigger:Trigger camera once immediately. (0 = no trigger, 1 = trigger)",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_FENCE_ENABLE: {
    P1: "	enable? (0=disable, 1=enable, 2=disable_floor_only)",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_PARACHUTE: {
    P1: "Action: 0=PARACHUTE_DISABLE,1=PARACHUTE_ENABLE ,2=PARACHUTE_RELEASE",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_MOTOR_TEST: {
    P1: "Motor instance number (from 1 to max number of motors on the vehicle).",
    P2: "Throttle type (whether the Throttle Value in param3 is a percentage, PWM value, etc.)",
    P3: "	Throttle value",
    Yaw: "Timeout between tests that are run in sequence.",
    Lat: "Motor count. Number of motors to test in sequence: 0/1=one motor, 2= two motors, etc. The Timeout (param4) is used between tests.",
    Long: "Motor test order.[0/1/2]",
    Alt: "Empty",
  },
  DO_INVERTED_FLIGHT: {
    P1: "Inverted flight. (0=normal, 1=inverted)",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_GRIPPER: {
    P1: "Gripper instance number.",
    P2: "Gripper action to perform.[1=Grab, 0= Release]",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_AUTOTUNE_ENABLE: {
    P1: "	Enable (1: enable, 0:disable).",
    P2: "Specify which axis are autotuned. 0 indicates autopilot default settings.[(0/1/2/3)==(default/Roll/Pitch/Yaw)",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  SET_YAW_SPEED: {
    P1: "Yaw angle to adjust steering by.(deg)",
    P2: "Speed(m/s)",
    P3: "Final angle. (0=absolute, 1=relative)",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_SET_CAM_TRIGG_INTERVAL: {
    P1: "Trigger Cycle: [Camera trigger cycle time. -1 or 0 to ignore.]",
    P2: "Shutter Integration:[Camera shutter integration time. Should be less than trigger cycle time. -1 or 0 to ignore.]",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_SET_RESUME_REPEAT_DIST: 215,
  DO_SPRAYER: 216,
  DO_SEND_SCRIPT_MESSAGE: 217,
  DO_AUX_FUNCTION: 218,
  DO_MOUNT_CONTROL_QUAT: {
    P1: "quaternion param q1, w (1 in null-rotation)",
    P2: "quaternion param q2, x (0 in null-rotation)",
    P3: "quaternion param q3, y (0 in null-rotation)",
    Yaw: "quaternion param q4, z (0 in null-rotation)",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_GUIDED_MASTER: {
    P1: "System ID:min:0 max:255",
    P2: "Component ID:min:0 max:255",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_GUIDED_LIMITS: {
    P1: "Timeout(sec) - maximum time that external controller will be allowed to control vehicle. 0 means no timeout.",
    P2: "Altitude (MSL) min - if vehicle moves below this alt, the command will be aborted and the mission will continue. 0 means no lower altitude limit.",
    P3: "Altitude (MSL) max - if vehicle moves above this alt, the command will be aborted and the mission will continue. 0 means no upper altitude limit.",
    Yaw: "Horizontal move limit - if vehicle moves more than this distance from its location at the moment the command was executed, the command will be aborted and the mission will continue. 0 means no horizontal move limit.",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_ENGINE_CONTROL: {
    P1: "Start Engine: [0: Stop engine, 1:Start Engine]",
    P2: "Cold Start:[0: Warm start, 1:Cold start. Controls use of choke where applicable]",
    P3: "Height delay. This is for commanding engine start only after the vehicle has gained the specified height. Used in VTOL vehicles during takeoff to start engine after the aircraft is off the ground. Zero for no delay.",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_SET_MISSION_CURRENT: {
    P1: "Mission sequence value to set. -1 for the current mission item (use to reset mission without changing current mission item).",
    P2: 'Resets mission. 1: true, 0: false. Resets jump counters to initial values and changes mission state "completed" to be "active" or "paused".',
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  DO_LAST: {
    P1: "Empty",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  PREFLIGHT_CALIBRATION: {
    P1: "Gyro Temperature- [1: gyro calibration, 3: gyro temperature calibration]",
    P2: "Magnetometer- [1: magnetometer calibration]",
    P3: "Ground Pressure- [1: ground pressure calibration]",
    Yaw: "Remote Control- [1: radio RC calibration, 2: RC trim calibration]",
    Lat: "Accelerometer - [1: accelerometer calibration, 2: board level calibration, 3: accelerometer temperature calibration, 4: simple accelerometer calibration]",
    Long: "Compmot or Airspeed- [1: APM: compass/motor interference calibration (PX4: airspeed calibration, deprecated), 2: airspeed calibration]",
    Alt: "ESC or Baro- [1: ESC calibration, 3: barometer temperature calibration]",
  },
  PREFLIGHT_SET_SENSOR_OFFSETS: {
    P1: "Sensor Type:[Sensor to adjust the offsets for: 0: gyros, 1: accelerometer, 2: magnetometer, 3: barometer, 4: optical flow, 5: second magnetometer, 6: third magnetometer]",
    P2: "X offset: [X axis offset (or generic dimension 1), in the sensor's raw units]",
    P3: "y offset:[Y axis offset (or generic dimension 2), in the sensor's raw units]",
    Yaw: "Z offset:[Z axis offset (or generic dimension 3), in the sensor's raw units]",
    Lat: "Generic dimension 4, in the sensor's raw units",
    Long: "Generic dimension 5, in the sensor's raw units",
    Alt: "Generic dimension 6, in the sensor's raw units",
  },
  PREFLIGHT_UAVCAN: {
    P1: "Actuator Id :[1: Trigger actuator ID assignment and direction mapping. 0: Cancel command.]",
    P2: "Empty",
    P3: "Empty",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  PREFLIGHT_STORAGE: {
    P1: "Parameter Storage :[Action to perform on the persistent parameter storage]",
    P2: "Mission Storage: [Action to perform on the persistent mission storage]",
    P3: "Logging Rate[Hz] : [Onboard logging: 0: Ignore, 1: Start default rate logging, -1: Stop logging, > 1: logging rate (e.g. set to 1000 for 1000 Hz logging)] ",
    Yaw: "Empty",
    Lat: "Empty",
    Long: "Empty",
    Alt: "Empty",
  },
  PREFLIGHT_REBOOT_SHUTDOWN: {
    P1: "Autopilot: [0: Do nothing for autopilot, 1: Reboot autopilot, 2: Shutdown autopilot, 3: Reboot autopilot and keep it in the bootloader until upgraded.]",
    P2: "Companion: [0: Do nothing for onboard computer, 1: Reboot onboard computer, 2: Shutdown onboard computer, 3: Reboot onboard computer and keep it in the bootloader until upgraded.]",
    P3: "Component action: [0: Do nothing for component, 1: Reboot component, 2: Shutdown component, 3: Reboot component and keep it in the bootloader until upgraded]",
    Yaw: "Component ID: [MAVLink Component ID targeted in param3 (0 for all components).](0to 255)",
    Lat: "Reserved (set to 0)",
    Long: "Reserved (set to 0)",
    Alt: "WIP: ID (e.g. camera ID -1 for all IDs)",
  },
};
