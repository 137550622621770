import React, { useEffect, useState } from 'react';
import "./DronesModelTab.scss"
import dayjs from 'dayjs';
import AddNewModelForm from '../AddNewModel/AddNewModelForm';
import ModelDetailPage from '../ModelDetailspage/ModelsDetailsPage';
import axios from 'axios';
import { backEndIP } from '../MyConfiguration';


const DroneModelTab = ({ userCookie, setSelectedTab, SetLoadingVisibility, handleNotifyAlert }) => {
    const [addModelForm, setAddModelForm] = useState(false)
    const [detailsPopUp, setDetailsPopUp] = useState(false)
    const [selectedModel, setSelectedModel] = useState({})
    const [allModels, setAllModels] = useState([]);

    const handleAddModelButton = () => {
        setAddModelForm(true)
    }
    useEffect(() => {
        axios({
            url: backEndIP + "/dronemodel/get_drone_model",
            // url: backEndIP + "/user/login",
            method: "GET",
            headers: {
                authorization: userCookie.token
            },
        }).then((modelResp) => {
            setAllModels(modelResp.data.data);
            // console.log(allModels)
        }).catch((err) => {
            console.log(err);
        })
        // console.log(employeesResp.data.data);

    }, [])

    return (
        <div className='MainModelFlight'>
            <div className='ModelTabContent-container'>

                <div className='ModelTabContent-datacontainer' >
                    <div className='ModelTabContent-container__index'>
                        <div className='ModelTabContent-container__index__modelNo ModelTabContent-container__index__term'>
                            Model No
                        </div>
                        <div className='ModelTabContent-container__index__fullName ModelTabContent-container__index__term'>
                            Full Name
                        </div>
                        <div className='ModelTabContent-container__index__droneType ModelTabContent-container__index__term' >
                            Drone Type
                        </div>
                        <div className='ModelTabContent-container__index__verticalPro ModelTabContent-container__index__term'>
                            Vertical Propulsion Type
                        </div>
                        <div className='ModelTabContent-container__index__forwardPro ModelTabContent-container__index__term'>
                            Forward Propulsion Type
                        </div>
                        <div className='ModelTabContent-container__index__payload ModelTabContent-container__index__term' >
                            Payload Capacity(kg)
                        </div>
                        <div className='ModelTabContent-container__index__version ModelTabContent-container__index__term'>
                            Version
                        </div>
                        <div className='ModelTabContent-container__index__shortName ModelTabContent-container__index__term'>
                            Short Name
                        </div>
                        <div className='ModelTabContent-container__index__noOfD ModelTabContent-container__index__term'>
                            Drones Present
                        </div>
                        <div className='ModelTabContent-container__index__dateTime ModelTabContent-container__index__term' >
                            Creation Date
                        </div>

                    </div>
                    {allModels.map((model, i) => {
                        //console.log(allModels, i)
                        const modelV = model.version % 10;
                        const secondDigit = model.version / 10
                        const version = secondDigit + "." + modelV
                        return <div key={model._id} className='ModelTabContent-datacontainer__row' onClick={() => { setDetailsPopUp(true); setSelectedModel(model) }}>
                            <div className='ModelTabContent-datacontainer__row__model ModelTabContent-datacontainer__row__term'>
                                {i + 1}
                            </div>
                            <div className='ModelTabContent-datacontainer__row__fullName ModelTabContent-datacontainer__row__term'>
                                {model.full_model_name}
                            </div>
                            <div className='ModelTabContent-datacontainer__row__droneType ModelTabContent-datacontainer__row__term'>
                                {model.drone_type === "V" ? "VTOL" : "MULTIROTOR"}
                            </div>
                            <div className='ModelTabContent-datacontainer__row__verticalPro ModelTabContent-datacontainer__row__term'>
                                {model.vertical_propulsion_type === "E" ? "Electric Engine" : "IC Engine"}
                            </div>
                            <div className='ModelTabContent-datacontainer__row__forwardPro ModelTabContent-datacontainer__row__term'>
                                {model.forward_propulsion === "E" ? "Electric Engine" : "IC Engine"}
                            </div>
                            <div className='ModelTabContent-datacontainer__row__payload ModelTabContent-datacontainer__row__term'>
                                {model.payload_capacity}Kg
                            </div>
                            <div className='ModelTabContent-datacontainer__row__version ModelTabContent-datacontainer__row__term'>
                                {version}
                            </div>
                            <div className='ModelTabContent-datacontainer__row__shortName ModelTabContent-datacontainer__row__term'>
                                {model.short_name}
                            </div>
                            <div className='ModelTabContent-datacontainer__row__noOfD ModelTabContent-datacontainer__row__term'>
                                {model.noOfDrones}
                            </div>
                            <div className='ModelTabContent-datacontainer__row__dateTime ModelTabContent-datacontainer__row__term'>
                                {dayjs(model.date).format("YYYY-MM-DD h:mm:ss").toString()}
                            </div>
                        </div>
                    })}
                </div>
            </div>
            <div className='AddNewModelButton'><button className='AddNewModelButton__Btn' onClick={() => { handleAddModelButton() }}>Add New Model</button></div>
            {addModelForm ? <AddNewModelForm setFormPopup={setAddModelForm} allModels={allModels} userCookie={userCookie} setAllModels={setAllModels} SetLoadingVisibility={SetLoadingVisibility} handleNotifyAlert={handleNotifyAlert} /> : null}
            {detailsPopUp && <ModelDetailPage setDetailsPopUp={() => { setSelectedTab("Drone Models"); setDetailsPopUp(false) }} modelDetails={selectedModel} />}

        </div>
    )
}

export default DroneModelTab