import React, { useRef, useEffect } from "react";
import "./AddNewModelForm.scss";
import { useState } from "react";
import axios from "axios";
import { backEndIP } from "../MyConfiguration";

const InputDropDownBox = ({ item, setModelData }) => {
    const InputRef = useRef();
    //console.log(item.key)
    const handleInputChange = () => {
        setModelData((prev) => {
            let obj = { ...prev };
            obj[item.key] = InputRef.current.value[0];
            return obj;
        });
        // setModelData({ ...item, key: InputRef.current.value })
    };
    useEffect(() => {
        setModelData((prev) => {
            let obj = { ...prev };
            obj[item.key] = item.options[0];
            return obj;
        });
    }, [])

    return (<div className="addNewModal-content-container__dropdown-row-cont">
        <div className="addNewModal-content-container__dropdown-row-cont__label">{item.label}</div>
        <select className="addNewModal-content-container__dropdown-row-cont__input"
            style={{ outline: "none", border: "none" }}
            onChange={handleInputChange}
            ref={InputRef}
        >
            <option />
            {item.options.map((op, i) => {
                return <option key={op + i} value={op}>{op}</option>
            })
            }
        </select>
    </div>)
}




const AddNewModelForm = ({ allModels, setFormPopup, userCookie, setAllModels, setSelectedTab, handleNotifyAlert, SetLoadingVisibility }) => {
    const modalRef = useRef();
    const [modelData, setModelData] = useState({
        "drone_type": "",
        "vertical_propulsion_type": "",
        "forward_propulsion": "",
        "payload_capacity": 0,
        "version": 0,
        "weight": 0,
        "length": 0,
        "width": 0,
        "height": 0,
        "max_range": 0,
        "short_name": ""

    })

    const closeModal = e => {
        if (modalRef.current === e.target) {
            //window.location.reload(false)
            //setSelectedTab("All Flights")
            setFormPopup(false);
        }
    };

    const content = {
        input: [
            { label: "Payload Capacity (grams)", key: "payload_capacity", type: "number", maxLength: 2, minLength: 2 },
            { label: "Short Name", key: "short_name", type: "text", maxLength: 4, minLength: 4 },
            { label: "Version", key: "version", type: "number", maxLength: 2, minLength: 2 }],
        drop_down: [
            { label: "Type of Drone", key: "drone_type", options: ["VTOL", "MULTIROTOR"] },
            { label: "Vertical Propulsion Type", key: "vertical_propulsion_type", options: ["Electric Engine", "IC Engine"] },
            { label: "Forward Propulsion Type", key: "forward_propulsion", options: ["Electric Engine", "IC Engine"] }
        ],
    }
    const handelModelSubmit = () => {
        // console.log(modelData);
        // console.log(userCookie.token)
        SetLoadingVisibility(true)
        axios({
            url: backEndIP + "/dronemodel/drone_model_type",
            method: "POST",
            headers: {
                authorization: userCookie.token,
            },
            data: modelData,
        })
            .then((resp) => {
                SetLoadingVisibility(false)
                setFormPopup(false);
                handleNotifyAlert(
                    "notify",
                    `Model Added successfully`,
                    "success",
                    () => { }
                );
                //console.log(data.data.data)
                setAllModels([...allModels, resp.data.data]);
              // setFormPopup(false);

            })
            .catch((err) => {
                // if (err.response.data.message) window.alert(err.response.data.message);
                // else window.alert("some error occurered");
                // console.log(err);
                console.log(err);
                SetLoadingVisibility(false);
                setFormPopup(false);
                handleNotifyAlert(
                  "alert",
                  `${err.response.message}`,
                  "failed",
                  () => { }
                );
            });
    }
    // const handelOnChange = (e, i)=>{
    //     console.log(e.target.value.toUpperCase())
    //     console.log(e.target.value)
    //     setModelData({...modelData, [i]: e.target.value.toUpperCase()})
    // } drone Model Id: 6388b1c206acb72552678c64
    //console.log(infoToSend)
    // console.log(modelData)
    return (
        <div onClick={closeModal} ref={modalRef} className="addNewModalBackground">
            <div className="addNewModalContainer" >
                <div className="addNewModal-content-container">
                <div className="addNewModal-content-container__form">Add Drone Model</div>
                {content.drop_down.map((drop) => {
                    // console.log(drop)
                    return <InputDropDownBox key={drop.label}
                        item={drop}
                        setModelData={setModelData}></InputDropDownBox>
                })}
                {/* {content.input.map((item) => {
                    return <InputFieldBox key={item.key} item={item} maxlength={item.maxLength} minLength={item.minLength} setInfoToSend={setInfoToSend} setModelData={setModelData} userCookie={userCookie}></InputFieldBox>
                })} */}
                <div className="addNewModal-content-container__input-row-cont">
                    <div className="addNewModal-content-container__input-row-cont__label"
                    >Payload Capacity</div>
                    <div className="addNewModal-content-container__input-row-cont__colon"> : </div>
                    <input
                        className="addNewModal-content-container__input-row-cont__input"
                        type="string"
                        maxLength={4}
                        minLength={4}
                        onChange={(e) => { setModelData({ ...modelData, ["payload_capacity"]: Number(e.target.value) }) }}
                    ></input>
                </div>
                <div className="addNewModal-content-container__input-row-cont">
                    <div className="addNewModal-content-container__input-row-cont__label"
                    >Maximum Range</div>
                    <div className="addNewModal-content-container__input-row-cont__colon"> : </div>
                    <input
                        className="addNewModal-content-container__input-row-cont__input"
                        type="number"
                        onChange={(e) => { setModelData({ ...modelData, ["max_range"]: e.target.value }) }}
                    ></input>
                </div>
                <div className="addNewModal-content-container__input-row-cont">
                    <div className="addNewModal-content-container__input-row-cont__label"
                    >Weight</div>
                    <div className="addNewModal-content-container__input-row-cont__colon"> : </div>
                    <input
                        className="addNewModal-content-container__input-row-cont__input"
                        type="number"
                        onChange={(e) => { setModelData({ ...modelData, ["weight"]: e.target.value }) }}
                    ></input>
                </div>
                <div className="addNewModal-content-container__input-row-cont">
                    <div className="addNewModal-content-container__input-row-cont__label"
                    >Length</div>
                    <div className="addNewModal-content-container__input-row-cont__colon"> : </div>
                    <input
                        className="addNewModal-content-container__input-row-cont__input"
                        type="number"
                        onChange={(e) => { setModelData({ ...modelData, ["length"]: e.target.value }) }}
                    ></input>
                </div>
                <div className="addNewModal-content-container__input-row-cont">
                    <div className="addNewModal-content-container__input-row-cont__label"
                    >Width</div>
                    <div className="addNewModal-content-container__input-row-cont__colon"> : </div>
                    <input
                        className="addNewModal-content-container__input-row-cont__input"
                        type="number"
                        onChange={(e) => { setModelData({ ...modelData, ["width"]: e.target.value }) }}
                    ></input>
                </div>
                <div className="addNewModal-content-container__input-row-cont">
                    <div className="addNewModal-content-container__input-row-cont__label"
                    >Height</div>
                    <div className="addNewModal-content-container__input-row-cont__colon"> : </div>
                    <input
                        className="addNewModal-content-container__input-row-cont__input"
                        type="number"
                        onChange={(e) => { setModelData({ ...modelData, ["height"]: e.target.value }) }}
                    ></input>
                </div>
                <div className="addNewModal-content-container__input-row-cont">
                    <div className="addNewModal-content-container__input-row-cont__label"
                    >Short Name</div>
                    <div className="addNewModal-content-container__input-row-cont__colon"> : </div>
                    <input
                        className="addNewModal-content-container__input-row-cont__input"
                        type="string"
                        maxLength={5}
                        minLength={5}
                        onChange={(e) => { setModelData({ ...modelData, ["short_name"]: e.target.value.toUpperCase() }) }}
                    ></input>
                </div>
                <div className="addNewModal-content-container__input-row-cont">
                    <div className="addNewModal-content-container__input-row-cont__label"
                    >Version</div>
                    <div className="addNewModal-content-container__input-row-cont__colon"> : </div>
                    <input
                        className="addNewModal-content-container__input-row-cont__input"
                        type="string"
                        maxLength={2}
                        minLength={2}
                        onChange={(e) => { setModelData({ ...modelData, ["version"]: Number(e.target.value) }) }}
                    ></input>
                </div>
                <div className="addNewModal-content-container__submitButton">
                    <button className="addNewModal-content-container__submitButton__button" onClick={handelModelSubmit}>Submit</button>
                </div>
            </div>
            </div>
        </div>

    );
}

export default AddNewModelForm;
