import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import "./AssignPilot.scss";
import { backEndIP } from "../MyConfiguration";
import axios from "axios";

function AssignPilot({ setDetailsPopUp, setAllDrones, allDrones, droneTobeManage, userCookie, handleNotifyAlert, SetLoadingVisibility, locations }) {
  const modalRef = useRef();
  const [dronePilots, setDronePilots] = useState([])
  const location = droneTobeManage.hub_location
  const [updateValue, setUpdateValue] = useState({
    "internal_id": droneTobeManage.internal_id.toString(),
    "pilot_id1": "Unassigned",
    "pilot_id2": "Unassigned"
  })

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setDetailsPopUp();
    }
  };
  useEffect(() => {
    axios({
      url: backEndIP + "/admin/getAllPilot",
      // url: backEndIP + "/user/login",
      method: "GET",
      headers: {
        authorization: userCookie.token,
        location: location
      },
    }).then((resp) => {
      // console.log(resp.data)
      setDronePilots(resp.data.data);
      //console.log(hubLocation)
    }).catch((err) => {
      console.log(err);
    })
    // console.log(employeesResp.data.data);

  }, [])

  const handleDeployment = () => {
    // if (updateValue.pilot_id1 !== "Unassigned") {
    SetLoadingVisibility(true)
    axios({
      url: backEndIP + "/drone/drone_assignPilot",
      method: "POST",
      headers: {
        authorization: userCookie.token
      },
      data: updateValue,
    }).then((resp) => {
      SetLoadingVisibility(false)
      setDetailsPopUp();
      handleNotifyAlert(
        "notify",
        `${resp.data.message}`,
        "success",
        () => { }
      );
      // console.log(resp.data);
      setAllDrones(resp.data.data)
      // setDetailsPopUp();
    }).catch((err) => {
      console.log(err);
      SetLoadingVisibility(false);
      setDetailsPopUp();
      handleNotifyAlert(
        "alert",
        `${err.response.data.message}`,
        "failed",
        () => { }
      );
      // if (err.response.data.message) window.alert(err.response.message);
      // else window.alert("some error occurred");
    })
    // } else {
    //   SetLoadingVisibility(false);
    //   setDetailsPopUp(false);
    //   handleNotifyAlert(
    //     "alert",
    //     `Please Select Pilot`,
    //     "failed",
    //     () => { }
    //   );
    // window.alert("Please Select Pilot")
    // }
  }

  // const locations = {
  //   "63932c7ab1cab628335ae969": "Jengjal",
  //   "63930f82865ec3abed90bc1b": "Mendipathar PHC",
  //   "639310eb865ec3abed90bc1f": "Pedaldoba PHC",
  //   "639311e2865ec3abed90bc23": "Nongalbibra PHC",
  //   "6393126c865ec3abed90bc27": "Shallang PHC",
  //   "639312ff865ec3abed90bc2b": "Samanda PHC",
  //   "63bd5b3ccafee0c35772f8f1": "Office",
  // }
  const list = {
    "last_maintenance_date": "Date",
    "manufacturing_date": "Date",
    "current_location": "Location",
    "hub_location": "Location",
    "flight_hours": "time",
    "drone_password": "Password",
    "current_flight_id": "Flight Id",
    "__v": "Password"
  }
  const CompileContent = (data) => {
    if (list[data] === "Date") {
      return dayjs(droneTobeManage[data]).format("YYYY-MM-DD HH:mm").toString();
    }
    else if (list[data] === "Location") {
      // console.log("droneTobeManage[data]", droneTobeManage[data])
      // console.log("locations", locations, locations[droneTobeManage[data]])
      return locations[droneTobeManage[data]]
    }
    else if (list[data] === "time") {
      return (droneTobeManage[data].hours + ":" + droneTobeManage[data].minutes + ":" + droneTobeManage[data].seconds);
    } else if (list[data] === "Password") {
      return
    } else if(list[data] === "Flight Id"){
      if(droneTobeManage[data] === ""){
        return "NA"
      } else {
        return droneTobeManage[data]
      }
    }
    return droneTobeManage[data];
  }

  const [drone, setDrone] = useState([])
  const drone_Data = {
    "_id": "Model ID",
    "internal_id": "Internal Id",
    "model_no": "Model No",
    "payload_capacity": "Payload Capacity(kg)",
    "drone_password": "Drone Password",
    "weight": "Model Weight (kg)",
    "length": "Model Length (m)",
    "width": "Model Width (m)",
    "height": "Model Height (m)",
    "max_range": "Maximum Range(km)",
    "flight_hours": "Flight Hours(hh:mm:ss)",
    "last_maintenance_date": "Last Maintenance Date",
    "manufacturing_date": "Manufacturing Date",
    "drone_phase": "Drone Phase",
    "drone_version": "Model Version",
    "pilot_id1": "Pilot 1",
    "pilot_id2": "Pilot 2",
    "hub_location": "Drone Commisioned at Hub",
    "current_location": "Current Location",
    "current_flight_id": "Current Flight Id",
    "__v": "Drone Password"
  }
  useEffect(() => {
    const ownFlight = [];
    const keys = Object.keys(droneTobeManage);
    keys.forEach(key => {
      ownFlight.push([drone_Data[key], CompileContent(key)])
    });
    ownFlight.pop()
    setDrone(ownFlight)


  }, [droneTobeManage])
  // console.log(location)
  // console.log(internalId)
  // console.log(updateValue)
  return (
    <div onClick={closeModal} ref={modalRef} className="AssignPilotPopup-modalBackground">
      <div className="AssignPilotPopup-modalContainer-main">
        <div className="AssignPilotPopup-modalContainer" >
        <div className="AssignPilotPopup-modalContainer__heading" >Drone Details</div>
        <div className="AssignPilotPopup-modalContainer__flexBox">
          <div className="AssignPilotPopup-modalContainer__leftSide">
            {drone.sort().map((item) => {
              {
                if (item[0] === "Drone Password") {
                  return null
                } else {
                  return (<div className="AssignPilotPopup-modalContainer__item">
                    <div className="AssignPilotPopup-modalContainer__item__title">{item[0]}</div>
                    :
                    <div className="AssignPilotPopup-modalContainer__item__value"> {item[1]}</div>
                  </div>)
                }
              }
            })}


          </div>
          <div className="AssignPilotPopup-modalContainer__rightSide">
            <div className="AssignPilotPopup-modalContainer__field">
              <div className="AssignPilotPopup-modalContainer__field__label">Choose Pilot</div>
              :
              <div className="AssignPilotPopup-modalContainer__field__options">
                <select className="AssignPilotPopup-modalContainer__field__options__select"
                  onChange={(e) => {
                    // console.log(e.target.value)
                    setUpdateValue({ ...updateValue, ["pilot_id1"]: e.target.value })
                  }}
                >
                  <option>{droneTobeManage.pilot_id1}</option>
                  {dronePilots.map((item) => {
                    return <option value={item.useremail}>{item.useremail}</option>
                  })}
                  <option>Unassigned</option>
                </select>
              </div>
            </div>
            <div className="AssignPilotPopup-modalContainer__field">
              <div className="AssignPilotPopup-modalContainer__field__label">Choose Co-Pilot</div>
              :
              <div className="AssignPilotPopup-modalContainer__field__options">
                <select className="AssignPilotPopup-modalContainer__field__options__select"
                  onChange={(e) => {
                    // console.log(e.target.value)
                    setUpdateValue({ ...updateValue, ["pilot_id2"]: e.target.value })
                  }}
                >
                  <option>{droneTobeManage.pilot_id2}</option>
                  {dronePilots.map((item) => {
                    return <option value={item.useremail}>{item.useremail}</option>
                  })}
                  <option>Unassigned</option>
                </select>
              </div>
            </div>
          </div>

        </div>
        </div>
        <div className="AssignPilotPopup-modalContainer__button" >
          <button className="AssignPilotPopup-modalContainer__button__Btn"
            onClick={() => { modalRef.current.click(); }} >Cancel</button>
          <button className="AssignPilotPopup-modalContainer__button__Btn" onClick={() => { handleDeployment() }}>
            OK</button>
        </div>
      </div>
    </div>
  );
}

export default AssignPilot;
