import React, { useEffect, useRef } from 'react'
import "./HelpTopicPoints.scss"
import HelpTopicPointRow from './HelpTopicPointRow'
const HelpTopicPoints = ({ registerScrollToElement, topic, selectedTopic, scrollToStart }) => {
    const TopicPointsContainerRef = useRef()

    useEffect(() => {
        if (selectedTopic?._id === topic?._id && TopicPointsContainerRef.current) {
            registerScrollToElement(TopicPointsContainerRef.current)
            console.log("selectedTopic = topic", topic?.topicName)
        }
    }, [selectedTopic, TopicPointsContainerRef.current])


    return (
        <div ref={TopicPointsContainerRef} className='HelpTopicPoints-container'>
            <div className='HelpTopicPoints-container__topicLabel'>
                <div>{topic?.topicName}:</div>
                <div className='HelpTopicPoints-container__topicLabel__backBtn' onClick={() => { scrollToStart() }}>Back</div>
            </div>
            <HelpTopicPointRow />
        </div>
    )
}

export default HelpTopicPoints