import React from 'react'
import "./checklistInputForm.scss"
import ChecklistItemRow from './ChecklistItemRow'
const ChecklistInputForm = ({ socket, checklistItems, url, setIsChanged, checklistUpdateObj,isEditable }) => {
    return (

        <table className="checklistInputForm-container">
            <thead className='checklistInputForm-container__index'>
                <tr className='checklistInputForm-container__index'>
                    <th className='checklistInputForm-container__index__SrNo'>S.No</th>
                    <th className='checklistInputForm-container__index__confirm'>Confirm</th>
                    <th className='checklistInputForm-container__index__label'>Title</th>
                    <th className='checklistInputForm-container__index__parameters'>Parameters</th>
                    <th className='checklistInputForm-container__index__notes'>Notes</th>
                </tr>
            </thead>
            <tbody>
                {checklistItems?.length && checklistItems.map((item, i) => {
                    return <ChecklistItemRow item={item}
                        socket={socket}
                        url={url}
                        key={`${item.label}${i}`}
                        index={i}
                        setIsChanged={setIsChanged}
                        checklistUpdateObj={checklistUpdateObj}
                        isEditable={isEditable} />

                })}
            </tbody>
        </table>
    )
}

export default ChecklistInputForm