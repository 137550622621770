import React, { useEffect, useRef, useState } from 'react'
import { GoogleMap, GoogleMarkerClusterer, InfoBox, Marker, MarkerClusterer, Polyline } from '@react-google-maps/api';
import { useJsApiLoader } from '@react-google-maps/api';
import { mapOptions } from '../MyConfiguration';
import DroneMarker from '../GoogleMapsCustomComponents/DroneMarker';
import HubAndLocMarker from '../GmapComponents/HubAndLocMarker';

// import markerDrone from 'drone2vector.svg'



function GMapViewAllDrones(props) {
    const { routePoints, allAvailableDrones,
        allDronesInfo, selectedDrone, setSelectedDrone,
        heading, centerToDrone, fitRoute,
        hubNodeLocations,
        SetCenterToDrone,
        isConnected,
    } = props;
    const [prevDroneLocation, SetPrevDroneLocation] = useState({ lat: allDronesInfo[selectedDrone]?.lat ? allDronesInfo[selectedDrone]?.lat : 25.85573935116686, lng: allDronesInfo[selectedDrone]?.long ? allDronesInfo[selectedDrone]?.long : 90.35195512885268 });
    const [droneLastLocation, setDroneLastLocation] = useState({ lat: allDronesInfo[selectedDrone]?.lat ? allDronesInfo[selectedDrone]?.lat : 25.85573935116686, lng: allDronesInfo[selectedDrone]?.long ? allDronesInfo[selectedDrone]?.long : 90.35195512885268 })
    const [isDroneLatSet, SetisDroneLatSet] = useState(false);
    const [locDiff, SetlocDiff] = useState({ lat: 0, lng: 0 });

    const [prevDroneAngle, SetPrevDroneAngle,] = useState(heading);
    const [isDroneAngleSet, SetisDroneAngleSet] = useState(false);
    const [angleDiff, SetAngleDiff] = useState(0);
    const [allHubAndNodes, setAllHubAndNodes] = useState([])
    const [myHub, setMyHub] = useState({
        tag: "HUB",
        name: "default",
        lat: 25.85573935116686,
        lng: 90.35195512885268,
        hoverFunc: () => { },
        MouseOutFunc: () => { }
    })
    const [positionM1, setPositionM1] = useState({
        lat: 25.85573935116686, lng: 90.35195512885268
    })
    const [imgAngle, setImgAngle] = useState(30);
    const [cardShow, setCardShow] = useState(false);
    const [ownMapCenter, setOwnMapCenter] = useState({ lat: 25.917101231771113, lng: 90.64956864386703 });
    const altitudeInputRef = useRef();
    const [routeOptions, setRouteOptions] = useState({
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 5,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: true,
        visible: true,
        paths: routePoints[selectedDrone],
        zIndex: 10
    })
    const polyLineRef = useRef();
    const [markerAnchors, setMarkerAnchors] = useState({
        wp: null,
        owp: null,
        dwp: null
    })

    const routes = [
        // { lat: 25.65554589592577, lng: 90.35196585740401 },
        // { lat: 25.970128750000608, lng: 90.41011400454289 },
        // { lat: 25.917101231771113, lng: 90.64956864386703 },
        // { lat: 25.530009648762853, lng: 90.8591667000106 },
        { lat: 25.45279714240047, lng: 90.7127885290625 },

        { lat: 25.57566372263245, lng: 90.49134440018842 },
        // { lat: 25.45279714240047, lng: 90.7127885290625 }
        // { lat: 25.658606, lng: 90.351402 },
        // { lat: 25.658527, lng: 90.354292 },

        // { lat: 25.654866, lng: 90.354915 }
    ]

    const GmapRef = useRef();
    const [allLocationsTree, setAllLocationsTree] = useState({})
    const [allLocationsData, setAllLocationsData] = useState({})

    useEffect(() => {
        if (isDroneLatSet) {
            // if (droneLastLocation.lat !== prevDroneLocation.lat || droneLastLocation.lng !== prevDroneLocation.lng) {
            //     SetPrevDroneLocation({ lat: droneLat, lng: droneLong })
            // }
            let lat = (allDronesInfo[selectedDrone]?.lat - prevDroneLocation.lat) / 15;
            let lng = (allDronesInfo[selectedDrone]?.lng - prevDroneLocation.lng) / 15;
            SetlocDiff({ lat: lat, lng: lng });


            const latLngTimer = setInterval(() => {
                SetPrevDroneLocation((prev) => {
                    let out = {
                        lat: prev.lat,
                        lng: prev.lng
                    }
                    let latsign = 0
                    if (locDiff.lat < 0) {
                        latsign = -1;
                        if (prev.lat >= allDronesInfo[selectedDrone]?.lat) {
                            out.lat = prev.lat + locDiff.lat
                        }
                        else {
                            out.lat = prev.lat
                        }
                    }
                    else {
                        latsign = 1;
                        if (prev.lat <= allDronesInfo[selectedDrone]?.lat) {
                            out.lat = prev.lat + locDiff.lat
                        }
                        else {
                            out.lat = prev.lat
                        }

                    }
                    let lngsign = 0
                    if (locDiff.lng < 0) {
                        lngsign = -1;
                        if (prev.lng >= allDronesInfo[selectedDrone]?.long) {
                            out.lng = prev.lng + locDiff.lng
                        }
                        else {
                            out.lng = prev.lng
                        }
                    }
                    else {
                        lngsign = 1;
                        if (prev.lng <= allDronesInfo[selectedDrone]?.long) {
                            out.lng = prev.lng + locDiff.lng
                        }
                        else {
                            out.lng = prev.lng
                        }
                    }
                    return out;

                })


            }, 25);
            // setDroneLastLocation({ lat: droneLat, lng: droneLong })

            return () => { clearInterval(latLngTimer) }
        }
    }, [allDronesInfo[selectedDrone]?.lat, isDroneLatSet])

    const onMapLoad = (mapInstance) => {
        GmapRef.current = mapInstance;
        GmapRef.current.setMapTypeId("satellite")
        const bounds = new window.google.maps.LatLngBounds();
        allHubAndNodes.forEach((marker) => {
            bounds.extend({ lat: marker.lat, lng: marker.lng })
        })
        GmapRef.current.fitBounds(bounds);
        setMarkerAnchors({
            wp: new window.google.maps.Point(15, 45),
            owp: new window.google.maps.Point(15, 45),
            dwp: new window.google.maps.Point(15, 45)
        })
    }
    useEffect(() => {
        console.log(allHubAndNodes, routePoints)
        if (GmapRef.current && selectedDrone !== "" && allHubAndNodes.length) {

            const bounds = new window.google.maps.LatLngBounds();
            if (routePoints[selectedDrone]?.length && selectedDrone !== "") {
                {
                    routePoints[selectedDrone].forEach((marker) => {
                        bounds.extend({ lat: marker.lat, lng: marker.lng })
                    })
                }
            } else {
                allHubAndNodes.forEach((marker) => {
                    bounds.extend({ lat: marker.lat, lng: marker.lng })
                })
            }
            GmapRef.current.fitBounds(bounds);

        }
    }, [fitRoute, allHubAndNodes])


    const { isLoaded } = useJsApiLoader({
        id: mapOptions.googleMapApiKey,
        googleMapsApiKey: mapOptions.googleMapApiKey,
    })

    const containerStyle = {
        width: '100%',
        height: '100%',
        borderRadius: "30px"
        // background: "pink"
    };

    useEffect(() => {
        const keys = Object.keys(hubNodeLocations);
        const formatedLocations = []
        const locationsTree = {}
        const locationsData = {}
        // console.log("keys =", keys)
        keys.forEach((key, i) => {
            // console.log("key =",i,key)
            const loc = hubNodeLocations[key];
            formatedLocations.push({
                tag: loc.tag,
                name: loc.name,
                lat: parseFloat(loc.lat),
                lng: parseFloat(loc.lng),
                hoverFunc: () => { },
                MouseOutFunc: () => { }
            })
            locationsData[key] = {
                tag: loc.tag,
                name: loc.name,
                lat: parseFloat(loc.lat),
                lng: parseFloat(loc.lng),
                hoverFunc: () => { },
                MouseOutFunc: () => { }
            }
            if (loc.tag === "HUB") {
                setMyHub({
                    tag: loc.tag,
                    name: loc.name,
                    lat: parseFloat(loc.lat),
                    lng: parseFloat(loc.lng),
                    hoverFunc: () => { },
                    MouseOutFunc: () => { }
                })
                locationsTree[key] = [key]
                // locationsTree[key].push[key]
            }
            else {
                locationsTree[loc.hub_id]?.push(key)
            }
            // console.log("locationsTree", locationsTree)
        });
        // console.log("formatedLocations =",formatedLocations)
        setAllHubAndNodes(formatedLocations)
        // console.log("locationsTree", locationsTree)
        setAllLocationsTree(locationsTree)
        setAllLocationsData(locationsData)
    }, [hubNodeLocations])



    const MarkerLocationCardStyle = {
        opacity: 1, backgroundColor: "#FD6125", padding: "5px", borderRadius: "5px",
        display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"
    }


    // useEffect(() => {
    //     if (routePoints[selectedDrone]?.length && isLoaded) {
    //         const bounds = new window.google.maps.LatLngBounds();
    //         routePoints[selectedDrone].forEach((marker) => {
    //             bounds.extend({ lat: marker.lat, lng: marker.lng })
    //         })
    //         GmapRef.current.fitBounds(bounds);
    //     }
    // }, [routePoints[selectedDrone]])

    useEffect(() => {
        if (centerToDrone && GmapRef.current) {
            // GmapRef.current.setCenter({ lat: droneLat, lng: droneLong });
            if (prevDroneLocation.lat && selectedDrone !== "") {
                GmapRef.current?.setCenter({ lat: allDronesInfo[selectedDrone]?.lat, lng: allDronesInfo[selectedDrone]?.long });
            }
        }
        if (!isDroneLatSet) {
            SetPrevDroneLocation((prev) => {
                console.log("inside")
                return { lat: allDronesInfo[selectedDrone]?.lat, lng: allDronesInfo[selectedDrone]?.long }
            })
        }
        if (allDronesInfo[selectedDrone]?.lat) {
            SetisDroneLatSet(true)
        }


    }, [allDronesInfo[selectedDrone]?.lat, allDronesInfo[selectedDrone]?.long, centerToDrone, prevDroneLocation.lat, heading]);


    const [isMapDraggle, setIsMapDraggle] = useState(true)

    const [routeTrailPoints, setRouteTrailPoints] = useState([]);
    const [isTrailTimerOn, setIsTrailTimerOn] = useState(false);
    const routeTrailOptions = {
        strokeColor: '#FFFF00',
        strokeOpacity: 0.6,
        strokeWeight: 4,
        fillColor: '#FFFF00',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 300,
        paths: routeTrailPoints,
        zIndex: 100
    };
    useEffect(() => {
        let trailIntervalTimerId;
        if (routeTrailPoints.length > 5) {
            trailIntervalTimerId = setInterval(() => {
                if (routeTrailPoints.length > 0) {
                    routeTrailPoints.pop();
                }
            }, 400);
            setIsTrailTimerOn(true);
            if (routeTrailPoints.length <= 1) {
                clearInterval(trailIntervalTimerId);
                setRouteTrailPoints([])
                setIsTrailTimerOn(false);
            }
            return () => {
                clearInterval(trailIntervalTimerId)
                setIsTrailTimerOn(false);
            }
        }
        if (routeTrailPoints.length <= 1) {
            clearInterval(trailIntervalTimerId);
            setIsTrailTimerOn(false);
        }
    }, [routeTrailPoints])

    useEffect(() => {
        routeTrailPoints.unshift({ lat: allDronesInfo[selectedDrone]?.lat, lng: allDronesInfo[selectedDrone]?.long });
        if (routeTrailPoints.length >= 50) {
            routeTrailPoints.pop();
        }
        setRouteTrailPoints([...routeTrailPoints])

    }, [allDronesInfo[selectedDrone]?.lat])


    return (isLoaded && (
        <>
            <GoogleMap
                mapContainerStyle={containerStyle}

                center={ownMapCenter}
                // zoom={9}
                options={{
                    styles: mapOptions.mapTheme,
                    disableDefaultUI: false, draggable: { isMapDraggle },
                    mapTypeControl: false,

                }}

                onLoad={onMapLoad}
                onZoomChanged={() => { SetCenterToDrone(false) }}
                onDragStart={() => { SetCenterToDrone(false) }}
                onClick={(event) => {

                }}
            >

                {<HubAndLocMarker allHubAndNodes={allHubAndNodes} locationsTree={allLocationsTree} allLocationsData={allLocationsData} />}

                <>
                    <MarkerClusterer gridSize={40}
                        minimumClusterSize={routePoints[selectedDrone]?.length <= 1 ? 5 : routePoints[selectedDrone]?.length}>
                        {(clusterer) => routePoints[selectedDrone]?.map((loc, index) => {
                            if (index === 0) {
                                return < Marker
                                    key={`routePoints[selectedDrone]waypoint${loc.tag}${index}`}
                                    // label={{ text: (index + 1).toString(), color: 'white', fontSize: "20px", className: "GmapCustomMarkerLabels" }}
                                    options={{
                                        zIndex: 5,
                                        clickable: false,
                                        draggable: false,
                                        cursor: "default"
                                    }} animation={window.google.maps.Animation.DROP}
                                    clusterer={clusterer} position={loc} icon={{ url: "./Origin.svg", anchor: markerAnchors.wp }} />
                            }
                            else if (index === routePoints[selectedDrone]?.length - 1) {
                                return < Marker key={`routePoints[selectedDrone]waypoint${loc.tag}${index}`}
                                    // label={{ text: (index + 1).toString(), color: 'white', fontSize: "20px", className: "GmapCustomMarkerLabels" }}
                                    options={{
                                        zIndex: 5,
                                        clickable: false,
                                        draggable: false,
                                        cursor: "default"
                                    }} animation={window.google.maps.Animation.DROP}
                                    clusterer={clusterer} position={loc} icon={{ url: "./Destination.svg", anchor: markerAnchors.wp }} />
                            }
                            else {
                                return < Marker key={`routePoints[selectedDrone]waypoint${loc.tag}${index}`}
                                    // label={{ text: (index + 1).toString(), color: 'white', fontSize: "20px", className: "GmapCustomMarkerLabels" }}
                                    options={{
                                        zIndex: 5,
                                        clickable: false,
                                        draggable: false,
                                        cursor: "default"
                                    }} animation={window.google.maps.Animation.DROP}
                                    clusterer={clusterer} position={loc} icon={{ url: "./Waypoint.svg", anchor: markerAnchors.wp }} />
                            }
                        })}
                    </MarkerClusterer>
                    {/* {routePoints[selectedDrone] !== [] ? <Polyline
                        // onLoad={onLoad}
                        path={routePoints[selectedDrone]}
                        options={{
                            ...routeOptions,
                            clickable: false,
                            draggable: false,
                            editable: false,
                        }}
                    /> : null} */}
                    {allAvailableDrones?.map((drone, i) => {
                        if (selectedDrone === drone) {
                            return <Polyline
                                // onLoad={onLoad}
                                key={`${drone} ${i} polyline`}
                                path={routePoints[drone]}
                                options={{
                                    ...routeOptions,
                                    strokeColor: '#FF0000',
                                    paths: routePoints[drone],
                                    zIndex: 1000,
                                    clickable: false,
                                    draggable: false,
                                    editable: false,
                                }}
                            />
                        }
                        else {
                            return <Polyline
                                // onLoad={onLoad}
                                key={`${drone} ${i} polyline`}
                                path={routePoints[drone]}
                                onClick={() => { setSelectedDrone(drone) }}
                                options={{
                                    ...routeOptions,
                                    strokeColor: 'black',
                                    paths: routePoints[drone],
                                    clickable: true,
                                    draggable: false,
                                    editable: false,
                                }}
                            />
                        }
                    })}
                    {allAvailableDrones?.map((drone, i) => {
                        // console.log("allDronesInfo =", drone)
                        // if (drone !== selectedDrone) {
                        return allDronesInfo[drone]?.lat ? <><DroneMarker posi={{ lat: allDronesInfo[drone]?.lat, lng: allDronesInfo[drone]?.long }}
                            setSelectedDrone={setSelectedDrone} selectedDrone={selectedDrone} drone={drone} isMultipleDrone={true}
                            label={{ text: `A-spd:${allDronesInfo[drone].a_speed} Km/hr | eta:${allDronesInfo[drone].eta} | cbs:${allDronesInfo[drone].climb_rate}m/s`, className: `${drone === selectedDrone ? "hub-dronemarkerLabelCss-selected" : "hub-dronemarkerLabelCss"}` }}
                            anchor={new window.google.maps.Point(170, 0)} zIndex={selectedDrone === drone ? 100 : 6} scale={0.25} rotation={allDronesInfo[drone]?.heading} />
                            {/* <Marker cursor={"default"} clickable={false} zIndex={5} label={{ text: `${drone}`, className: "hub-markerLabelCss" }} key={`marker${drone}${i}`}
                                position={{ lat: allDronesInfo[drone]?.lat, lng: allDronesInfo[drone]?.long }} /> */}
                        </> : null
                        // }
                    })}
                </>

            </GoogleMap >

        </>)
    );

}






export { GMapViewAllDrones, Marker }

