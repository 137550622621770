import React, { useEffect, useRef, useState } from 'react'
import { backEndIP } from '../MyConfiguration';
import axios from 'axios';
import "./PostFlightCheckList.scss"
import NewItemChecklistForm from '../NewItemChecklistForm';
import CheckListItemTemplateRow from '../CheckListItemTemplateRow';
import ReactToPrint from 'react-to-print';

const PostFlightCheckList = ({ userCookie, socket, setSelectedTab, handleNotifyAlert, SetLoadingVisibility }) => {

    const [allChecklistItems, setAllChecklistItems] = useState([])
    const [newItemFormVisibility, setNewItemFormVisibility] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [pageType, setPageType] = useState("add");
    const [updateSelf, setUpdateSelf] = useState(false)
    const [deleteRows, setDeleteRows] = useState([])

    const componentRef = useRef()
    const moveChecklistItemUp = (index) => {
        if (index > 0) {
            const item1 = allChecklistItems[index];
            allChecklistItems[index] = allChecklistItems[index - 1]
            allChecklistItems[index - 1] = item1
            setAllChecklistItems([...allChecklistItems])
            setIsChanged(true)
        }
    }
    const moveChecklistItemDown = (index) => {
        if (index < allChecklistItems.length - 1) {
            const item1 = allChecklistItems[index];
            allChecklistItems[index] = allChecklistItems[index + 1]
            allChecklistItems[index + 1] = item1
            setAllChecklistItems([...allChecklistItems])
            setIsChanged(true)
        }
    }
    const checklistItemDelete = (index) => {

        const items = [...allChecklistItems.slice(0, index), ...allChecklistItems.slice(index + 1)];
        //console.log(items)
        deleteRows.push(allChecklistItems[index]._id)
        setAllChecklistItems(items)
        setIsChanged(true)

    }
    const checklistItemAdd = (itemData) => {
        allChecklistItems.push(itemData)
        setAllChecklistItems([...allChecklistItems])
        setIsChanged(true)
    }

    const handleUpdateChangesClick = () => {
        // updatePreFlightCheckListTemplate

        SetLoadingVisibility(true);
        axios({
            url: backEndIP + "/checkListItems/updateCheckListTemplate",
            method: "PUT",
            headers: {
                authorization: userCookie.token,
            },
            data: { list: allChecklistItems, deleteRows: deleteRows, templateTitle: "Post Flight" }
        }).then((resp) => {
            //console.log(resp.data.data);
            setAllChecklistItems(resp.data.data)
            setDeleteRows([])
            setIsChanged(false)
            SetLoadingVisibility(false);
        }).catch((err) => {
            SetLoadingVisibility(false);
            if (err.response.data.message) window.alert(err.response.data.message);
            else window.alert("some error occurered");
            //console.log(err);
        });
    }

    useEffect(() => {
        SetLoadingVisibility(true);
        axios({
            url: backEndIP + `/checkListItems/getCheckListTemplate${"Post Flight"}`,
            method: "GET",
            headers: {
                authorization: userCookie.token,
            },
        }).then((resp) => {
            //console.log(resp.data.data);
            setAllChecklistItems(resp.data.data)
            setDeleteRows([])
            setIsChanged(false)
            SetLoadingVisibility(false);
        }).catch((err) => {
            SetLoadingVisibility(false);
            if (err.response.data.message) window.alert(err.response.data.message);
            else window.alert("some error occurered");
            //console.log(err);
        });
    }, [updateSelf])


    return (
        <div className='post-flightChecklist-main-container' ref={componentRef}>
            <div className='post-flightChecklist-container'>
                <div className='post-flightChecklist-container__topBar'>
                    <div className='post-flightChecklist-container__topBar__title'> Post-Flight CheckList Template</div>
                    {isChanged && <button className='post-flightChecklist-container__topBar__updateBtn' onClick={handleUpdateChangesClick}>Update Changes</button>}</div>
                <div className='post-flightChecklist-container__table' >
                    <div className='post-flightChecklist-container__index'>
                        <div className='post-flightChecklist-container__index__SrNo'>SrNo</div>
                        <div className='post-flightChecklist-container__index__CheckBox'>Active</div>
                        <div className='post-flightChecklist-container__index__Title'>Title</div>
                        <div className='post-flightChecklist-container__index__Parameters'>Parameters</div>
                        <div className='post-flightChecklist-container__index__Notes'>Notes</div>
                        <div className='post-flightChecklist-container__index__actions'>Actions</div>
                    </div>
                    {allChecklistItems.map((item, index) => {
                        return <CheckListItemTemplateRow key={`CheckListItemTemplateRow${item.label}`} item={item}
                            index={index}
                            itemMoveUp={moveChecklistItemUp}
                            itemMoveDown={moveChecklistItemDown}
                            itemDelete={checklistItemDelete}
                            setIsChanged={setIsChanged}
                        />
                    })}
                </div>
            </div>
            <div className='post-flightChecklist-addButton'>
                <button onClick={() => { setNewItemFormVisibility(true) }} className='post-flightChecklist-addButton__Button'>Add new Item</button></div>
            {newItemFormVisibility ? <NewItemChecklistForm
                setOpenModal={setNewItemFormVisibility}
                checklistItemAdd={{
                    url: "/checkListItems/addCheckListItem",
                    userToken: userCookie.token,
                    templateTitle: "Post Flight"
                }}
                SetLoadingVisibility={SetLoadingVisibility}
                setUpdateSelf={setUpdateSelf}

                pageType={"add"}
                data={""} /> : null}
            {/* <ReactToPrint
                trigger={() => <button>Print this out!</button>}
                content={() => componentRef.current}
            /> */}
        </div>
    )
}

export default PostFlightCheckList