
import React, { useEffect, useRef, useState } from "react";
import "./ForwardAndReturnMissionFile.scss";
import axios from "axios";
import { backEndIP } from "../MyConfiguration";

function ForwardAndReturnMission({
    title,
    adminDesignation,
    fileNameTextBox,
    fileInputElement,
    missionFileType,
    missionFile,
    setMissionFile,
    fileExportAnchorRef,
    previousMissionFile,
    SetLoadingVisibility,
    handleNotifyAlert,
    userCookie,
    setDetailsPopUp }) {

    const [errorMessage, setErrorMessage] = useState(false)
    const [wrongFileError, setWrongFileError] = useState(false)


    const onExportFileClick = () => {
        //console.log(previousMissionFile)
        if (previousMissionFile !== "") {
            const blob = new Blob([previousMissionFile], { type: "text/plain" });
            const urlFile = URL.createObjectURL(blob);
            fileExportAnchorRef.current.href = urlFile;
            fileExportAnchorRef.current.click();
            // setExportFileName( "testFile.waypoints")
        } else {
            // setDetailsPopUp(false)
            handleNotifyAlert(
                "notify",
                `Previous File is not present`,
                "failed",
                () => { }
            );
            //window.alert("Previous File is not present")
        }

    }

    const handleFileOnChange = () => {
        const reader = new FileReader();
        //console.log(fileInputElement.current.files.length)
        if (fileInputElement.current.files.length) {
            fileNameTextBox.current.value = fileInputElement.current.files[0].name;
            //if(fileInputElement.current.files[0].name)
            console.log(fileInputElement.current.files[0])
            if (fileInputElement.current.files[0].name.split(".")[1] === "waypoints") {
                setWrongFileError(false)
                reader.onload = () => {
                    console.log(reader.result)
                    setMissionFile(reader.result)
                    fileInputElement.current.value = "";
                }
                setMissionFile(reader.readAsText(fileInputElement.current.files[0]));
                setErrorMessage(false)

            } else {
                setWrongFileError(true)
                fileInputElement.current.value = "";
                setMissionFile("")
                //fileNameTextBox.current.value = "";
            }

        }

    };
    //console.log(missionFile)
    const handleSelectFileClick = () => {

        fileInputElement.current.click();
        //console.log(fileInputElement.current)
    };

    const handelFileUpload = () => {
        //console.log(missionFile)
        if (missionFile === "") {
            setErrorMessage(true)
        } else {
            // console.log(missionFile)
            axios({
                url: backEndIP + `/missionFiles/updateMission_missionFile`,
                method: "PUT",
                headers: {
                    authorization: userCookie.token
                },
                data: {
                    mission_tagName: title,
                    mission_file: missionFile,
                }
            }).then((resp) => {
               // console.log(resp.data)
                setMissionFile("")
                fileInputElement = ""
                fileNameTextBox.current.value = ""
                handleNotifyAlert(
                    "notify",
                    `${resp.data.message}`,
                    "success",
                    () => { }
                );
                //setHubData(hubAvailable.data.data);
            }).catch((err) => {
                console.log(err);
                handleNotifyAlert(
                    "alert",
                    `${err.response.data.message}`,
                    "failed",
                    () => { }
                );
            })
        }
    }

    // console.log(locationDetails)
    return (

        <div className="AddMissionPopup-modalContainer__subContainer__forward">
            <div className="AddMissionPopup-modalContainer__subContainer__forward__fileName">{title}</div>
            {adminDesignation === "Hub Manager" || adminDesignation === "System User" ? <div className="AddMissionPopup-modalContainer__subContainer__forward__filedContainer">
                <div className="AddMissionPopup-modalContainer__subContainer__forward__textFieldError">
                    <input
                        //value={forwardMissionFile}
                        ref={fileNameTextBox}
                        placeholder={missionFileType}
                        type="text"
                        name="filedata"
                        readOnly="readOnly"
                        className="AddMissionPopup-modalContainer__subContainer__forward__textField"
                    ></input>
                    {errorMessage === true && <div style={{ fontSize: "calc(14px * var(--heightmultiplier)", color: "red", textAlign: "" }}>Please Select the file before Upload</div>}
                    {wrongFileError === true && <div style={{ fontSize: "calc(14px * var(--heightmultiplier)", color: "red", textAlign: "" }}>This is an invaid File Extention</div>}
                </div>
                <input
                    ref={fileInputElement}
                    type="file"
                    name="uploaded_file"
                    onChange={() => { handleFileOnChange(missionFileType) }}
                    className="AddMissionPopup-modalContainer__subContainer__forward__hidden"
                />
                <input
                    type="button"
                    value="Select File"
                    onClick={handleSelectFileClick}
                    className="AddMissionPopup-modalContainer__subContainer__forward__button"
                    required
                />
            </div>: null}
            <div className="AddMissionPopup-modalContainer__subContainer__forward__DivBtn">
                {wrongFileError === true ?
                    <button className="AddMissionPopup-modalContainer__subContainer__forward__DivBtn__disable" disabled >Upload File</button> : <>
                        {adminDesignation === "Hub Manager" || adminDesignation === "System User" ?
                        <button className="AddMissionPopup-modalContainer__subContainer__forward__DivBtn__Uploadbtn" onClick={handelFileUpload}>Upload File</button>: null}</>}
                {previousMissionFile !== "" && <button className="AddMissionPopup-modalContainer__subContainer__forward__DivBtn__exportBtn" onClick={onExportFileClick}>Download File
                    <a style={{ display: "none" }} ref={fileExportAnchorRef} download={`${title}.waypoints`}
                        onClick={(e) => { e.stopPropagation() }}></a></button>}
            </div>
        </div>
    );
}

export default ForwardAndReturnMission;
