import React, { useEffect, useRef, useState } from 'react'

const AttitudeInstrument = ({ rol, pit, dimen }) => {
    const [rollCSS, setRollCSS] = useState({});
    const [pitchCSS, setPitchCSS] = useState({});
    // const [ILSLocalizerCSS, setILSLocalizerCSS] = useState({});
    // const [ILSGlideslope, setILSGlideslopeCSS] = useState({});
    const indicatorBackgroundRef =useRef();

    // Attitude - Set pitch
    function _setPitch(pitch) {
        // const pitch_bound = 26
        // if (pitch > pitch_bound) pitch = pitch_bound;
        // else if (pitch < -pitch_bound) pitch = -pitch_bound;

        // setPitchCSS({ transform: `translateY(${(((dimen.height / 1080) + (dimen.width / 1920)) / 2) * pitch}%)` });
        setPitchCSS({ transform: `translateY(${(indicatorBackgroundRef.current.clientHeight/445) * pitch}px)` });
    }

    // Attitude - Set roll
    function _setRoll(roll) {
        setRollCSS({ transform: "rotate(" + roll + "deg)" });
    }


    // Attitude - Instrument landing system - Set localizer angle
    // function _setILSLocalizer(angle) {
    //     var ang =
    //         Math.abs(angle) > 14.5 ? (Math.abs(angle) / angle) * 14.5 : angle;
    //     //         .css({
    //     //             transform: "rotate(" + ang + "deg)",
    //     //             "transform-origin": "center top",
    //     //         });
    // }

    // // Attitude - Instrument landing system - Set glideslope angle
    // function _setILSGlideslope(angle) {
    //     var ang =
    //         Math.abs(angle) > 14.5 ? (Math.abs(angle) / angle) * 14.5 : angle;

    //     //         .css({
    //     //             transform: "rotate(" + ang + "deg)",
    //     //             "transform-origin": "center left",
    //     //         });

    // }

    useEffect(() => {
        _setRoll(rol);
        _setPitch(pit * (48/20));//11
        // _setILSLocalizer(15 * Math.sin(rol / 30));
        // _setILSGlideslope(20 * Math.sin(rol / 30));
        // console.log(indicatorBackgroundRef.current.clientHeight);
        

    }, [rol, pit, window.innerHeight, window.innerHeight])

    return (
        <div className="instrument attitude">
            <div className="indicator_background" >
                <img src="indicator_background_dashboard.svg"  className="box" alt="" />
            </div>
            <div className="indicator_background_screws" >
                <img ref={indicatorBackgroundRef} src="indicator_background_screws.svg" className="box" alt="" />
            </div>
            <div className="indicator_inner">
                <div className="attitude box"
                    style={rollCSS}>
                    <img src="attitude_roll_1.svg" className="box" alt="" />
                    <div className="attitude_pitch box"
                        style={pitchCSS}>
                        <img src="attitude_pitch.svg" className="box" alt="" />
                    </div>

                    <img src="attitude_roll_2.svg" className="box" alt="" />
                </div>
                <div className="attitude_foreground_1">
                    <img src="attitude_foreground_1.svg" className="box" alt="" />
                </div>
                {/* <div className="attitude_ils">

                    <img src="attitude_ils_markings.svg" className="box" alt="" />
                    <div className="attitude_ils_localizer box">
                        <img src="attitude_ils_localizer.svg" className="box" alt="" />
                    </div>
                    <div className="attitude_ils_glideslope box">
                        <img src="attitude_ils_glideslope.svg" className="box" alt="" />
                    </div>

                </div> */}
                <div className="attitude_foreground_2">
                    <img src="attitude_foreground_2.svg" className="box" alt="" />
                </div>
                {/* <div className="attitude_off_flag">
                    <img src="attitude_off_flag.svg" className="box" alt="" />
                </div> */}

            </div>
            <div className="indicator_foreground">
                <img src="indicator_foreground.svg" className="box" alt="" />
            </div>

        </div>

    )
}

export default AttitudeInstrument