import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import "./AuthLocation.scss"

const PhysicalLocation = ({
  adminDesignation,
  employeeDetails,
  allLocations,
  userCookie,
  deploymentData,
  adminLocation,
  setDeploymentData,
  authLocationData,
  country,
  setCountry,
  zone,
  setZone,
  state,
  setState,
  hubLocation,
  setHubLocation,
  setAuthLocationData }) => {

  const AllowedAdminDesignation = ["System User","CEO", "Country Manager"]
  const hubManagerAndLowerDesignation = [ "Testing Engineer", "Order Manager", "Drone Pilot", "Drone Maintenance Engineer"]

  return (
    <div className='AuthLocationInputBox-modalContainer'>
      <div className="AuthLocationInputBox-modalContainer__right">
      <div className="AuthLocationInputBox-modalContainer__PhyTitle"> Physical Location</div>
           {adminDesignation === "Zone Manager" || adminDesignation === "State Manager" && 
           <div className="AuthLocationInputBox-modalContainer__physicalLoc">
              <div className="AuthLocationInputBox-modalContainer__field">
                <div className="AuthLocationInputBox-modalContainer__field__title">Country</div>
                <div className="AuthLocationInputBox-modalContainer__field__colon">:</div>
                <div className="AuthLocationInputBox-modalContainer__field__value">
                  <select
                    defaultValue={deploymentData.country_id}
                    className="AuthLocationInputBox-modalContainer__field__value__option"
                    onChange={(e) => {
                      if (e.currentTarget.value !== "") {
                        //console.log("Designation", designations)
                        console.log("Country", allLocations.countryNames[e.currentTarget.value])
                        setCountry(allLocations.countryNames[e.currentTarget.value])
                        setDeploymentData({ ...deploymentData, ["country_id"]: e.target.value })
                      } else {
                        setCountry("");
                        setZone("");
                        setState("");
                        setHubLocation("");
                      }
                      console.log(e.currentTarget.value);
                    }}>
                    <option value={""}></option>
                    {allLocations.country.map((item) => {
                      return <option value={item._id}>{item.name}</option>
                    })}
                  </select>
                </div>
              </div>
              {country !== "" && <div className="AuthLocationInputBox-modalContainer__field">
                <div className="AuthLocationInputBox-modalContainer__field__title">Zone</div>
                <div className="AuthLocationInputBox-modalContainer__field__colon">:</div>
                <div className="AuthLocationInputBox-modalContainer__field__value">
                  <select
                  defaultValue={deploymentData.zone_id}
                    className="AuthLocationInputBox-modalContainer__field__value__option"
                    onChange={(e) => {
                      if (e.currentTarget.value !== "") {
                        const valueTobeSet = e.currentTarget.value;
                        setZone(allLocations.zonesNames[e.currentTarget.value]);
                        setDeploymentData({ ...deploymentData, ["zone_id"]: e.target.value })
                        console.log(valueTobeSet)
                        //setLocalEmployee(localEmployee[6][1] = e.currentTarget.value)
                        // setLocalEmployee()
                      } else {
                        setZone("");
                        setState("");
                        setHubLocation("");
                      }
                      console.log(e.currentTarget.value);
                    }}>
                    <option value={""}></option>
                    {allLocations.zones.map((item) => {
                      return <option value={item._id} keys={item.zone_id}>{item.name}</option>
                    })}
                  </select>
                </div>
              </div>}
              {zone !== "" && <div className="AuthLocationInputBox-modalContainer__field">
                <div className="AuthLocationInputBox-modalContainer__field__title">State</div>
                <div className="AuthLocationInputBox-modalContainer__field__colon">:</div>
                <div className="AuthLocationInputBox-modalContainer__field__value">
                  <select
                  defaultValue={deploymentData.state_id}
                    className="AuthLocationInputBox-modalContainer__field__value__option"
                    onChange={(e) => {
                      if (e.currentTarget.value !== "") {
                        setState(allLocations.stateNames[e.currentTarget.value])
                        setDeploymentData({ ...deploymentData, ["state_id"]: e.target.value })
                      } else {
                        setState("");
                        setHubLocation("");
                      }
                      console.log("Zone", zone)
                      console.log(e.currentTarget.value);
                    }}>
                    <option value={""} ></option>
                    {allLocations.states[zone].map((item) => {
                      return <option value={item._id}>{item.name}</option>
                    })}
                  </select>
                </div>
              </div>
              }
              {state !== "" && allLocations.hubs[state].length > 0 && <div className="AuthLocationInputBox-modalContainer__field">
                <div className="AuthLocationInputBox-modalContainer__field__title">Hub</div>
                <div className="AuthLocationInputBox-modalContainer__field__colon">:</div>
                <div className="AuthLocationInputBox-modalContainer__field__value">
                  <select
                  defaultValue={deploymentData.location_id}
                    className="AuthLocationInputBox-modalContainer__field__value__option"
                    onChange={(e) => {
                      if (allLocations.hubNames[e.currentTarget.value] === "Office") {
                        setDeploymentData({ ...deploymentData, ["newLocation"]: "Office" })
                        if (e.currentTarget.value !== "") {
                          setHubLocation(allLocations.hubNames[e.currentTarget.value])
                          setDeploymentData({ ...deploymentData, ["location_id"]: e.target.value, ["newLocation"]: "Office" })

                        } else {
                          setHubLocation("");
                        }
                      } else {
                        setDeploymentData({ ...deploymentData, ["newLocation"]: "Hub" })
                        if (e.currentTarget.value !== "") {

                          setHubLocation(allLocations.hubNames[e.currentTarget.value])
                          setDeploymentData({ ...deploymentData, ["location_id"]: e.target.value, ["newLocation"]: "Hub" })

                        } else {
                          setHubLocation("");
                        }
                      }
                      console.log(allLocations.hubNames[e.currentTarget.value]);
                    }}>
                    <option value={""}></option>
                    {allLocations.hubs[state].map((item) => {
                      return <option value={item._id}>{item.name}</option>
                    })}
                  </select>
                </div>
              </div>
              }
            </div>}
            { AllowedAdminDesignation.includes(adminDesignation) && <> {!hubManagerAndLowerDesignation.includes(deploymentData.designation) ?
           <div className="AuthLocationInputBox-modalContainer__physicalLoc">
              <div className="AuthLocationInputBox-modalContainer__field">
                <div className="AuthLocationInputBox-modalContainer__field__title">Country</div>
                <div className="AuthLocationInputBox-modalContainer__field__colon">:</div>
                <div className="AuthLocationInputBox-modalContainer__field__value">
                  <select
                    defaultValue={deploymentData.country_id}
                    className="AuthLocationInputBox-modalContainer__field__value__option"
                    onChange={(e) => {
                      if (e.currentTarget.value !== "") {
                        //console.log("Designation", designations)
                        console.log("Country", allLocations.countryNames[e.currentTarget.value])
                        setCountry(allLocations.countryNames[e.currentTarget.value])
                        setDeploymentData({ ...deploymentData, ["country_id"]: e.target.value })
                      } else {
                        setCountry("");
                        setZone("");
                        setState("");
                        setHubLocation("");
                      }
                      console.log(e.currentTarget.value);
                    }}>
                    <option value={""}></option>
                    {allLocations.country.map((item) => {
                         if (deploymentData.country_id === item._id) {
                          return <option value={item._id} selected > {item.name}</option>
                        }
                      return <option value={item._id}>{item.name}</option>
                    })}
                  </select>
                </div>
              </div>
              {country !== "" && <div className="AuthLocationInputBox-modalContainer__field">
                <div className="AuthLocationInputBox-modalContainer__field__title">Zone</div>
                <div className="AuthLocationInputBox-modalContainer__field__colon">:</div>
                <div className="AuthLocationInputBox-modalContainer__field__value">
                  <select
                  defaultValue={deploymentData.zone_id}
                    className="AuthLocationInputBox-modalContainer__field__value__option"
                    onChange={(e) => {
                      if (e.currentTarget.value !== "") {
                        const valueTobeSet = e.currentTarget.value;
                        setZone(allLocations.zonesNames[e.currentTarget.value]);
                        setDeploymentData({ ...deploymentData, ["zone_id"]: e.target.value })
                        console.log(valueTobeSet)
                        //setLocalEmployee(localEmployee[6][1] = e.currentTarget.value)
                        // setLocalEmployee()
                      } else {
                        setZone("");
                        setState("");
                        setHubLocation("");
                      }
                      console.log(e.currentTarget.value);
                    }}>
                    <option value={""}></option>
                    {allLocations.zones.map((item) => {
                        if (deploymentData.zone_id === item._id) {
                          return <option value={item._id} selected > {item.name}</option>
                        }
                      return <option value={item._id} keys={item.zone_id}>{item.name}</option>
                    })}
                  </select>
                </div>
              </div>}
              {zone !== "" && <div className="AuthLocationInputBox-modalContainer__field">
                <div className="AuthLocationInputBox-modalContainer__field__title">State</div>
                <div className="AuthLocationInputBox-modalContainer__field__colon">:</div>
                <div className="AuthLocationInputBox-modalContainer__field__value">
                  <select
                  defaultValue={deploymentData.state_id}
                    className="AuthLocationInputBox-modalContainer__field__value__option"
                    onChange={(e) => {
                      if (e.currentTarget.value !== "") {
                        setState(allLocations.stateNames[e.currentTarget.value])
                        setDeploymentData({ ...deploymentData, ["state_id"]: e.target.value })
                      } else {
                        setState("");
                        setHubLocation("");
                      }
                      console.log("Zone", zone)
                      console.log(e.currentTarget.value);
                    }}>
                    <option value={""} ></option>
                    {allLocations.states[zone].map((item) => {
                         if (deploymentData.state_id === item._id) {
                          return <option value={item._id} selected > {item.name}</option>
                        }
                      return <option value={item._id}>{item.name}</option>
                    })}
                  </select>
                </div>
              </div>
              }
              {state !== "" && allLocations.hubs[state].length > 0 && <div className="AuthLocationInputBox-modalContainer__field">
                <div className="AuthLocationInputBox-modalContainer__field__title">Hub</div>
                <div className="AuthLocationInputBox-modalContainer__field__colon">:</div>
                <div className="AuthLocationInputBox-modalContainer__field__value">
                  <select
                  defaultValue={deploymentData.location_id}
                    className="AuthLocationInputBox-modalContainer__field__value__option"
                    onChange={(e) => {
                      if (allLocations.hubNames[e.currentTarget.value] === "Office") {
                        setDeploymentData({ ...deploymentData, ["newLocation"]: "Office" })
                        if (e.currentTarget.value !== "") {
                          setHubLocation(allLocations.hubNames[e.currentTarget.value])
                          setDeploymentData({ ...deploymentData, ["location_id"]: e.target.value, ["newLocation"]: "Office" })

                        } else {
                          setHubLocation("");
                        }
                      } else {
                        setDeploymentData({ ...deploymentData, ["newLocation"]: "Hub" })
                        if (e.currentTarget.value !== "") {

                          setHubLocation(allLocations.hubNames[e.currentTarget.value])
                          setDeploymentData({ ...deploymentData, ["location_id"]: e.target.value, ["newLocation"]: "Hub" })

                        } else {
                          setHubLocation("");
                        }
                      }
                      console.log(allLocations.hubNames[e.currentTarget.value]);
                    }}>
                    <option value={""}></option>
                    {allLocations.hubs[state].map((item) => {
                         if (deploymentData.location_id === item._id) {
                          return <option value={item._id} selected > {item.name}</option>
                        }
                      return <option value={item._id}>{item.name}</option>
                    })}
                  </select>
                </div>
              </div>
              }
            </div>:  <div className="AuthLocationInputBox-modalContainer__physicalLoc">
              <div className="AuthLocationInputBox-modalContainer__field">
                <div className="AuthLocationInputBox-modalContainer__field__title">Country</div>
                <div className="AuthLocationInputBox-modalContainer__field__colon">:</div>
                <div className="AuthLocationInputBox-modalContainer__field__value">
                 <div className="AuthLocationInputBox-modalContainer__field__value__option">{allLocations.countryNames[deploymentData.country_id]}</div>
                </div>
              </div>
              {country !== "" && <div className="AuthLocationInputBox-modalContainer__field">
                <div className="AuthLocationInputBox-modalContainer__field__title">Zone</div>
                <div className="AuthLocationInputBox-modalContainer__field__colon">:</div>
                <div className="AuthLocationInputBox-modalContainer__field__value">
                <div className="AuthLocationInputBox-modalContainer__field__value__option">{allLocations.zonesNames[deploymentData.zone_id]}</div>
                </div>
              </div>}
              {zone !== "" && <div className="AuthLocationInputBox-modalContainer__field">
                <div className="AuthLocationInputBox-modalContainer__field__title">State</div>
                <div className="AuthLocationInputBox-modalContainer__field__colon">:</div>
                <div className="AuthLocationInputBox-modalContainer__field__value">
                <div className="AuthLocationInputBox-modalContainer__field__value__option">{allLocations.stateNames[deploymentData.state_id]}</div>
                </div>
              </div>
              }
              {state !== "" && allLocations.hubs[state].length > 0 && <div className="AuthLocationInputBox-modalContainer__field">
                <div className="AuthLocationInputBox-modalContainer__field__title">Hub</div>
                <div className="AuthLocationInputBox-modalContainer__field__colon">:</div>
                <div className="AuthLocationInputBox-modalContainer__field__value">
                <div className="AuthLocationInputBox-modalContainer__field__value__option">{allLocations.hubNames[deploymentData.location_id]}</div>
                </div>
              </div>
              }
            </div>}</>}
            {/* {adminDesignation === "System User" || adminDesignation === "Country Manager" &&  <div className="AuthLocationInputBox-modalContainer__physicalLoc">
              <div className="AuthLocationInputBox-modalContainer__PhyTitle"> Physical Location</div>
              <div className="AuthLocationInputBox-modalContainer__field">
                <div className="AuthLocationInputBox-modalContainer__field__title">Country</div>
                <div className="AuthLocationInputBox-modalContainer__field__colon">:</div>
                <div className="AuthLocationInputBox-modalContainer__field__value">
                 <div className="AuthLocationInputBox-modalContainer__field__value__option">{allLocations.allNames[deploymentData.country_id]}</div>
                </div>
              </div>
              {country !== "" && <div className="AuthLocationInputBox-modalContainer__field">
                <div className="AuthLocationInputBox-modalContainer__field__title">Zone</div>
                <div className="AuthLocationInputBox-modalContainer__field__colon">:</div>
                <div className="AuthLocationInputBox-modalContainer__field__value">
                <div className="AuthLocationInputBox-modalContainer__field__value__option">{allLocations.allNames[deploymentData.country_id]}</div>
                </div>
              </div>}
              {zone !== "" && <div className="AuthLocationInputBox-modalContainer__field">
                <div className="AuthLocationInputBox-modalContainer__field__title">State</div>
                <div className="AuthLocationInputBox-modalContainer__field__colon">:</div>
                <div className="AuthLocationInputBox-modalContainer__field__value">
                <div className="AuthLocationInputBox-modalContainer__field__value__option">{allLocations.allNames[deploymentData.country_id]}</div>
                </div>
              </div>
              }
              {state !== "" && allLocations.hubs[state].length > 0 && <div className="AuthLocationInputBox-modalContainer__field">
                <div className="AuthLocationInputBox-modalContainer__field__title">Hub</div>
                <div className="AuthLocationInputBox-modalContainer__field__colon">:</div>
                <div className="AuthLocationInputBox-modalContainer__field__value">
                <div className="AuthLocationInputBox-modalContainer__field__value__option">{allLocations.allNames[deploymentData.country_id]}</div>
                </div>
              </div>
              }
            </div>} */}
      </div>
    </div>
  );
}


export default PhysicalLocation;