import React, { useRef, useEffect, useState } from "react";
import "./FileUploadInput.scss";
import dayjs from "dayjs";
import axios from "axios";
import { backEndIP, CDN_URL } from "../MyConfiguration";
import SuccessModelPopUp from "./FileUploadResultPopUp";

const generateRandomId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 12; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};

const FileUploadInput = ({ setOpenModal, flightId, flightLogData, userCookie, setFlightDetails, setUploadDone, setResult, result }) => {
    const [errorMessage, setErrorMessage] = useState(false)
    const fileNameTextBox = useRef()
    const fileInputElement = useRef()
    const [fileProgress, setFileProgress] = useState(0);
    const [uploadCompleted, setUploadCompleted] = useState(false);
    const [status, setStatus] = useState("")
    const [progress, setProgress] = useState(0);
    const [logfile, setLogfile] = useState(null);
    const fileExportAnchorRef = useRef(null);
    // const [fileName, setFileName] = useState(null)


    const handleFileOnChange = () => {
        setUploadCompleted(false)
        if (fileInputElement.current.files.length) {
            fileNameTextBox.current.value = fileInputElement.current.files[0].name;
            const logFileData = {
                file: fileInputElement.current.files[0],
                id: generateRandomId()
            }
            setLogfile(logFileData);
            setErrorMessage(false)
        }
        setFileProgress(100);
        //console.log(fileInputElement.current.files.length)
    };

    const exportFile = (logFileName) => {
        // Define the file to be downloaded
        const fileName = logFileName;
        const fileUrl = `${CDN_URL}/logfiles/${fileName}`;
        fileExportAnchorRef.current.href = fileUrl;
        fileExportAnchorRef.current.download = fileName;
        fileExportAnchorRef.current.click();
    }

    const handleSelectFileClick = () => {

        fileInputElement.current.click();
        setUploadCompleted(false)
        //console.log(fileInputElement.current)
    };


    const getFlightData = async () => {
        const newFlightDetails = await axios({
            url: backEndIP + `/flight/get_flightById/${flightId}`,
            method: "GET",
            headers: {
                authorization: userCookie.token
            }
        }).catch((err) => {
            console.log("error", err)
        })
        console.log("newFlightDetails", newFlightDetails)
        setFlightDetails(newFlightDetails.data.data)

    }

    const updateFlightLogFileAdded = async (fileName) => {
        try {
            console.log("fileName", fileName)
            const updateFlightData = await axios({
                url: `${CDN_URL}/flight-log-keeping/updateflight-logfile`,
                method: "PUT",
                data: {
                    logFileName: fileName,
                    access_token: userCookie.token,
                    flightId: flightId
                }
            }).catch((err) => {
                console.log("error", err)
            })
            console.log("updateFlightData",updateFlightData)
            // setFileName(null)
        } catch (err) {
            console.log("Err", err)
        }
    }

    const uploadFileAllChunks = async (fileID, fileName, selectedFile, startChunkNum = 0, chunkSize, chunkProgress, totalChunks) => {
        console.log("inside uploadFileAllChunks")
        let chunkNumber = startChunkNum;
        let start = 0;
        let end = chunkSize;
        const access_token = userCookie.token
        // let flag =true
        const uploadNextChunk = async () => {
            if (chunkNumber < totalChunks) {
                const chunk = selectedFile.slice(start, end);
                const formData = new FormData();
                formData.append("file", chunk);
                formData.append("chunkNumber", chunkNumber);
                formData.append("totalChunks", totalChunks);
                formData.append("fileName", fileName);
                formData.append("fileType", selectedFile.type === "" ? "unknown" : selectedFile.type)
                formData.append("fileId", fileID)
                formData.append("access_token", access_token)
                await axios(`${CDN_URL}/flight-log-keeping/new-add-file-part`, {
                    method: "POST",
                    data: formData
                }).then((resp) => {
                    const data = resp.data
                    // console.log("data", data);

                    const temp = `Chunk ${chunkNumber + 1
                        }/${totalChunks} uploaded successfully`;
                    // console.log(temp)
                    setStatus(temp);
                    const updatedProgress = Math.round(Number((chunkNumber + 1) * chunkProgress))
                    setProgress(updatedProgress);
                    // console.log(temp);
                    chunkNumber++;
                    start = end;
                    end = start + chunkSize;
                    uploadNextChunk();
                    if (progress >= 100) {
                        setUploadCompleted(true)
                        // setUploadDone(true)
                    }
                    // getFlightData()
                    // setOpenModal(false)

                })
                    .catch((error) => {
                        console.error("Error uploading chunk:", error);
                        alert("Error uploading chunk:", error.data.response.message)
                    });
            } else {
                setProgress(100);
                setLogfile(null);
                setStatus("File upload completed");
                console.log("All chunks uploaded succesfully")
                setUploadDone(true)
                await updateFlightLogFileAdded(fileName)
                setTimeout(() => {
                    setUploadDone(false);
                    getFlightData();
                }, 3000);

            }
        };
        uploadNextChunk();

    }
    const handleFileUpload = async () => {
        console.log("logfile", logfile)
        setUploadCompleted(false)
        if (logfile === null) {
            // setUploadComplete(true)
            alert("Please select a file to upload.");
            return;
        }
        const chunkSize = 5 * 1024 * 1024; // 5MB (adjust based on your requirements)
        const totalChunks = Math.ceil(logfile.file.size / chunkSize);
        const chunkProgress = 100 / totalChunks;
        console.log("chunkSize =", chunkSize, "totalChunks =", totalChunks, "chunkProgress =", chunkProgress)

        try {
            const createFileEntryResp = await axios(`${CDN_URL}/flight-log-keeping/creat-logfile-entry`, {
                method: "POST",
                data: {
                    fileName: logfile.file.name,
                    logfileId: logfile.id,
                    flightId: flightId,
                    fileType: logfile.file.type === "" ? "unknown" : logfile.file.type,
                    fileSize: logfile.file.size,
                    access_token: userCookie.token,
                }
            })
            console.log("createFileEntryResp", createFileEntryResp.data)
            // console.log(logfile)
            // await setFileName(createFileEntryResp.data.data.fileName)
            await uploadFileAllChunks(createFileEntryResp.data.data._id, createFileEntryResp.data.data.fileName, logfile.file, 0, chunkSize, chunkProgress, totalChunks);
            setUploadCompleted(true)
            setResult({ result: createFileEntryResp.data.status, message: createFileEntryResp.data.message })
        }
        catch (err) {
            console.log(err)
        }
    };
    // console.log("userCookie", userCookie.token)
    // console.log("fileName", fileName)
    return (
        <div className="flightUploadInput">
            <div className="flightUploadInput__subContainer">
                {flightLogData.isFlightLogAdded === false ?
                    <div className="flightUploadInput__subContainer__forward">
                        <div className="flightUploadInput__subContainer__forward__filedContainer">
                            <div className="flightUploadInput__subContainer__forward__title">Upload Log File :</div>
                            <div className="flightUploadInput__subContainer__forward__textFieldError">
                                <input
                                    //value={forwardMissionFile}
                                    ref={fileNameTextBox}
                                    placeholder={"Upload Log"}
                                    type="text"
                                    name="filedata"
                                    readOnly="readOnly"
                                    className="flightUploadInput__subContainer__forward__textField"
                                ></input>
                                {errorMessage === true && <div style={{ fontSize: "calc(14px * var(--heightmultiplier)", color: "red", textAlign: "" }}>Please Select the file before Upload</div>}
                            </div>
                            <input
                                ref={fileInputElement}
                                type="file"
                                name="uploaded_file"
                                onChange={() => { handleFileOnChange() }}
                                className="flightUploadInput__subContainer__forward__hidden"
                            />
                            <div className="flightUploadInput__subContainer__forward__buttonBox">
                                <input
                                    type="button"
                                    value="Select File"
                                    onClick={handleSelectFileClick}
                                    className="flightUploadInput__subContainer__forward__button"
                                    required
                                />
                            </div>
                        </div>
                        {logfile && <div className="flightUploadInput__subContainer__forward__val">{`File Size : ${logfile.file.size}`}</div>}
                        {logfile && (
                            <div className="flightUploadInput__subContainer__forward__progress-bar">
                                <div
                                    className="flightUploadInput__subContainer__forward__progress-bar-inner"
                                    style={{ width: `${progress}%` }}
                                >

                                </div>
                                <div
                                    className="flightUploadInput__subContainer__forward__progress-bar-number"
                                >
                                    {progress}%
                                </div>

                            </div>
                        )}
                        {!uploadCompleted ? <div className="flightUploadInput__subContainer__forward__DivBtn">
                            <button className="flightUploadInput__subContainer__forward__DivBtn__UploadbtnActive"
                                onClick={handleFileUpload}>Upload File</button>
                        </div> :
                            null}
                        {/* {progress > 0 && <div className="flightUploadInput__subContainer__forward__progress-bar" style={{ "border": "none" }}>
                            <div
                                className="flightUploadInput__subContainer__forward__progress-bar-success"
                                style={{ width: `${progress}%` }}
                            >
                                Success
                            </div>
                        </div>} */}

                    </div> :
                    <div className="flightUploadInput__subContainer__forward__flightLogField">
                        <div className="flightUploadInput__subContainer__forward__logFile">
                            <div className="flightUploadInput__subContainer__forward__logFile__title">Flight log file :</div>
                            <div className="flightUploadInput__subContainer__forward__logFile__value">{flightLogData.logFileName}</div>
                        </div>
                        <button className="flightUploadInput__subContainer__forward__DivBtn__exportBtn" onClick={() => { exportFile(flightLogData.logFileName) }}>Download File
                            <a style={{ display: "none" }} ref={fileExportAnchorRef} target="_blank"></a></button>
                    </div>
                }
            </div>
        </div>

    );
}

export default FileUploadInput;