import dayjs from "dayjs";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { VictoryAxis, VictoryBrushContainer, VictoryChart, VictoryLabel, VictoryLine, VictoryTheme, VictoryZoomContainer } from "victory";


const calculateChartData = (duration, flights) => {
    if (!duration?.end)
        return []
    const totalDays = duration.end.diff(duration.start, 'day')
    console.log("TotalDays", totalDays)
    const dateVSFlights = {}

    for (let i = 0; i < totalDays; i++) {
        const day = dayjs(duration.start).second(0).minute(0).hour(0).millisecond(0).add(i, "day")
        dateVSFlights[day.toISOString()] = 0;
    }

    flights.forEach(flight => {
        if (flight.start_time && flight.end_time) {
            const date = dayjs(flight.date_created).second(0).minute(0).hour(0).millisecond(0)
            if (!dateVSFlights[date.toISOString()]) {
                dateVSFlights[date.toISOString()] = 0;
            }
            dateVSFlights[date.toISOString()] += 1
        }
    });

    // console.log("dateVSFlights", dateVSFlights)
    const outData = []
    Object.keys(dateVSFlights).forEach(day => {
        outData.push({
            day: new Date(day),
            flights: dateVSFlights[day]
        })
    })
    // console.log("outData", outData)
    return outData
}
const chartTitle = "Flights per day for given Duration"
export default function FlightsPerDayChart({ flights, duration ,SizeMultiplier}) {
    const [state, setState] = useState({});
    const [flightData, setFlightData] = useState(calculateChartData(duration, flights))
   

    useEffect(() => {
        setFlightData(calculateChartData(duration, flights))
    }, [flights])


    function handleZoom(domain) {
        setState({
            selectedDomain: domain,
        });
    }

    function handleBrush(domain) {
        setState({ zoomDomain: domain });
    }

    return (
        <div style={{ backgroundColor: "white", width: "fit-content", height: "fit-content", paddingLeft: "10px" }}>
            <VictoryChart
                width={550 * SizeMultiplier['--widthmultiplier']}
                height={300 * SizeMultiplier['--heightmultiplier']}
                // padding={{ top: 50, left: 50, right: 50, bottom: 30 }}
                scale={{ x: "time" }}
                theme={VictoryTheme.material}
                containerComponent={
                    <VictoryZoomContainer
                        responsive={false}
                        zoomDimension="x"
                        zoomDomain={
                            state.zoomDomain
                        }
                        onZoomDomainChange={
                            handleZoom
                        }
                    />
                }
            >
                <VictoryLabel
                    x={275 * SizeMultiplier['--widthmultiplier']}
                    y={20 * SizeMultiplier['--heightmultiplier']}
                    textAnchor="middle"
                    text={chartTitle}
                />
                <VictoryLine
                    data={flightData}

                    style={{
                        data: { stroke: "#fd6125" }
                    }}
                    x={"day"}
                    y={"flights"}
                />

                <VictoryAxis
                    // orientation='bottom'
                    // tickFormat={(x) => `${x}m`}
                    theme={VictoryTheme.material}
                    style={{
                        axisLabel: { fontSize: 15, padding: (35 * SizeMultiplier['--heightmultiplier']), color: "black" },
                    }}
                    label={"Time"}
                />
                <VictoryAxis
                    dependentAxis
                    // tickFormat={(y) => `${y}m`}
                    theme={VictoryTheme.material}
                    style={{
                        axisLabel: { fontSize: 15, padding: (35 * SizeMultiplier['--widthmultiplier']), color: "black" },
                    }}
                    label={"No of Flights"}
                />
            </VictoryChart>

            <VictoryChart
                width={550 * SizeMultiplier['--widthmultiplier']}
                height={80 * SizeMultiplier['--heightmultiplier']}
                scale={{ x: "time" }}
                theme={VictoryTheme.material}
                padding={{
                    top: 0,
                    left: 50,
                    right: 50,
                    bottom: 30,
                }}
                containerComponent={
                    <VictoryBrushContainer
                        responsive={false}
                        brushDimension="x"
                        brushDomain={
                            state.selectedDomain
                        }
                        onBrushDomainChange={
                            handleBrush
                        }
                    />
                }
            >

                <VictoryLine
                    data={flightData}
                    style={{
                        data: { stroke: "#fd6125" }
                    }}
                    x={"day"}
                    y={"flights"}
                />
            </VictoryChart>
        </div>
    );
}
