import React, { useEffect, useState } from 'react'
import "./HelpSectionTabContent.scss"
import { useDispatch, useSelector } from 'react-redux';
import { selectAllhelpContentPages, selectAllhelpContentPagesLoading, setAllhelpContentPages, setAllhelpContentPagesLoading, setAllhelpContentTopics, setAllhelpContentTopicsLoading } from './HelpContentDataSlice';
import axios from 'axios';
import { backEndIP } from '../MyConfiguration';
import HelpContentPage from './HelpContentPage';

const HelpSectionTabContent = ({ userCookie }) => {
    const dispatch = useDispatch();
    const [currentWebsiteName, setCurrentWebsiteName] = useState("Clearsky");
    const [currentSelectedPage, setCurrentSelectedPage] = useState("");
    const allhelpContentPages = useSelector(selectAllhelpContentPages);
    const allhelpContentPagesLoading = useSelector(selectAllhelpContentPagesLoading);

    useEffect(() => {
        if (allhelpContentPagesLoading === "idle") {
            axios({
                url: backEndIP + "/support/help/allHelpPages/",
                method: "GET",
                headers: {
                    authorization: userCookie.token
                },
            }).then((resp) => {
                console.log(resp.data)
                dispatch(setAllhelpContentPages(resp.data.data))
                setCurrentSelectedPage(resp.data.data[currentWebsiteName][1]._id)
                dispatch(setAllhelpContentPagesLoading("done"))
                // dispatch(setAllhelpContentTopicsLoading("idle"))
            }).catch((err) => {
                console.log(err);
            })
        }
        if (allhelpContentPagesLoading === "done") {
            console.log("allhelpContentPagesLoading  =", allhelpContentPagesLoading)
            axios({
                url: backEndIP + "/support/help/allHelpTopics/",
                method: "GET",
                headers: {
                    authorization: userCookie.token
                },
            }).then((resp) => {
                console.log(resp.data)
                dispatch(setAllhelpContentTopics(resp.data.data))
                dispatch(setAllhelpContentTopicsLoading("done"))
            }).catch((err) => {
                console.log(err);
            })
        }

    }, [allhelpContentPagesLoading,currentSelectedPage])
    useEffect(() => {
        console.log(currentSelectedPage)
    }, [currentSelectedPage])
    useEffect(() => {
        if (allhelpContentPagesLoading === "done") {
            setCurrentSelectedPage(allhelpContentPages[currentWebsiteName][0]?._id)
            console.log(currentSelectedPage, allhelpContentPages[currentWebsiteName][0])
        }
    }, [currentWebsiteName])


    return (
        <div className='HelpSectionTabContent-container'>
            <div className='HelpSectionTabContent-container__navigator'>
                Website:
                <button className='HelpSectionTabContent-container__navigator__websiteBtn'
                    onClick={() => { setCurrentWebsiteName("Clearsky"); }}>Clearsky</button>
                <button className='HelpSectionTabContent-container__navigator__websiteBtn'
                    onClick={() => { setCurrentWebsiteName("Shop-Website"); }}>Shop-Website</button>
                Pages:
                {currentWebsiteName && <select className='HelpSectionTabContent-container__navigator__pageSelect'
                    defaultValue={currentSelectedPage._id}
                    onChange={(e) => { setCurrentSelectedPage(e.target.value) }}>
                    {allhelpContentPages[currentWebsiteName]?.map((item) => {
                        return <option value={item._id} key={item._id + item.pageName}>{item.pageName}</option>
                    })}
                </select>}
            </div>
            <div className='HelpSectionTabContent-container__Content'>
                <HelpContentPage contentPageId={currentSelectedPage} WebsiteName={currentWebsiteName} />
            </div>
        </div>
    )
}

export default HelpSectionTabContent