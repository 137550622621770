import dayjs from "dayjs";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { VictoryAxis, VictoryBar, VictoryBrushContainer, VictoryChart, VictoryHistogram, VictoryLabel, VictoryLine, VictoryStack, VictoryTheme, VictoryVoronoiContainer, VictoryZoomContainer } from "victory";


const calculateChartData = (duration, flights) => {
    if (!duration?.end)
        return []
    const totalDays = duration.end.diff(duration.start, 'day')
    console.log("TotalDays", totalDays)
    const dateVSFlights = {}

    for (let i = 0; i < totalDays; i++) {
        const day = dayjs(duration.start).second(0).minute(0).hour(0).millisecond(0).add(i, "day")
        dateVSFlights[day.toISOString()] = 0;
    }

    flights.forEach(flight => {
        if (flight.start_time && flight.end_time) {
            const date = dayjs(flight.date_created).second(0).minute(0).hour(0).millisecond(0)
            if (!dateVSFlights[date.toISOString()]) {
                dateVSFlights[date.toISOString()] = 0;
            }
            dateVSFlights[date.toISOString()] += 1
        }
    });

    console.log("dateVSFlights", dateVSFlights)
    const outData = []
    Object.keys(dateVSFlights).forEach(day => {
        outData.push({
            day: new Date(day),
            flights: dateVSFlights[day]
        })
    })
    console.log("outData", outData)
    return outData
}



const startDate = new Date(
    "2020-01-01T00:00:00.000Z",
);
const endDate = new Date(
    "2020-12-31T11:59:59.000Z",
);
const genres = [
    "pop",
    "rap",
    "hip-hop",
    "r&b",
    "indie",
    "alternative",
];

const listeningData = [];
for (let i = 0; i < 100; i++) {
    listeningData.push({
        day: new Date(
            Math.random(
                startDate.getTime(),
                endDate.getTime(),
            ),
        ),
        genre:
            genres[
            Math.random(0, genres.length - 1)
            ],
    });
}

const groupedData = Object.groupBy(
    listeningData,
    ({ genre }) => genre,
);

const sharedAxisStyles = {
    tickLabels: {
        fontSize: 13,
    },
    axisLabel: {
        padding: 39,
        fontSize: 13,
        fontStyle: "italic",
    },
};

const dataA = [
    { x: 'A', y: 2 },
    { x: 'B', y: 3 },
    { x: 'C', y: 5 },
    { x: 'D', y: 4 },
];
const dataB = [
    { x: 'A', y: 4 },
    { x: 'B', y: 2 },
    { x: 'C', y: 3 },
    { x: 'D', y: 6 },
];
const dataC = [
    { x: 'A', y: 3 },
    { x: 'B', y: 4 },
    { x: 'C', y: 2 },
    { x: 'D', y: 5 },
];

const chartTitle = "Flights per day for given Duration"
export default function FlightsPerDayHistogramChart({ flights, duration }) {
    const [state, setState] = useState({});
    const [flightData, setFlightData] = useState(calculateChartData(duration, flights))
    const dimentionMultiplier = 100;
    const [SizeMultiplier, setSizeMultiplier] = useState({
        "--widthmultiplier": (parseInt(window.innerWidth * dimentionMultiplier / 1920) / dimentionMultiplier),
        "--heightmultiplier": (parseInt(window.innerHeight * dimentionMultiplier / 1080) / dimentionMultiplier),
        height: window.innerHeight.toString() + "px",
        width: window.innerWidth.toString() + "px"
    });
    useLayoutEffect(() => {
        function handleResize() {
            let calFontHeight = window.innerHeight.toString() + "px";
            let calFontWidth = window.innerWidth.toString() + "px";
            let calMulHeight = (parseInt(window.innerHeight * dimentionMultiplier / 1080) / dimentionMultiplier);
            let calmulWidth = (parseInt(window.innerWidth * dimentionMultiplier / 1920) / dimentionMultiplier);
            setSizeMultiplier({ "--widthmultiplier": calmulWidth, "--heightmultiplier": calMulHeight, height: calFontHeight, width: calFontWidth });
            console.log(calmulWidth, calMulHeight);
            console.log(calFontHeight, calFontWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        setFlightData(calculateChartData(duration, flights))
    }, [flights])


    function handleZoom(domain) {
        setState({
            selectedDomain: domain,
        });
    }

    function handleBrush(domain) {
        setState({ zoomDomain: domain });
    }

    return (
        <div style={{ backgroundColor: "white", width: "fit-content", height: "fit-content", paddingLeft: "10px" }}>
            {/* <VictoryChart
                width={550 * SizeMultiplier['--widthmultiplier']}
                height={300 * SizeMultiplier['--heightmultiplier']}
                // padding={{ top: 50, left: 50, right: 50, bottom: 30 }}
                scale={{ x: "time" }}
                theme={VictoryTheme.material}
                containerComponent={
                    <VictoryZoomContainer
                        responsive={false}
                        zoomDimension="x"
                        zoomDomain={
                            state.zoomDomain
                        }
                        onZoomDomainChange={
                            handleZoom
                        }
                    />
                }
            >
                <VictoryLine
                    data={flightData}
                    x={"day"}
                    y={"flights"}
                    style={{
                        data: { stroke: "#fd6125" }
                    }}
                />

                <VictoryAxis
                    // orientation='bottom'
                    // tickFormat={(x) => `${x}m`}
                    theme={VictoryTheme.material}
                    style={{
                        axisLabel: { fontSize: 15, padding: (35 * SizeMultiplier['--heightmultiplier']), color: "black" },
                    }}
                    label={"Time"}
                />
                <VictoryAxis
                    dependentAxis
                    // tickFormat={(y) => `${y}m`}
                    theme={VictoryTheme.material}
                    style={{
                        axisLabel: { fontSize: 15, padding: (35 * SizeMultiplier['--widthmultiplier']), color: "black" },
                    }}
                    label={"No of Flights"}
                />
            </VictoryChart> */}
            <VictoryChart
                height={450}
                width={500}
                padding={70}
                domainPadding={5}
                scale={{ x: "time" }}
                containerComponent={
                    <VictoryVoronoiContainer
                        style={{}}
                        labels={({ datum }) =>
                            datum.y > 0
                                ? `${datum.y} ${datum.x} songs`
                                : null
                        }
                    />
                }
                theme={VictoryTheme.material}
            >
                <VictoryLabel
                    x={225}
                    y={25}
                    textAnchor="middle"
                    text={chartTitle}
                />

                <VictoryStack colorScale={['#FF9999', '#99CCFF', '#99FF99']}>
                    <VictoryBar data={dataA} barWidth={10} />
                    <VictoryBar data={dataB} barWidth={10} />
                    <VictoryBar data={dataC} barWidth={10} />
                </VictoryStack>

                <VictoryAxis
                    tickCount={12}
                    tickFormat={(date) =>
                        date.toLocaleString(
                            "default",
                            { month: "short" },
                        )
                    }
                    style={sharedAxisStyles}
                />

                <VictoryAxis
                    dependentAxis
                    label={"No of Flights"}
                    style={sharedAxisStyles}
                />
            </VictoryChart>

        </div >
    );
}
