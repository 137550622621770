import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { selectAllhelpContentPagesLoading, selectAllhelpContentTopics, selectAllhelpContentTopicsLoading, setAllhelpContentTopics, setAllhelpContentTopicsLoading } from './HelpContentDataSlice';
import HelpContentTopicRow from './HelpContentTopicRow';
import { backEndIP } from '../MyConfiguration';

import "./HelpContentTopics.scss"

const HelpContentTopics = ({ pageData, registerScrollTo }) => {
    const allhelpContentPagesLoading = useSelector(selectAllhelpContentPagesLoading)
    const allhelpContentTopics = useSelector(selectAllhelpContentTopics);
    const allhelpContentTopicsLoading = useSelector(selectAllhelpContentTopicsLoading);
    console.log("HelpContentTopicsPAGE", pageData, allhelpContentTopics[pageData._id])
    // useEffect(() => {
    //     if (allhelpContentPagesLoading === "done") {
    //         console.log("allhelpContentPagesLoading  =", allhelpContentPagesLoading)
    //         axios({
    //             url: backEndIP + "/support/help/allHelpTopics/",
    //             method: "GET",
    //             headers: {
    //                 authorization: userCookie.token
    //             },
    //         }).then((resp) => {
    //             console.log(resp.data)
    //             dispatch(setAllhelpContentTopics(resp.data.data))
    //             dispatch(setAllhelpContentTopicsLoading("done"))
    //         }).catch((err) => {
    //             console.log(err);
    //         })
    //     }
    // }, [allhelpContentPagesLoading,allhelpContentTopicsLoading])

    return (
        <div className='HelpContentTopics-container'>
            {allhelpContentTopicsLoading === "done" && allhelpContentTopics[pageData._id]?.map((topic, tIndex) => {
                return <HelpContentTopicRow key={topic._id + topic.topicName} topic={topic} registerScrollTo={registerScrollTo} />
            })}
            <div className='HelpContentTopics-container__addNewTopic'>
                <div>Add new Topic :</div>
                <div className='HelpContentTopics-container__addNewTopic__input'>Topic Title : <textarea style={{ width: "calc(500px * var(--widthmultiplier))", marginLeft: "5px" }} type='text' /></div>
                <div className='HelpContentTopics-container__addNewTopic__input'>Video Link : <input type='text' /></div>
                <div className='HelpContentTopics-container__addNewTopic__input'>Topic Type : <input type='text' /></div>
                <button>Add Topic</button>

            </div>
        </div>
    )
}

export default HelpContentTopics