import React, { useEffect, useState } from 'react'
import "./MissionPlanPointRow.scss"
import { commandActualParametersList, commandNames, commandParametersList } from '../commands'
import { json } from 'react-router-dom';

const MissionPlanPointRow = ({ index, data, missionPlanner, currentMissionPlan, distance }) => {
    const [isHover, setIsHover] = useState(false);
    // const [isFocus, setIsFocus] = useState(false);
    // // console.log("point data =", data)

    // useEffect(() => {
    //     setIsHover(isFocus)
    // }, [isFocus])
    const WPFrames = {
        Absolute: 0,
        Relative: 3
    }
    return (
        <div className='MissionPlanPointRow-container' 
            onMouseOver={() => { missionPlanner.setSelectedWayPoint(index) }}
            onMouseOut={() => { missionPlanner.setSelectedWayPoint(null) }}
            style={{ border: missionPlanner.selectedWayPoint === index ? "3px solid #FD6125" : "1px solid var(--primaryTextColor)", }}>
            <div className='MissionPlanPointRow-container__row1'>
                <div className='MissionPlanPointRow-container__row1__srno'>{index + 1}</div>
                {/* <div>{data.coordinates.lat}{data.coordinates.lng}</div> */}
                <div className='MissionPlanPointRow-container__row1__cmd'>
                    <div className='MissionPlanPointRow-container__row1__cmd__tag' >CMD : </div>
                    <select className='MissionPlanPointRow-container__row1__cmd-select' style={{color:"black"}}
                        onChange={(e) => {
                            console.log(e.currentTarget.value);
                            missionPlanner.updatePoint({
                                cmd: e.currentTarget.value,
                                p1: 0,
                                p2: 0,
                                p3: 0,
                                yaw: 0,
                                lat: data.lat,
                                long: data.long,
                                alt: data.alt,
                                frame: data.frame,
                            }, index);
                        }}
                        defaultValue={data.cmd}
                    >
                        {commandNames.map((item, index) => {

                            if (data.cmd === item) {
                                // console.log("data cmd = ", data.cmd, item)
                                return <option style={{color:"black"}} key={`${index}${item}`} value={item} >{item}</option>
                            }
                            return <option style={{color:"black"}} key={`${index}${item}`} value={item} > {item}</option>
                        })}
                    </select>
                </div>
                <div className='MissionPlanPointRow-container__row1__buttons'>
                    <img className='MissionPlanPointRow-container__row1__updownimg'
                        src="./upArrowIcon.png" alt="UP"
                        onClick={() => { missionPlanner.movePointUp(index) }} />
                    <img className='MissionPlanPointRow-container__row1__updownimg'
                        src="./downArrowIcon.png" alt="DN"
                        onClick={() => { missionPlanner.movePointDown(index) }} />
                    <img className='MissionPlanPointRow-container__row1__updownimg'
                        src="./deleteIcon.png" alt="DEL" onClick={() => {
                            missionPlanner.deletePoint(index)
                        }} />
                </div>
            </div>
            <div className='MissionPlanPointRow-container__row2'>
                {commandParametersList[data.cmd]?.map((paraName) => {
                    return <div className='MissionPlanPointRow-container__row2__para-container'
                        key={`${data.cmd}${data.lng}${data.lat}${paraName}`}
                        onMouseOver={() => { setIsHover(true) }}
                        onMouseOut={() => { setIsHover(false) }}
                    >
                        <div className='MissionPlanPointRow-container__row2__para-container__label' style={{color:"black"}}>{paraName}</div>
                        {commandActualParametersList[data.cmd][paraName] === "Empty" ?
                            <input className='MissionPlanPointRow-container__row2__para-container__paraInput-disabled'
                                onFocusCapture={() => { missionPlanner.setSelectedWayPoint(index) }}
                                // onFocus={() => { setIsFocus(true) }}
                                // onBlur={() => { setIsFocus(true) }}
                                // type={commandParametersDefination[data.cmd][paraName].type}
                                disabled
                                type={"number"}
                                value={data[paraName.toLocaleLowerCase()]}
                                onChange={(e) => {
                                    const temp = {
                                        cmd: data.cmd,
                                        p1: 0,
                                        p2: 0,
                                        p3: 0,
                                        yaw: 0,
                                        lat: data.lat,
                                        long: data.long,
                                        alt: 100,
                                        frame: data.frame
                                    }
                                    temp[paraName.toLocaleLowerCase()] = Number(e.target.value)
                                    missionPlanner.updatePoint(temp, index);
                                }}
                                style={{color:"black"}}
                            >
                            </input> :

                            <input className='MissionPlanPointRow-container__row2__para-container__paraInput-enabled'
                                onFocusCapture={() => { missionPlanner.setSelectedWayPoint(index) }}
                                // onFocus={() => { setIsFocus(true) }}
                                // onBlur={() => { setIsFocus(true) }}
                                // type={commandParametersDefination[data.cmd][paraName].type}
                                type={"number"}
                                value={data[paraName.toLocaleLowerCase()]}
                                onChange={(e) => {
                                    const temp = {
                                        cmd: data.cmd,
                                        p1: 0,
                                        p2: 0,
                                        p3: 0,
                                        yaw: 0,
                                        lat: data.lat,
                                        long: data.long,
                                        alt: 100,
                                        frame:data.frame
                                    }
                                    temp[paraName.toLocaleLowerCase()] = Number(e.target.value)
                                    missionPlanner.updatePoint(temp, index);
                                }}
                                style={{color:"black"}}
                            >
                            </input>
                        }
                    </div>
                })}
            </div>
            <div className='MissionPlanPointRow-container__row3'>
                <div className="MissionPlanPointRow-container__row3__frame"
                >
                    Frame : <select style={{backgroundColor:data.frame?"white":"#FD6125" ,color:"black"}}
                        defaultValue={data.frame}
                        onChange={(e) => {
                            data.frame = (parseInt(e.target.value)) 
                        }}>
                        {["Absolute", "Relative"].map((item,i) => {
                            return <option key={`${item}${i}`} value={WPFrames[item]}>{item}</option>
                        })}

                    </select>

                </div>
                <div className='MissionPlanPointRow-container__row3__distance-cont'>Distance(m):
                    <input className='MissionPlanPointRow-container__row3__distance' style={{color:"black"}}
                        type={"number"}
                        readOnly
                        value={distance}>
                    </input>
                </div>
            </div>
            {isHover && <div className='MissionPlanPointRow-container__hoverData-container'
                style={index > parseInt(currentMissionPlan.length / 2) ? { bottom: "100%" } : { top: "100%" }}>
                {Object.keys(commandActualParametersList[data.cmd]).map((item,i) => {
                    return <div key={`${item}${i}`}><strong>{item} : </strong>{commandActualParametersList[data.cmd][item]}</div>
                })}
            </div>}
        </div>


    )
}

export default MissionPlanPointRow