import React, { useRef, useEffect, useState } from "react";
import "./AddNewDroneForm.scss";
import axios from "axios";
import { backEndIP } from "../MyConfiguration";

const InputDropDownBox = ({ item, setNewDroneData, options }) => {
    const InputRef = useRef();
    //console.log(item.key)
    const handleInputChange = () => {
        setNewDroneData((prev) => {
            let obj = { ...prev };
            obj[item] = InputRef.current.value;
            return obj;
        });
        // setModelData({ ...item, key: InputRef.current.value })
    };
    useEffect(() => {
        setNewDroneData((prev) => {
            let obj = { ...prev };
            obj[item] = options[0];
            return obj;
        });
    }, [])

    return (<div className="DroneTab-content-container__dropdown-row-cont">
        <div className="DroneTab-content-container__dropdown-row-cont__label">Select Model </div>
        {/* <div className="DroneTab-content-container__dropdown-row-cont__colon"> : </div> */}
        <select className="DroneTab-content-container__dropdown-row-cont__input"
            style={{ outline: "none", border: "none" }}
            onChange={handleInputChange}
            ref={InputRef}
        >
            <option />
            {options.map((op, i) => {
                return <option key={op + i} value={op.full_model_name}>{op.full_model_name}</option>
            })
            }
        </select>
    </div>)
}


const AddNewDroneForm = ({ getModel, setFormPopup, allDrones, userCookie, setAllDrones, setAllModels, handleNotifyAlert, SetLoadingVisibility }) => {
    const modalRef = useRef();
    const [newDroneData, setNewDroneData] = useState({
        "model_no": "",
        "drone_password": "",
        "drone_version": "",
    })
    const [conformPassword, setConformPassword] = useState()

    const closeModal = e => {
        if (modalRef.current === e.target) {
            //window.location.reload(false)
            //setSelectedTab("All Flights")
            setFormPopup(false);
        }
    };

    // console.log(getModel);
    // console.log(newDroneData)
    const handelModelSubmit = () => {
        // console.log(modelData);
        if (newDroneData.drone_password === conformPassword) {
            SetLoadingVisibility(true)
            axios({
                url: backEndIP + "/drone/add_new_drone",
                method: "POST",
                headers: {
                    authorization: userCookie.token,
                },
                data: newDroneData,
            })
                .then((resp) => {
                    SetLoadingVisibility(false)
                    setFormPopup(false);
                    handleNotifyAlert(
                        "notify",
                        `Drone Added successfully`,
                        "success",
                        () => { }
                    );
                    console.log(resp.data);
                    setAllDrones([...allDrones, resp.data.data]);
                    //setFormPopup(false);
                    //setSelectedTab("Drone Models")
                })
                .catch((err) => {
                    // if (err.response.data.message) window.alert(err.response.data.message);
                    // else window.alert("some error occurred");
                    console.log(err);
                    SetLoadingVisibility(false);
                    setFormPopup(false);
                    handleNotifyAlert(
                        "alert",
                        `${err.response.message}`,
                        "failed",
                        () => { }
                    );
                });

        } else {
            // window.alert("Incorrect Conform Password");
            SetLoadingVisibility(false);
            setFormPopup(false);
            handleNotifyAlert(
                "alert",
                `Incorrect Confirm Password`,
                "failed",
                () => { }
            );
        }
        //console.log(userCookie.token)
    }
    return (
        <div onClick={closeModal} ref={modalRef} className="droneBackground">
            <div className="droneContainer">
                <div className="droneContainer__form">Register New Drone</div>
                <div className="DroneTab-Container">
                    <InputDropDownBox
                        item="model_no"
                        options={getModel}
                        setNewDroneData={setNewDroneData}></InputDropDownBox>
                    <div className="DroneTab-content-container__input-row-cont">
                        <div className="DroneTab-content-container__input-row-cont__label"
                        >Drone Password</div>
                        <div className="DroneTab-content-container__input-row-cont__colon"> : </div>
                        <input
                            className="DroneTab-content-container__input-row-cont__input"
                            type="password"
                            minLength={8}
                            onChange={(e) => { setNewDroneData({ ...newDroneData, ["drone_password"]: e.target.value }) }}
                        ></input>
                    </div>
                    <div className="DroneTab-content-container__input-row-cont">
                        <div className="DroneTab-content-container__input-row-cont__label"
                        >Conform Drone Password</div>
                        <div className="DroneTab-content-container__input-row-cont__colon"> : </div>
                        <input
                            className="DroneTab-content-container__input-row-cont__input"
                            type="password"
                            minLength={8}
                            onChange={(e) => { setConformPassword(e.target.value) }}
                        ></input>
                    </div>
                    <div className="DroneTab-content-container__input-row-cont">
                        <div className="DroneTab-content-container__input-row-cont__label"
                        >Drone Version</div>
                        <div className="DroneTab-content-container__input-row-cont__colon"> : </div>
                        <input
                            className="DroneTab-content-container__input-row-cont__input"
                            type="text"
                            minLength={2}
                            max={2}
                            onChange={(e) => { setNewDroneData({ ...newDroneData, ["drone_version"]: Number(e.target.value) }) }}
                        ></input>
                    </div>
                </div>
                <div className="DroneSubmitButton">
                    <button className="DroneSubmitButton__button" onClick={handelModelSubmit}>Submit</button>
                </div>
            </div>
        </div>

    );
}

export default AddNewDroneForm;
