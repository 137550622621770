import { useMemo } from "react";


export const usePagination = ({ totalCount, pageSize, siblingCount, currentPage }) => {

    const range = (start, end) => {
        let length = end - start + 1;

        return Array.from({ length }, (_, idx) => idx + start);
    }

    // const DOTS = "..."

    // console.log("totalCount", totalCount)
    // console.log("pageSize", pageSize)
    // console.log("siblingCount", siblingCount)
    // console.log("currentPage", currentPage)
    const paginationRange = useMemo(() => {
        // let array;
        const totalPages = Math.ceil(totalCount / pageSize);
        // console.log("totalPages", totalPages)

        const totaPageNumber = siblingCount + 5
        // console.log("totaPageNumber >= totalPages", totaPageNumber >= totalPages)
        if (totaPageNumber >= totalPages) {
            return range(1, totalPages)
        }
        // console.log("Range", range(1, totalPages))
        const leftsiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPages)
        // console.log("currentPage - siblingCount, 1", Math.max(currentPage - siblingCount, 1), currentPage - siblingCount, 1, currentPage, siblingCount)
        const shouldShowLeftDots = leftsiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPages - 2
        // console.log("leftsiblingIndex", leftsiblingIndex, leftsiblingIndex > 2)
        const firstPageIndex = 1;
        const lastPageIndex = totalPages;
        // console.log("!shouldShowLeftDots && shouldShowRightDots", !shouldShowLeftDots && shouldShowRightDots, shouldShowLeftDots, shouldShowRightDots)
        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);
            // console.log("range", [...leftRange, "DOTS", totalPages])
            return [...leftRange, "DOTS", totalPages]
        }
        // console.log("!shouldShowLeftDots && shouldShowRightDots", shouldShowLeftDots && !shouldShowRightDots, shouldShowLeftDots, shouldShowRightDots)
        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 3 + 2 * siblingCount
            let rightRange = range(totalPages - rightItemCount + 1, totalPages);
            return [firstPageIndex, "DOTS", ...rightRange]
        }
        // console.log("!shouldShowLeftDots && shouldShowRightDots", shouldShowLeftDots && shouldShowRightDots, shouldShowLeftDots, shouldShowRightDots)
        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftsiblingIndex, rightSiblingIndex);
            return [firstPageIndex, "DOTS", ...middleRange, "DOTS", lastPageIndex];
        }
        return
    }, [totalCount, pageSize, siblingCount, currentPage])

    // console.log("Pagination", paginationRange)

    return paginationRange;
}
