import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import "./EmployeeDeployPopup.scss"
import axios from 'axios';
import { backEndIP } from '../MyConfiguration';
import AuthLocationInputBox from '../AuthLocation/AuthLocation2';
import PhysicalLocation from '../AuthLocation/PhysicalLocation';
const permissionMap = {
  "Granted": true,
  "Not Granted": false
}
const EmployeeDeployPopup = ({ setOpenModal,
  setEmployees,
  adminDesignation,
  employeeDetails,
  allLocations,
  userCookie,
  handleNotifyAlert,
  SetLoadingVisibility,
  adminLocation,
  adminAuthLocType }) => {
  const modalRef = useRef();
  const [country, setCountry] = useState("");
  const [zone, setZone] = useState("");
  const [state, setState] = useState("");
  const [hubLocation, setHubLocation] = useState("");
  const [designations, setDesignations] = useState(employeeDetails.designation.toString())
  const [allDesignation, setAllDesignation] = useState([])
  const [hubManagerAndDesignation, setHubManagerAndDesignation] = useState([])
  const [paramEditPermission, setParamEditPermission] = useState(employeeDetails.isParamEditPermitted ? "Granted" : "Not Granted")

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setOpenModal();
    }
  };

  const [deploymentData, setDeploymentData] = useState({
    "employeeName": employeeDetails.useremail.toString(),
    "designation": employeeDetails.designation.toString(),
    "country_id": "",
    "zone_id": "",
    "state_id": "",
    "location_id": "",
    "newLocation": "",
    "authLocation": "",
    "authLocType": employeeDetails.authorized_location_type.toString(),
  })

  // "employeeName": employeeDetails.useremail.toString(),
  // "designation": employeeDetails.designation.toString(),
  // "country_id": employeeDetails.country,
  // "zone_id": employeeDetails.zone,
  // "state_id": employeeDetails.state,
  // "location_id": employeeDetails.location,
  // "newLocation": "",
  // "authLocation": employeeDetails.authorized_location,
  // "authLocType": employeeDetails.authorized_location_type.toString(),
  const [authLocationData, setAuthLocationData] = useState({
    "System User": { permission: -1, loc: "country", startIndex: 0, availableLocation: ["country"] },
    "CEO": { permission: 0, loc: "country", startIndex: 0, availableLocation: ["country"] },
    "Country Manager": { permission: 1, loc: "country", startIndex: 0, availableLocation: ["country"] },
    "Zone Manager": { permission: 2, loc: "zones", startIndex: 0, availableLocation: ["country", "zones"] },
    "ATC Manager": { permission: 2, loc: "zones", startIndex: 0, availableLocation: ["country", "zones"] },
    "State Manager": { permission: 3, loc: "states", startIndex: 0, availableLocation: ["country", "zones", "states"] },
    "Hub Manager": { permission: 5, loc: "hubs", startIndex: 0, availableLocation: ["country", "zones", "states", "hubs"] },
    "Testing Engineer": { permission: 5, loc: "hubs", startIndex: 0, availableLocation: ["country", "zones", "states", "hubs"] },
    "Order Manager": { permission: 5, loc: "hubs", startIndex: 0, availableLocation: ["country", "zones", "states", "hubs"] },
    "Drone Pilot": { permission: 6, loc: "hubs", startIndex: 0, availableLocation: ["country", "zones", "states", "hubs"] },
    "Drone Maintenance Engineer": { permission: 6, loc: "hubs", startIndex: 0, availableLocation: ["country", "zones", "states", "hubs"] },
    "Unassigned": { permission: 6, loc: "hubs", startIndex: 0, availableLocation: ["country", "zones", "states", "hubs"] }
  })
  const authLocationTypeKey = {
    'country': "country",
    "zone": "zones",
    "state": "states",
    "hub": "hubs",
  }

  const setAuthLocationStartIndex = (designations) => {
    // console.log("Staring executing setAuthLocationStartIndex")
    if (adminDesignation !== "System User" && adminDesignation !== "CEO") {
      // console.log("isConditiontrue", adminDesignation !== "System User" && adminDesignation !== "CEO")
      const avaiLDesignations = Object.keys(authLocationData);
      designations.forEach((desig) => {
        if (avaiLDesignations.includes(desig.designation)) {
          // console.log(desig.designation, authLocationData[desig.designation].availableLocation.length)
          let accessableArrayLength = authLocationData[desig.designation].availableLocation.length
          // console.log("accessableArrayLength", accessableArrayLength)
          for (let i = 0; i < accessableArrayLength - 1; i++) {
            // console.log("authLocation", authLocationData[desig.designation].availableLocation[i])
            // console.log("adminAuthLocType", authLocationTypeKey[adminAuthLocType])
            // console.log(desig.designation, authLocationData[desig.designation].availableLocation[i], authLocationTypeKey[adminAuthLocType], authLocationData[desig.designation].availableLocation[i] === authLocationTypeKey[adminAuthLocType])
            if (authLocationData[desig.designation].availableLocation[i] === authLocationTypeKey[adminAuthLocType]) {
              // console.log("authLOc found", authLocationTypeKey[adminAuthLocType], authLocationData[desig.designation].availableLocation[i])
              // console.log("Index", i)
              authLocationData[desig.designation].startIndex = i
            }
          }
        }
      })
    }
    setAuthLocationData(authLocationData)
  }

  useEffect(() => {
    axios({
      url: backEndIP + "/designation/getDesignation",
      method: "GET",
      headers: {
        authorization: userCookie.token
      },
    }).then(async (DesigResp) => {
      //console.log(DesigResp.data.data)
      setAllDesignation(DesigResp.data.data)
      setAuthLocationStartIndex(DesigResp.data.data)
    }).catch((err) => {
      console.log(err);
    })

  }, [])

  useEffect(() => {
    axios({
      url: backEndIP + "/designation/desig_HubManager",
      method: "GET",
      headers: {
        authorization: userCookie.token
      },
    }).then((DesigResp) => {
      // console.log(DesigResp.data.data)
      setHubManagerAndDesignation(DesigResp.data.data)
    }).catch((err) => {
      console.log(err);
    })

  }, [])

  // console.log("authLocationData", authLocationData)
  const authorizedLoc = {
    "Country Manager": deploymentData.country_id,
    "Zone Manager": deploymentData.zone_id,
    "State Manager": deploymentData.state_id,
    "Hub Manager": deploymentData.location_id,
    "CEO": deploymentData.country_id,
    "Drone Pilot": deploymentData.location_id,
    "Testing Engineer": deploymentData.location_id,
    "Order Manager": deploymentData.location_id,
  }



  const handelEmployeeDeployment = () => {
    // console.log("Inside handel employee data")
    if (country === "" || zone === "" || state === "" || hubLocation === "") {
      SetLoadingVisibility(false);
      //setOpenModal(false)
      handleNotifyAlert(
        "alert",
        `Please Enter Physical Location Details`,
        "failed",
        () => { }
      );
    } else {
      // console.log("authorizedLoc[designations]")
      axios({
        url: backEndIP + "/admin/update_employee",
        method: "PUT",
        headers: {
          authorization: userCookie.token,
        },
        data: {
          //...deploymentData
          employeeName: deploymentData.employeeName,
          designation: deploymentData.designation,
          country_id: deploymentData.country_id,
          zone_id: deploymentData.zone_id,
          state_id: deploymentData.state_id,
          location_id: deploymentData.location_id,
          newLocation: deploymentData.newLocation,
          authLocationType: deploymentData.authLocType,
          authorizedLocation: deploymentData.authLocation
        }
      }).then(async (data) => {
        SetLoadingVisibility(false)
        setOpenModal(false)
        handleNotifyAlert(
          "notify",
          `Employee deployed Successfully`,
          "success",
          () => { }
        );
        const employeesResp = await axios({
          url: backEndIP + "/admin/employee",
          // url: backEndIP + "/user/login",
          method: "GET",
          headers: {
            authorization: userCookie.token
          },
        }).catch((err) => {
          console.log(err);
        })
        setEmployees(employeesResp.data.data)
      }).catch((err) => {
        console.log(err)
        SetLoadingVisibility(false);
        setOpenModal(false)
        handleNotifyAlert(
          "alert",
          `${err.response.data.message}`,
          "failed",
          () => { }
        );
      })
    }
  }

  const unassignedEmployee = () => {
    SetLoadingVisibility(false)
    axios({
      url: backEndIP + "/admin/unAssignManager",
      method: "PUT",
      headers: {
        authorization: userCookie.token,
      },
      data: {
        //...deploymentData
        employee_id: deploymentData.employeeName,
      }
    }).then(async (resp) => {
      SetLoadingVisibility(false)
      setOpenModal(false)
      handleNotifyAlert(
        "notify",
        `${resp.data.message}`,
        "success",
        () => { }
      );
      const employeesResp = await axios({
        url: backEndIP + "/admin/employee",
        // url: backEndIP + "/user/login",
        method: "GET",
        headers: {
          authorization: userCookie.token
        },
      }).catch((err) => {
        console.log(err);
      })
      setEmployees(employeesResp.data.data)
    }).catch((err) => {
      console.log(err)
      SetLoadingVisibility(false);
      setOpenModal(false)
      handleNotifyAlert(
        "alert",
        `${err.response.data.message}`,
        "failed",
        () => { }
      );
    })
  }

  const deActiveemployeeAccount = () => {
    SetLoadingVisibility(false)
    axios({
      url: backEndIP + "/admin/deActive_employeeAccount",
      method: "PUT",
      headers: {
        authorization: userCookie.token,
      },
      data: {
        //...deploymentData
        employeeId: deploymentData.employeeName,
        active: false
      }
    }).then(async (resp) => {
      SetLoadingVisibility(false)
      setOpenModal(false)
      handleNotifyAlert(
        "notify",
        `${resp.data.message}`,
        "success",
        () => { }
      );
      const employeesResp = await axios({
        url: backEndIP + "/admin/employee",
        // url: backEndIP + "/user/login",
        method: "GET",
        headers: {
          authorization: userCookie.token
        },
      }).catch((err) => {
        console.log(err);
      })
      setEmployees(employeesResp.data.data)
    }).catch((err) => {
      console.log(err)
      SetLoadingVisibility(false);
      setOpenModal(false)
      handleNotifyAlert(
        "alert",
        `${err.response.data.message}`,
        "failed",
        () => { }
      );
    })
  }


  const handelDeactivate = () => {
    handleNotifyAlert(
      "alert",
      `Are you sure! You Want to Deactivate ${employeeDetails.userName}'s Account`,
      "alert",
      () => { deActiveemployeeAccount() }
    );
  }

  const handelUnassignment = () => {
    handleNotifyAlert(
      "alert",
      `Are you sure! You Want to Unassigned Employee: ${employeeDetails.userName}`,
      "alert",
      () => { unassignedEmployee() }
    );
  }

  const authLocationType = {
    "System User": "country",
    "CEO": "country",
    "Country Manger": "country",
    "Zone Manager": "zone",
    "State Manager": "state",
    "Hub Manager": "hub",
    "Drone Pilot": "hub",
    "Drone Maintenance Engineer": "hub"
  }

  const [localEmployee, setLocalEmployee] = useState([])
  const employee_Data = {
    "userName": "Name",
    "useremail": "Email",
    "_id": "Internal Id",
    "designation": "Designation",
    "permissionLevel": "Permission Level",
    "country": "Country",
    "zone": "Zone",
    "state": "State",
    "location": "Physical Location",
    "addedBy": "Registered by",
    "phone_no": "Phone No",
    "active": "User Status",
    "authorized_location": "Authorized Location"
  }
  const LocationsList = {
    "country": "countryNames",
    "zone": "zonesNames",
    "state": "stateNames",
    "location": "hubNames",
    "active": "Active",
    "authorized_location": employeeDetails.authorized_location_type,
    "phone_no": "phone"
    // "location": "officeNames",
  }
  const CompileContent = (data) => {
    if (LocationsList[data] === "countryNames" ||
      LocationsList[data] === "zonesNames" ||
      LocationsList[data] === "stateNames" ||
      LocationsList[data] === "hubNames") {
      return allLocations[LocationsList[data]][employeeDetails[data]]
    } else if (LocationsList[data] === "Active") {
      if (employeeDetails[data] === true) {
        return "Active";
      } else {
        return "Deactivated";
      }
    } else if (LocationsList[data] === employeeDetails.authorized_location_type) {
      return allLocations.allNames[employeeDetails.authorized_location]
    } else if (LocationsList[data] === "phone") {
      if (employeeDetails[data] === "") {
        return "N/A"
      } else {
        return employeeDetails[data]
      }
    }
    return employeeDetails[data];
  }


  useEffect(() => {
    const ownEmployee = [];
    const keys = Object.keys(employee_Data);
    keys.forEach(key => {
      // ownEmployee.push([employee_Data[key], employeeDetails[key]])
      ownEmployee.push([employee_Data[key], CompileContent(key)])
    });
    setLocalEmployee(ownEmployee)
  }, [employeeDetails])

  // console.log("Country", country)
  // console.log("zone", zone)
  // console.log("state", state)
  // console.log("hus", hubLocation)


  const handleParamEditPermissionUpdate = async () => {
    const updateEmployeeParamEditPermissionResp = await axios({
      url: backEndIP + "/admin/employee/update-param-edit-permission",
      method: "POST",
      headers: {
        authorization: userCookie.token
      },
      data: {
        employeeEmail: employeeDetails.useremail,
        isParamEditPermitted: permissionMap[paramEditPermission]
      }
    }).catch((err) => {
      console.log(err);
      handleNotifyAlert(
        "alert",
        `${err.response.data.message}`,
        "failed",
        () => { }
      );
    })

    // console.log("updateEmployeeParamEditPermissionResp", updateEmployeeParamEditPermissionResp)
    await handleNotifyAlert("alert", `${updateEmployeeParamEditPermissionResp.data.message}`, "success", () => { });
    employeeDetails.isParamEditPermitted = updateEmployeeParamEditPermissionResp.data.data.isParamEditPermitted
    setParamEditPermission(employeeDetails.isParamEditPermitted ? "Granted" : "Not Granted")
  }

  return (
    <div onClick={closeModal} ref={modalRef} className="EmployeeDeployPopup-modalBackground">
      <div className="EmployeeDeployPopup-modalContainer" >
        <div className="EmployeeDeployPopup-modalContainer__heading">Employee Deployment Form</div>
        <div className="EmployeeDeployPopup-modalContainer__data_Container">
          <div className="EmployeeDeployPopup-modalContainer__left">
            {localEmployee.map((item, i) => {
              return <div className="EmployeeDeployPopup-modalContainer__field" key={i + 1} >
                <div className="EmployeeDeployPopup-modalContainer__field__title">{item[0]}</div>
                <div className="EmployeeDeployPopup-modalContainer__field__colon">:</div>
                <div className="EmployeeDeployPopup-modalContainer__field__value">{item[1]}</div>
              </div>
            })}
          </div>
          <div className="EmployeeDeployPopup-modalContainer__right">
            {adminDesignation === "System User" && <div className="EmployeeDeployPopup-modalContainer__right__permision">
              <div className="EmployeeDeployPopup-modalContainer__right__permision__Update">
                <div className="EmployeeDeployPopup-modalContainer__right__permision__Update__title">Param-edit Permission</div>
                :
                <div className="EmployeeDeployPopup-modalContainer__right__permision__Update__options-container">
                  <input type="radio" name="approval" id="userApprovedRadio" value={"Granted"} checked={paramEditPermission === "Granted" ? true : false} onClick={(e) => { setParamEditPermission(e.target.value) }} />
                  <label className="EmployeeDeployPopup-modalContainer__right__permision__Update__options-container__inputLabel" for="userApprovedRadio">Granted</label>
                  <input type="radio" name="approval" id="userNotApprovedRadio" value={"Not Granted"} checked={paramEditPermission === "Not Granted" ? true : false} onClick={(e) => { setParamEditPermission(e.target.value) }} />
                  <label className="EmployeeDeployPopup-modalContainer__right__permision__Update__options-container__inputLabel" for="userNotApprovedRadio">Not Granted</label>
                </div>

              </div>
              {employeeDetails.isParamEditPermitted !== permissionMap[paramEditPermission] ? < button className="EmployeeDeployPopup-modalContainer__right__permision__save-button"
                onClick={handleParamEditPermissionUpdate}>save</button> : null}
            </div>}
            <div className="EmployeeDeployPopup-modalContainer__desigLoc">
              <div className="EmployeeDeployPopup-modalContainer__field">
                <div className="EmployeeDeployPopup-modalContainer__field__title unselectable">Designation</div>
                <div className="EmployeeDeployPopup-modalContainer__field__colon">:</div>
                <div className="EmployeeDeployPopup-modalContainer__field__value">
                  <select
                    // value={"Select"}
                    className="EmployeeDeployPopup-modalContainer__field__value__option"
                    onChange={(e) => {
                      if (e.currentTarget.value !== "") {
                        // console.log("Designation", e.currentTarget.value)
                        setDesignations(e.currentTarget.value)
                        setDeploymentData({ ...deploymentData, ["authLocType"]: "hub" })
                        setDeploymentData({ ...deploymentData, ["designation"]: e.target.value })
                        // setLocalEmployee(employeeDetails.designation.e.currentTarget.value)
                      } else {
                        setDesignations("")
                      }
                    }}
                  >
                    {allDesignation.map((item) => {
                      if (employeeDetails.designation === item.designation) {
                        return <option value={item.designation} selected > {item.designation}</option>
                      }
                      return <option value={item.designation} > {item.designation}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>
            {adminDesignation !== "Hub Manager" && <div className="EmployeeDeployPopup-modalContainer__field">
              <PhysicalLocation adminDesignation={adminDesignation}
                employeeDetails={employeeDetails}
                allLocations={allLocations}
                userCookie={userCookie}
                deploymentData={deploymentData}
                setDeploymentData={setDeploymentData}
                adminLocation={adminLocation}
                authLocationData={authLocationData}
                country={country}
                setCountry={setCountry}
                zone={zone}
                setZone={setZone}
                state={state}
                setState={setState}
                hubLocation={hubLocation}
                setHubLocation={setHubLocation}
                setAuthLocationData={setAuthLocationData} />
            </div>}

            <div className="EmployeeDeployPopup-modalContainer__field">
              <AuthLocationInputBox
                adminDesignation={adminDesignation}
                employeeDetails={employeeDetails}
                allLocations={allLocations}
                userCookie={userCookie}
                deploymentData={deploymentData}
                setDeploymentData={setDeploymentData}
                adminLocation={adminLocation}
                authLocationData={authLocationData}
                setCountry={setCountry}
                setZone={setZone}
                setState={setState}
                setHubLocation={setHubLocation}
                setAuthLocationData={setAuthLocationData}
              ></AuthLocationInputBox>
            </div>
            {employeeDetails.designation !== "Unassigned" && employeeDetails.permissionLevel !== 9 ? <div className="EmployeeDeployPopup-modalContainer__Unassign">
              <button className="EmployeeDeployPopup-modalContainer__Unassign__button" onClick={() => { handelUnassignment() }}>Unassign Employee</button></div> : null}
            {employeeDetails.designation === "Unassigned" && employeeDetails.active === true ? <div className="EmployeeDeployPopup-modalContainer__Unassign">
              <button className="EmployeeDeployPopup-modalContainer__Unassign__button" onClick={() => { handelDeactivate() }}>Deactive account</button></div> : null}
            {/* {employeeDetails.designation === "Unassigned" && employeeDetails.active === false ? <div className="EmployeeDeployPopup-modalContainer__Unassign">
              <button className="EmployeeDeployPopup-modalContainer__Unassign__button" onClick={() => { handelUnassignment() }}>Active</button></div>: null} */}
          </div>
        </div>
        <div className="EmployeeDeployPopup-modalContainer__button" >
          {adminDesignation !== "Hub Manager" && <div className="EmployeeDeployPopup-modalContainer__button__cancelBtn"
            onClick={() => { setOpenModal(false) }} >Cancel</div>}
          {adminDesignation !== "Hub Manager" ?
            <div className="EmployeeDeployPopup-modalContainer__button__OkayBtn" onClick={() => { handelEmployeeDeployment() }}>OK</div> :
            <div className="EmployeeDeployPopup-modalContainer__button__OkayBtn" onClick={() => { setOpenModal(false) }}>OK</div>}
        </div>
      </div>
    </div >
  );
}


export default EmployeeDeployPopup;