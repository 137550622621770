import React from 'react'
import "./AirspaceUnitsSettings.scss"

const AirspaceUnitsSettings = ({ settings, setSettings }) => {
    return (
        <div className='AirspaceUnitsSettings-container'>
            <div className='AirspaceUnitsSettings-container__field'>
                <div className='AirspaceUnitsSettings-container__field__title'>
                    Speed Units
                </div>
                <div >:</div>
                <div className='AirspaceUnitsSettings-container__field__option'>
                    <div className='AirspaceUnitsSettings-container__field__option__label'>Km/hr</div>
                    <input type='radio' name="speed" checked={settings.speedUnits === "Km/hr"} onClick={() => {
                        setSettings((prev) => { return { ...prev, speedUnits: "Km/hr" } })
                    }} />
                </div>
                <div className='AirspaceUnitsSettings-container__field__option'>
                    <div className='AirspaceUnitsSettings-container__field__option__label'>NM/hr</div>
                    <input type='radio' name="speed" checked={settings.speedUnits === "NM/hr"} onClick={() => {
                        setSettings((prev) => { return { ...prev, speedUnits: "NM/hr" } })
                    }} />
                </div>
                <div className='AirspaceUnitsSettings-container__field__option'>
                    <div className='AirspaceUnitsSettings-container__field__option__label'>m/s</div>
                    <input type='radio' name="speed" checked={settings.speedUnits === "m/s"} onClick={() => {
                        setSettings((prev) => { return { ...prev, speedUnits: "m/s" } })
                    }} />
                </div>
            </div>
        </div>
    )
}

export default AirspaceUnitsSettings