import React from 'react'
import "./HelpContentTopicRow.scss"

const HelpContentTopicRow = ({topic,registerScrollTo}) => {
  return (
    <p className='HelpContentTopicRow-container'
    onClick={()=>{registerScrollTo(topic)}}>{topic?.topicName}</p>
  )
}

export default HelpContentTopicRow