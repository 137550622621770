import "./FileUploadResultPopUp.scss";

const SuccessModelPopUp = ({ result, message }) => {
    return (
        <div className="FileUploadResultPopUp-modalBackground">
            <div className="FileUploadResultPopUp-modalContainer">
                <div className="FileUploadResultPopUp-modalContainer__image">
                    {result === "success" ? <svg width="70" height="70" viewBox="0 0 83 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M81.8118 10.1551C83.383 11.708 83.3978 14.2406 81.8449 15.8118L45.2749 52.8118C44.5235 53.5721 43.499 54 42.43 54C41.361 54 40.3365 53.5721 39.5851 52.8118L23.1551 36.1887C21.6021 34.6175 21.617 32.0848 23.1882 30.5319C24.7594 28.979 27.292 28.9938 28.8449 30.565L42.43 44.3098L76.1551 10.1882C77.708 8.61695 80.2406 8.60215 81.8118 10.1551Z" fill="#4DC821" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M75 41.5C75 60.0015 60.0015 75 41.5 75C22.9985 75 8 60.0015 8 41.5C8 22.9985 22.9985 8 41.5 8C49.8298 8 57.4495 11.0402 63.3101 16.0715L68.945 10.3702C61.6299 3.91605 52.0223 0 41.5 0C18.5802 0 0 18.5802 0 41.5C0 64.4198 18.5802 83 41.5 83C64.4198 83 83 64.4198 83 41.5C83 35.6918 81.8068 30.1622 79.6521 25.1432L73.4548 31.4133C74.4587 34.5966 75 37.9851 75 41.5Z" fill="#4DC821" />
                    </svg> : 
                    <svg width="70" height="70" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z" stroke="#F44242" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M10.1004 5.8999L5.90039 10.0999" stroke="#F44242" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M5.90039 5.8999L10.1004 10.0999" stroke="#F44242" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>}
                </div>
                {result === "success" ? <div className="FileUploadResultPopUp-modalContainer__title">Success</div> :
                <div className="FileUploadResultPopUp-modalContainer__title">Failed</div>
                }
               {result !== "success" ?<div className="FileUploadResultPopUp-modalContainer__message">{message}</div>: null}
            </div>
        </div>
    )
}

export default SuccessModelPopUp