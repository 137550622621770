import React, {
    useContext,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import "./AirspacePage.scss";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { GMapAirspacePage } from "./GMapAirspacePage";
import { selectHubNodeLocations, selectHubNodeLocationsLoading, setHubNodeLocations } from "../../ReduxStore/HubNodeLocationsSlice";
import { backEndIP, clientSocketIP } from "../../components/MyConfiguration";
import CustomAlertBox from "../../components/CustomAlert/CustomAlertBox";
import CustomNotification from "../../components/CustomNotification/CustomNotification";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import CustomPopUpTemplete from "../../components/CustomPopUpTemplete/CustomPopUpTemplete";
import AirspaceUnitsSettings from "./AirspaceUnitsSettings";

const AirspacePage = ({ userCookie, setuserCookie, removeCookie, themeGlobal }) => {

    const [socket, setSocket] = useState(io(clientSocketIP, {
        autoConnect: false,
        transports: ["websocket", "polling"],
        auth: {
            token: userCookie.token,
            page: "airspace"
        },

    }))
    const dispatch = useDispatch()
    const [allDronesInfo, setAllDronesInfo] = useState({});
    const [isConnected, setIsConnected] = useState(false);
    const [ConnectBtnString, setConnectBtnString] = useState("Connect Server");
    const [loadingVisibility, setLoadingVisibility] = useState(false);
    const [customAlertVisibility, setCustomAlertVisibility] = useState(false);
    const [abortBoxVisibility, setAbortBoxVisibility] = useState(false);
    const [timerCount, setTimerCount] = useState(10);
    const modalRef = useRef();
    const [alertBoxMesage, setAlertBoxMessage] = useState({
        message: "",
        func: () => { },
    });
    const [notificationVisibility, setNotificationVisibility] = useState(false);
    const [messageToDisplay, setMessageToDisplay] = useState({
        type: "notify",
        message: "msg",
        msgtype: "success",
        func: () => { },
    });
    const [isAborted, setIsAborted] = useState(true);

    const [routePoints, setRoutePoints] = useState([]);
    const [centerToDrone, SetCenterToDrone] = useState(false);
    const [fitRoute, setFitRoute] = useState(false);
    // const [gcsLog, setGCS_Log] = useState([]);
    const [updateSelf, setUpdateSelf] = useState(false)
    const [flightData, setFlightData] = useState({});
    const [userInitial, setUserInitial] = useState("");
    const [allAvailableDrones, setAllAvailableDrones] = useState([])
    const [allConnectedDrones, setAllConnectedDrones] = useState({})
    const [selectedDrone, setSelectedDrone] = useState("Not Selected")
    const hubNodeLocationsLoadingState = useSelector(selectHubNodeLocationsLoading)
    const hubNodeLocations = useSelector(selectHubNodeLocations);


    useEffect(() => {
        setLoadingVisibility(true)
        axios({
            url: backEndIP + "/admin/verifytoken",
            // url: backEndIP + "/user/login",
            method: "GET",
            headers: {
                authorization: userCookie.token
            },
        }).then((loginData) => {
            setuserCookie("token", loginData.data.token, { path: "/" })
            setuserCookie("username", loginData.data.userName, { path: "/" })
            setuserCookie("useremail", loginData.data.userEmail, { path: "/" })
            setAssignedDroneId(loginData.data.droneAssigned)
            SetDroneFlightId(loginData.data.flightId)
            setFlightData(loginData.data.flightData)
            console.log("Flightinfo =", loginData.data.flightId, loginData.data.flightData)
            setUserInitial(loginData.data.userName[0].toLocaleUpperCase())
            setLoadingVisibility(false)
            // if (loginData.data.flightId !== "") {
            //     socket.emit("client:getPreFlightChecklist", loginData.data.flightId)

            // }
            if (loginData.data.userEmail.split("@")[1] === "techeagle.in") {
                // navigate("/monitor")
            }
            else {
                socket.off();
                socket.close();
                navigate("/view-flight")
            }
        }).catch((err) => {
            // window.alert("login again")
            setLoadingVisibility(false)
            console.log(err);
            socket.off();
            socket.close();
            removeCookie("token");
            removeCookie("username");
            removeCookie("useremail");
            navigate("/")
        })
    }, [])

    useEffect(() => {
        if (hubNodeLocationsLoadingState === "idle") {
            axios({
                url: backEndIP + "/location/hub-node-all-locations",
                // url: backEndIP + "/user/login",
                method: "GET",
                headers: {
                    authorization: userCookie.token
                },
            }).then((respHubNodeLocations) => {
                console.log("respHubNodeLocations=", respHubNodeLocations.data)
                dispatch(setHubNodeLocations(respHubNodeLocations.data.data))
            }).catch((err) => {
                console.log(err);

            })
        }
    }, [hubNodeLocationsLoadingState])

    const handleUpdateAlertNotifyMsg = (type, message, msgtype, func) => {
        setMessageToDisplay({
            type: type,
            message: message,
            msgtype: msgtype,
            func: () => {
                func();
            },
        });
        if (type === "notify") {
            setNotificationVisibility(true);
        } else if (type === "alert") {
            setNotificationVisibility(true);
        }
    };



    useEffect(() => {
        if (selectedDrone !== "" && selectedDrone !== undefined) {
            // gcsLog.push([new Date().toTimeString().split("(")[0], `Drone ${selectedDrone} is selected`]);
            // setUpdateSelf(prev=>!prev);
        }
        console.log("selectedDrone =", selectedDrone)
    }, [selectedDrone])



    // useEffect(() => {
    //     console.log("allAvailableDrones =", allAvailableDrones[5])
    //     if (!selectedDrone?.length) {
    //         setSelectedDrone(allAvailableDrones[5]);
    //     }
    // }, [allAvailableDrones])


    const navigate = useNavigate();
    const [settings, setSettings] = useState({
        isPanelVisible: false,
        speedUnits: "Km/hr"
    })
    const [assignedDroneId, setAssignedDroneId] = useState("Unassigned")
    const [droneFlightId, SetDroneFlightId] = useState(null)



    // const RefLogBox = useRef();

    // useEffect(() => {
    //     RefLogBox.current.scrollIntoView({ behavior: "smooth" });
    // }, [gcsLog.length]);


    useEffect(() => {
        if (hubNodeLocationsLoadingState === "idle") {
            axios({
                url: backEndIP + "/location/hub-node-all-locations",
                // url: backEndIP + "/user/login",
                method: "GET",
                headers: {
                    authorization: userCookie.token
                },
            }).then((respHubNodeLocations) => {
                console.log("respHubNodeLocations=", respHubNodeLocations.data)
                dispatch(setHubNodeLocations(respHubNodeLocations.data.data))
            }).catch((err) => {
                console.log(err);

            })
        }
    }, [hubNodeLocationsLoadingState])
    useEffect(() => {
        socket.on("connect", () => {
            setIsConnected(true);
            socket.emit("upgrade");
            socket.emit("client:get_route")
            socket.emit("client:getFlightData")
            setConnectBtnString("Disconnect");
            // gcsLog.push(
            //     [new Date().toTimeString().split("(")[0], "connected to server"]
            //     // `${new Date().toTimeString().split("(")[0]}: ${"cconnect dronnected to server"}`
            // );
            setUpdateSelf(prev => !prev);
        });


        socket.on("pong", () => {
            // setLastPong(new Date().toISOString());
        });
        socket.on("server:register-drone", data => {
            const droneIndex = allAvailableDrones.indexOf(data);
            setAllAvailableDrones([...allAvailableDrones])
            if (droneIndex === -1) {
                allAvailableDrones.push(data);
                setAllAvailableDrones([...allAvailableDrones])
            }
            console.log("server:register-drone", data)
        })
        socket.on("server:monitor_data", (data) => {
            allDronesInfo[data[0]] = data[1]
            setAllDronesInfo({ ...allDronesInfo });

        });
        socket.on("server:set_route", (data) => {
            console.log(data);
            routePoints[data[0]] = data[1];
            setRoutePoints({ ...routePoints })
        });
        socket.on('server:drone_connected', (droneId) => {
            console.log("server:drone_connected", droneId, allConnectedDrones[droneId])
            // console.log('server:drone_connected', droneId, allAvailableDrones.includes(droneId))
            if (!allConnectedDrones[droneId]) {
                allConnectedDrones[droneId] = true
            }
            console.log("allAvailableDrones", allAvailableDrones)
        })
        socket.on('server:drone_disconnected', (droneId) => {
            const index = allAvailableDrones.findIndex(droneId)
            const allDronesFirst = allAvailableDrones(0, index)
            const allDronesSecond = allAvailableDrones(index + 1)
            if (index !== -1) {
                setAllConnectedDrones([...allDronesFirst, ...allDronesSecond])
            }
        })
        // socket.on("server:gcs_log", (data) => {

        //     // gcsLog.push([new Date().toTimeString().split("(")[0], data]
        //     //     // `${new Date().toTimeString().split("(")[0]}: ${data}`
        //     // );
        //     setUpdateSelf(prev => !prev);
        // });


        socket.on("disconnect", () => {
            setIsConnected(false);
            setAllAvailableDrones([])
            setAllDronesInfo({})
            setSelectedDrone(prev => "Not selected");
            setConnectBtnString("Connect Server");
            // gcsLog.push([new Date().toTimeString().split("(")[0], "disconnected from server"]);
            setUpdateSelf(prev => !prev);
        });

        return () => {
            socket.off("connect");
            socket.off("disconnect");
            socket.off("pong");
            socket.off("server:register-drone");
            socket.off("server:arm_status");
            socket.off("server:monitor_data");
            socket.off("server:set_route");
            socket.off("server:gcs_log");
            socket.off("server:drone_connected")
            socket.off();
            socket.close();
        };
    }, [socket]);


    const handleConnectClick = (e) => {

        if (isConnected) {
            socket.disconnect();
        }
        else {
            setConnectBtnString("Connecting");
            socket.connect();
        }

    };

    const [currentMissionPlanRoutePoints, setCurrentMissionPlanRoutePoints] = useState([]);
    // console.log("allConnectedDrones", allConnectedDrones)

    const setSettingsVisibility = (state) => {
        setSettings((prev) => { return { ...prev, isPanelVisible: state } })
    }
    return (
        <div className="airspace-page-container">
            <div className="airspace-sidebar">
                <div className="airspace-sidebar__logodiv">
                    <img
                        className="airspace-sidebar__logodiv__logoimg"
                        src={themeGlobal.nameLogo}
                        alt="logo"
                        onClick={() => { navigate("/dashboard") }}
                    />
                    <svg className="airspace-sidebar__logodiv__settingImg"
                        onClick={() => {
                            setSettings(prev => {
                                return { ...prev, isPanelVisible: !prev.isPanelVisible }
                            })
                        }}
                        width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.9946 13.5213C11.7898 13.5213 12.5525 13.2031 13.1149 12.6369C13.6772 12.0706 13.9931 11.3026 13.9931 10.5018C13.9931 9.70104 13.6772 8.93304 13.1149 8.36679C12.5525 7.80054 11.7898 7.48242 10.9946 7.48242C10.1993 7.48242 9.43666 7.80054 8.87433 8.36679C8.31201 8.93304 7.99609 9.70104 7.99609 10.5018C7.99609 11.3026 8.31201 12.0706 8.87433 12.6369C9.43666 13.2031 10.1993 13.5213 10.9946 13.5213Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M1 11.3857V9.6143C1 8.56757 1.84958 7.70201 2.89905 7.70201C4.70815 7.70201 5.44778 6.41372 4.53823 4.83356C4.01849 3.92773 4.32834 2.75016 5.23788 2.22679L6.96702 1.23038C7.75662 0.757341 8.77611 1.03915 9.24588 1.83427L9.35582 2.0255C10.2554 3.60566 11.7346 3.60566 12.6442 2.0255L12.7541 1.83427C13.2239 1.03915 14.2434 0.757341 15.033 1.23038L16.7621 2.22679C17.6717 2.75016 17.9815 3.92773 17.4618 4.83356C16.5522 6.41372 17.2919 7.70201 19.1009 7.70201C20.1404 7.70201 21 8.55751 21 9.6143V11.3857C21 12.4324 20.1504 13.298 19.1009 13.298C17.2919 13.298 16.5522 14.5863 17.4618 16.1664C17.9815 17.0823 17.6717 18.2498 16.7621 18.7732L15.033 19.7696C14.2434 20.2427 13.2239 19.9608 12.7541 19.1657L12.6442 18.9745C11.7446 17.3943 10.2654 17.3943 9.35582 18.9745L9.24588 19.1657C8.77611 19.9608 7.75662 20.2427 6.96702 19.7696L5.23788 18.7732C4.8022 18.5206 4.48384 18.1042 4.35267 17.6155C4.2215 17.1268 4.28823 16.6057 4.53823 16.1664C5.44778 14.5863 4.70815 13.298 2.89905 13.298C1.84958 13.298 1 12.4324 1 11.3857Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>


                </div>
                <div className="airspace-sidebar__logodiv-border"></div>
                <div className="airspace-sidebar__DroneName">

                    <div className='airspace-sidebar__DroneName__droneInfo__droneid'>
                        <div className='airspace-sidebar__DroneName__droneInfo__droneid__title'>Drone Selected</div>
                        <div className='airspace-sidebar__DroneName__droneInfo__droneid__value'>{selectedDrone}</div>
                    </div>

                </div>
                <div className="airspace-sidebar__DroneName">

                    <div className='airspace-sidebar__DroneName__droneInfo__flightid'>
                        <div className='airspace-sidebar__DroneName__droneInfo__flightid__title'>Flight ID</div>
                        <div className='airspace-sidebar__DroneName__droneInfo__flightid__value'>{droneFlightId !== null ? droneFlightId : "Null"}</div>
                    </div>

                </div>
                <div className="airspace-sidebar__DroneName">

                    <div className='airspace-sidebar__DroneName__droneInfo__flightid'>
                        <div className='airspace-sidebar__DroneName__droneInfo__flightid__title'>Connected Drones</div>
                        <div className='airspace-sidebar__DroneName__droneInfo__flightid__value'>
                            <div className="airspace-sidebar__droneSelector2">
                                {Object.keys(allConnectedDrones).map((droneId) => {
                                    // console.log("droneId", allConnectedDrones)
                                    return <div className={`airspace-sidebar__droneSelector2__droneId-text${droneId === selectedDrone ? "-selected" : ""}`}
                                        onClick={() => { setSelectedDrone(droneId) }}>{droneId}</div>

                                })}
                            </div>
                        </div>
                    </div>

                </div>
                {/* <div className="airspace-sidebar__droneSelector">
                    {Object.keys(allConnectedDrones).map((droneId) => {
                        // console.log("droneId", allConnectedDrones)
                        return <div className={`airspace-sidebar__droneSelector__droneId-text${droneId === selectedDrone ? "-selected" : ""}`}
                            onClick={() => { setSelectedDrone(droneId) }}>{droneId}</div>

                    })}
                </div> */}
                <button className="airspace-sidebar__logout"
                    onClick={() => {
                        setAlertBoxMessage({
                            message:
                                "Are you sure you want Logout?",
                            func: () => {
                                removeCookie("token");
                                removeCookie("username");
                                removeCookie("useremail");
                                socket.off();
                                socket.close()
                                navigate("/")
                            },
                        });
                        setCustomAlertVisibility(true);
                    }}>
                    Logout
                </button>
            </div>
            <div className="airspace-main-map">
                <div className="airspace-main-map__topInfobar">

                    <div className="airspace-main-map__topInfobar__connectBtn-container">
                        <button
                            className="airspace-main-map__topInfobar__connectBtn-container__connectBtn"
                            onClick={handleConnectClick}
                        >
                            {ConnectBtnString}
                        </button>
                    </div>


                    <div className="airspace-main-map__topInfobar__user">
                        <div className="airspace-main-map__topInfobar__user__proImg"
                        >{userInitial}</div>
                    </div>
                </div>
                <div className="airspace-main-map__GoogleMapContainer">

                    <GMapAirspacePage
                        // checklistDone={checklistDone}
                        userSettings={settings}
                        routePoints={routePoints}
                        allAvailableDrones={allAvailableDrones}
                        allDronesInfo={allDronesInfo}
                        selectedDrone={selectedDrone}
                        setSelectedDrone={setSelectedDrone}
                        droneLat={allDronesInfo[selectedDrone]?.lat}
                        droneLong={allDronesInfo[selectedDrone]?.long}
                        heading={allDronesInfo[selectedDrone]?.heading}
                        centerToDrone={centerToDrone}
                        fitRoute={fitRoute}
                        hubNodeLocations={hubNodeLocations}
                        SetCenterToDrone={SetCenterToDrone}
                        isConnected={isConnected}
                        missionRoutePoints={currentMissionPlanRoutePoints}

                    />

                    <button
                        className="airspace-main-map__GoogleMapContainer__followDroneBtn"
                        onClick={() => {
                            SetCenterToDrone(true);
                        }}
                    >
                        Follow Drone
                    </button>
                    <button
                        className="airspace-main-map__GoogleMapContainer__ZoomtoRoute"
                        onClick={() => {
                            setFitRoute(prev => !fitRoute);
                        }}
                    >
                        Fit Route
                    </button>

                </div>
                <div className="airspace-main-map__footer-container">
                    <div className="airspace-main-map__footer-container__copyright">
                        <img
                            className="airspace-main-map__footer-container__copyright__company-logo"
                            src={themeGlobal.EagleLogo}

                        ></img>
                        <div className="airspace-main-map__footer-container__copyright__title">
                            Copyright&#169; 2024. TechEagle Innovations Pvt.Ltd.
                        </div>
                    </div>

                </div>
            </div>
            {customAlertVisibility && (
                <CustomAlertBox
                    setOpenModal={setCustomAlertVisibility}
                    messageToDisplay={alertBoxMesage}
                    setIsAborted={setIsAborted}
                />
            )}

            {notificationVisibility && (<CustomNotification setOpenModal={setNotificationVisibility} messageToDisplay={messageToDisplay} />)}
            {loadingVisibility && <LoadingScreen />}
            {settings.isPanelVisible && <CustomPopUpTemplete content={<AirspaceUnitsSettings settings={settings} setSettings={setSettings}/>} pageTitle={"Settings"} setOpenModal={setSettingsVisibility} />}
        </div>
    );
};

export default AirspacePage;
