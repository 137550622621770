import React from 'react'
import MissionPlanPointRow from '../MissionPlanPointRow/MissionPlanPointRow'



const MissionPointsViewer = ({ currentMissionPlan, missionPlanner }) => {

    const rad = function (x) {
        return x * Math.PI / 180;
    };

    const getDistanceBetweenPoints = function (p1, p2) {
        var R = 6378137; // Earth’s mean radius in meter
        var dLat = rad(p2.lat() - p1.lat());
        var dLong = rad(p2.lng() - p1.lng());
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat())) *
            Math.sin(dLong / 2) * Math.sin(dLong / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d; // returns the distance in meter
    };

    return (
        <div className='MissionPointsViewer-container'>
            {currentMissionPlan.map((point, index) => {
                let distance = 0;
                if (index > 0) {
                    const p1 = currentMissionPlan[index - 1]
                    const p2 = point
                    distance = getDistanceBetweenPoints({ lat: () => { return p1.lat }, lng: () => { return p1.long } }, { lat: () => { return p2.lat }, lng: () => { return p2.long } })
                }
                distance = Math.round(distance, 1)
                return <MissionPlanPointRow index={index}
                    key={`${index}${point.cmd}${point.lat}${index}`}
                    data={point}
                    missionPlanner={missionPlanner}
                    currentMissionPlan={currentMissionPlan}
                    distance={distance}
                />
            })}
        </div>
    )

}

export default MissionPointsViewer