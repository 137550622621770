import dayjs from "dayjs";
import React, { useRef } from "react";
import "./OrderDetailsPopup.scss";

function OrderDetailsPopup({ setOpenModal, order }) {
  const modalRef = useRef();

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setOpenModal(false);
    }
  };

  const locations = {
    "63930f82865ec3abed90bc1b": "Mendipathar PHC",
    "639310eb865ec3abed90bc1f": "Pedaldoba PHC",
    "639311e2865ec3abed90bc23": "Nongalbibra PHC",
    "6393126c865ec3abed90bc27": "Shallang PHC",
    "639312ff865ec3abed90bc2b": "Samanda PHC",
  }
  console.log(order.cart)
  return (
    <div onClick={closeModal} ref={modalRef} className="OrderDetailsPopup-modalBackground">
      <div className="OrderDetailsPopup-modalContainer" >
        <div className="OrderDetailsPopup-modalContainer__order-no">Order No : {order.order_no}</div>
        {/* <div>Source Location: {order.source_location}</div>
        <div>Order No : {order.destination_location}</div>
        <div>Order No : {order.total_weight}</div>
        <div>Order No : {order.delivery_type}</div>
        <div>Order No : {order.delivery_type==="Urgent"?"As soon as Possible":dayjs(order.delivery_date).format("YYYY-MM-DD HH:mm").toString()}
        <div>Order No : {current_status[1]}</div>*/}
        <div className="OrderDetailsPopup-modalContainer__item">User Email : {order.useremail}</div> 
        {order.cart.map((item) => {
          return <div className="OrderDetailsPopup-modalContainer__item">{item.name} : {item.quantity} units</div>
        })}

      <div className="OrderDetailsPopup-modalContainer__OkayBtn" onClick={() => { modalRef.current.click()}}>OK</div>
      </div>
    </div>
  );
}

export default OrderDetailsPopup;
