import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import "./FlightMarkCompletedDone.scss";
import FlightStatusIndicator from "../FlightStatusIndicator/FlightStatusIndicator";
import FileUploadInput from "../FileUploadInput/FileUploadInput";
import SuccessModelPopUp from "../FileUploadInput/FileUploadResultPopUp";
import axios from "axios";
import { backEndIP } from "../MyConfiguration";

const CancelFlightPopUp = ({ setOpenModal, handelCancelMarked }) => {
    const modalRef = useRef();
    const closeModal = e => {
        if (modalRef.current === e.target) {
            setOpenModal();
        }
    };
    return (
        <div onClick={closeModal} ref={modalRef} className="FlightConfirmMarkCompeletePopup-modalBackground">
            <div className="FlightConfirmMarkCompeletePopup-modalContainer">
                <img className="custom-alert-box-img" src="failedIcon-480.svg" alt="" />
                <div className="FlightConfirmMarkCompeletePopup-modalContainer__heading" >Are you sure you want to cancel this flight?</div>
                {/* <div className="FlightConfirmMarkCompeletePopup-modalContainer__heading" >Are you sure!</div> */}
                <div className="custom-alert-box-button-container">
                    <div onClick={() => { modalRef.current.click(); }} id="cancelBtn">Cancel</div>
                    <div onClick={()=>{handelCancelMarked()}}id="OkayBtn">OK</div>
                </div>
            </div>

        </div>
    );
}

export default CancelFlightPopUp;
