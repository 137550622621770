import React, { useState } from 'react'

const MissionPlanIcon = ({ width, height, state, setState }) => {
    const [isHover, setIsHover] = useState(false);
    return (
        <div className='home-main-map__GoogleMapContainer__navbar__MapNavIcons__container'>
            <svg width={width} height={height} viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg"
                className='home-main-map__GoogleMapContainer__navbar__MapNavIcons'
                onMouseOver={() => { setIsHover(true) }}
                onMouseOut={() => { setIsHover(false) }}
                onClick={() => { setState() }}>

                <circle cx="47.985" cy="48.1998" r="46.1038" fill={state ? "#FD6125" : "#08030A"} stroke={isHover || state ? "#FD6125" : "#08030A"} strokeWidth="4" />
                <path d="M82.3982 42.8588C85.7977 49.0174 83.5611 56.7657 77.4025 60.1653C71.244 63.5648 63.4956 61.3282 60.0961 55.1696C56.6965 49.0111 58.9332 41.2627 65.0917 37.8632C71.2503 34.4636 78.9986 36.7003 82.3982 42.8588Z" fill={state ? "#08030A" : "#FD6125"} fillOpacity="0.2" stroke={state ? "#08030A" : "#FD6125"} strokeWidth="2.26483" />
                <path d="M75.7479 50.5659C75.7479 52.9138 73.8759 54.4526 70.9094 54.4526C67.9428 54.4526 66.0391 52.8821 65.8329 50.3597L68.8629 50.3597C68.8947 51.4384 69.6085 52.1206 70.8459 52.1206C71.8771 52.1206 72.5592 51.724 72.5592 51.0101C72.5592 50.5183 72.0675 50.1534 71.4012 50.0265L69.323 49.6299C67.4986 49.2809 66.293 48.218 66.293 46.3778C66.293 44.252 68.1649 42.7608 70.719 42.7608C73.4318 42.7608 75.383 44.3155 75.5575 46.7744L72.5275 46.7744C72.4482 45.7433 71.7502 45.077 70.7507 45.077C69.8624 45.077 69.323 45.5529 69.323 46.1716C69.323 46.6792 69.8306 46.9806 70.4335 47.0917L72.6386 47.52C74.6691 47.9166 75.7479 48.9161 75.7479 50.5659Z" fill={state ? "#08030A" : "#FD6125"} />
                <path d="M26.9766 61.4576C27.7857 68.0694 33.3486 75.2722 38.5351 76.37C45.0183 77.7423 48.1576 67.826 48.1591 54.1215C48.1606 38.9973 48.9058 27.3293 57.9959 25.1237C63.3246 23.8307 69.1518 26.4461 70.468 35.4989" stroke={state ? "#08030A" : "#FD6125"} strokeWidth="2.26535" strokeDasharray="4.06 4.06" />
                <path d="M49.1281 42.6268C48.847 42.0707 48.0515 42.0751 47.7765 42.6342L41.8291 54.7276C41.5816 55.2309 41.9499 55.819 42.5108 55.8159L54.5371 55.7504C55.098 55.7473 55.4599 55.1553 55.2069 54.6547L49.1281 42.6268Z" fill={state ? "#08030A" : "#FD6125"} />
                <path fillRule="evenodd" clipRule="evenodd" d="M38.0674 41.4939C41.77 48.2015 39.334 56.6406 32.6264 60.3433C25.9187 64.0459 17.4796 61.6099 13.7769 54.9022C10.0743 48.1946 12.5104 39.7554 19.218 36.0528C25.9256 32.3502 34.3648 34.7862 38.0674 41.4939ZM20.9033 53.6896H23.9023V49.1356H28.1072V53.6896H31.1062V42.2966H28.1072V46.5333H23.9023V42.2966H20.9033V53.6896Z" fill={state ? "#08030A" : "#FD6125"} />
            </svg>
            <div className='home-main-map__GoogleMapContainer__navbar__MapNavIcons__container__dot'
                style={{ backgroundColor: state ?"#FD6125": null, opacity: state ? 1 : 0 }}
            >
            </div>
        </div>

    )
}

export default MissionPlanIcon