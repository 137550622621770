import React, { useEffect, useState } from 'react';
import "./DroneManagementTab.scss"
import dayjs from 'dayjs';
import AddNewDroneForm from '../AddNewDrone/AddNewDroneForm';
import axios from 'axios';
import { backEndIP } from '../MyConfiguration';
import AssignPilot from '../AssignPilot/AssignPilot';
//import AddNewModelForm from '../AddNewModel/AddNewModelForm';


const DroneManagementTab = ({ allDrones, userCookie, setAllDrones, setSelectedTab, SetLoadingVisibility, handleNotifyAlert, locations }) => {
    const [addDroneForm, setAddDroneForm] = useState(false)
    const [droneDetailsPopup, setDroneDetailPopup] = useState(false)
    const [getModel, setGetModel] = useState([])
    const [selectedDrone, setSelectedDrone] = useState()
    const [searchId, setSearchId] = useState("")
    const [filteredDroneData, setFliteredDroneData] = useState([])
    const [allHub, setAllHub] = useState([]);

    const handleAddModelButton = () => {
        setAddDroneForm(true)
    }
    useEffect(() => {
        axios({
            url: backEndIP + "/dronemodel/get_drone_model",
            method: "GET",
            headers: {
                authorization: userCookie.token
            }
        }).then((modelData) => {
            // console.log(modelData.data)
            setGetModel(modelData.data.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [])


    useEffect(() => {
        const getHubdata = () => {
            axios({
                url: backEndIP + "/hub/gethub",
                // url: backEndIP + "/user/login",
                method: "GET",
                headers: {
                    authorization: userCookie.token
                },
            }).then((hubResp) => {
                // console.log(hubResp.data)
                setAllHub(hubResp.data.data);
                //console.log(allHub)
            }).catch((err) => {
                console.log(err);
            })
        }
        axios({
            url: backEndIP + "/drone/get_all_drones",
            method: "GET",
            headers: {
                authorization: userCookie.token
            }
        }).then((modelData) => {
            //console.log(modelData.data)
            setAllDrones(modelData.data.data)
        }).catch((err) => {
            console.log(err)
        })
        if (userCookie.designation) {
            getHubdata()
        }
    }, [])

    // const locations = {
    //     "63bd5b3ccafee0c35772f8f1": "Office",
    //     "63932c7ab1cab628335ae969": "Jengjal",
    //     "63930f82865ec3abed90bc1b": "Mendipathar PHC",
    //     "639310eb865ec3abed90bc1f": "Pedaldoba PHC",
    //     "639311e2865ec3abed90bc23": "Nongalbibra PHC",
    //     "6393126c865ec3abed90bc27": "Shallang PHC",
    //     "639312ff865ec3abed90bc2b": "Samanda PHC",
    // }
    useEffect(() => {
        setAllDrones(allDrones.sort((a, b) => (a.internal_id.slice(0, 4) > b.internal_id.slice(0, 4) ? 1 : -1)))
        if (searchId === "") {
            setFliteredDroneData(allDrones.sort((a, b) => (a.internal_id.slice(0, 4) > b.internal_id.slice(0, 4) ? 1 : -1)))
        } else {
            const searchValue = new RegExp(`.*${searchId}.*`, 'i')
            // console.log("searchValue", searchValue)
            const filteredDroneArray = allDrones.filter((item) => {
                // console.log(item.internal_id == searchValue, item.internal_id, searchValue)
                return searchValue.test(item.internal_id)
            })
            // console.log("filteredDroneArray", filteredDroneArray)
            setFliteredDroneData(filteredDroneArray.sort((a, b) => (a.internal_id.slice(0, 4) > b.internal_id.slice(0, 4) ? 1 : -1)))
        }
        // console.log(allDrones)
    }, [allDrones, searchId])

    // console.log("allHub", getModel)
    // console.log("adminDesignation", userCookie)

    const handelSelectHub = (e) => {
        // console.log("Handel select hub", e.target.value)
        // console.log("allDrones", allDrones)
        if( e.target.value === "All"){
            setFliteredDroneData(allDrones.sort((a, b) => (a.internal_id.slice(0, 4) > b.internal_id.slice(0, 4) ? 1 : -1)))
        } else {
            const filteredDroneArray = allDrones.filter((item) => {
                // console.log(item)
                if (e.target.value === item.current_location) {
                    return item
                }
            })
            setFliteredDroneData(filteredDroneArray.sort((a, b) => (a.internal_id.slice(0, 4) > b.internal_id.slice(0, 4) ? 1 : -1)))
        }
        
    }

    const handelSelcetedModel = (e) => {
        // console.log("Handel select Model", e.target.value)
        // console.log("allDrones", allDrones)
        if( e.target.value === "All"){
            setFliteredDroneData(allDrones.sort((a, b) => (a.internal_id.slice(0, 4) > b.internal_id.slice(0, 4) ? 1 : -1)))
        } else {
            const filteredDroneArray = allDrones.filter((item) => {
                // console.log(item)
                if (e.target.value === item.model_no) {
                    return item
                }
            })
            setFliteredDroneData(filteredDroneArray.sort((a, b) => (a.internal_id.slice(0, 4) > b.internal_id.slice(0, 4) ? 1 : -1)))
        }
        
    }

    return (
        <div className='MainDroneManagementTab'>
            <div className='DroneManagementSearch'>
                <div className='DroneManagementSearch__box'>
                    <input className='DroneManagementSearch__box__input'
                        placeholder='Enter Drone Id'
                        onChange={(e) => setSearchId(e.target.value)} />
                    <img src={"search.svg"} alt={"search"} />
                </div>
                {userCookie.designation === "System User" && <div className='DroneManagementSearch__filter'><div className='DroneManagementSearch__filter__title' >Choose Hub : </div>
                    <select className='DroneManagementSearch__filter__options' onChange={(e) => { handelSelectHub(e) }}>
                        <option>All</option>
                        {allHub.map((hub) => {
                            return <option key={`hubsOptions${hub._id}`} value={hub._id}>{hub.location_name}</option>
                        })}
                    </select></div>}
                    <div className='DroneManagementSearch__filter'><div className='DroneManagementSearch__filter__title' >Select Model : </div>
                    <select className='DroneManagementSearch__filter__options' 
                    onChange={(e) => { handelSelcetedModel(e) }}
                    >
                        <option>All</option>
                        {getModel.map((model) => {
                       
                            return <option key={`hubsOptions${model.full_model_name}`} value={model.full_model_name}>{model.full_model_name}</option>
                        })}
                    </select></div>
            </div>
            <div className='DroneManagementContent-container'>
                <div className='DroneManagementContent-datacontainer'>
                    <div className='DroneManagementContent-container__index'>
                        <div className='DroneManagementContent-container__index__droneNo DroneManagementContent-container__index__term'>
                            Drone No
                        </div>
                        <div className='DroneManagementContent-container__index__modelNo DroneManagementContent-container__index__term'>
                            Model No
                        </div>
                        <div className='DroneManagementContent-container__index__payload DroneManagementContent-container__index__term' >
                            Payload Capacity(kg)
                        </div>
                        <div className='DroneManagementContent-container__index__maxRange DroneManagementContent-container__index__term'>
                            Maximum Range(Km)
                        </div>
                        <div className='DroneManagementContent-container__index__flightHrs DroneManagementContent-container__index__term'>
                            Flight Hours (HH:mm:ss)
                        </div>
                        <div className='DroneManagementContent-container__index__mfgDate DroneManagementContent-container__index__term' >
                            Manufacturing Date
                        </div>
                        <div className='DroneManagementContent-container__index__lmDate DroneManagementContent-container__index__term'>
                            Last Maintenance Date
                        </div>
                        <div className='DroneManagementContent-container__index__versionD DroneManagementContent-container__index__term'>
                            Drone Version
                        </div>
                        <div className='DroneManagementContent-container__index__pilotId DroneManagementContent-container__index__term'>
                            Authorized Pilot
                        </div>
                        <div className='DroneManagementContent-container__index__currLoca DroneManagementContent-container__index__term' >
                            Hub Location
                        </div>
                        <div className='DroneManagementContent-container__index__button DroneManagementContent-container__index__term' >
                            Detail
                        </div>

                    </div>
                    {filteredDroneData.map((drone, i) => {
                        //console.log(allDrones, i)
                        const droneV = drone.drone_version % 10;
                        const secondDigit = drone.drone_version / 10;
                        const version = secondDigit + "." + droneV
                        if (drone.hub_location === "63bd5b3ccafee0c35772f8f1") {
                            return null
                        } else {
                            return <div key={drone._id} className='DroneManagementContent-datacontainer__row'>
                                <div className='DroneManagementContent-datacontainer__row__droneNo DroneManagementContent-datacontainer__row__term'>
                                    {drone.internal_id.slice(0, 4)}
                                </div>
                                <div className='DroneManagementContent-datacontainer__row__modelNo DroneManagementContent-datacontainer__row__term'>
                                    {drone.model_no}
                                </div>
                                <div className='DroneManagementContent-datacontainer__row__payload DroneManagementContent-datacontainer__row__term'>
                                    {drone.payload_capacity}Kg
                                </div>
                                <div className='DroneManagementContent-datacontainer__row__maxRange DroneManagementContent-datacontainer__row__term'>
                                    {drone.max_range}Km
                                </div>
                                <div className='DroneManagementContent-datacontainer__row__flightHrs DroneManagementContent-datacontainer__row__term'>
                                    {drone.flight_hours.hours + " : " + drone.flight_hours.minutes + " : " + drone.flight_hours.seconds}
                                </div>
                                <div className='DroneManagementContent-datacontainer__row__mfgDate DroneManagementContent-datacontainer__row__term'>
                                    {dayjs(drone.manufacturing_date).format("YYYY-MM-DD HH:mm").toString()}
                                </div>
                                <div className='DroneManagementContent-datacontainer__row__lmDate DroneManagementContent-datacontainer__row__term'>
                                    {dayjs(drone.last_maintenance_date).format("YYYY-MM-DD HH:mm").toString()}
                                </div>
                                <div className='DroneManagementContent-datacontainer__row__versionD DroneManagementContent-datacontainer__row__term'>
                                    {drone.drone_version}
                                </div>
                                <div className='DroneManagementContent-datacontainer__row__pilotId DroneManagementContent-datacontainer__row__term'>
                                    1.{drone.pilot_id1.split("@")[0]}<br /> 2.{drone.pilot_id2.split("@")[0]}
                                </div>
                                <div className='DroneManagementContent-datacontainer__row__currLoca DroneManagementContent-datacontainer__row__term'>
                                    {locations[drone.hub_location]}
                                </div>
                                <div className='DroneManagementContent-datacontainer__row__button DroneManagementContent-datacontainer__row__term'>
                                    <button className='DroneManagementContent-datacontainer__btn' onClick={() => {
                                        setDroneDetailPopup(true);
                                        setSelectedDrone(drone)
                                    }}>Get Detail</button>
                                </div>
                            </div>
                        }
                    })}
                </div>
            </div>
            {/* <div className='AddNewDroneButton'><button className='AddNewDroneButton__Btn' onClick={() => { handleAddModelButton() }}>Register Drone</button></div>
            {addDroneForm ? <AddNewDroneForm setFormPopup={setAddDroneForm} allDrones={allDrones} getModel={getModel} userCookie={userCookie} setAllDrones={setAllDrones} setGetModel={setGetModel} setSelectedTab={setSelectedTab}/> : null} */}
            {droneDetailsPopup && <AssignPilot setDetailsPopUp={() => { setSelectedTab("Drone Management"); setDroneDetailPopup(false) }} setAllDrones={setAllDrones} allDrones={allDrones} droneTobeManage={selectedDrone} userCookie={userCookie} SetLoadingVisibility={SetLoadingVisibility} handleNotifyAlert={handleNotifyAlert} locations={locations} />}
        </div>
    )
}

export default DroneManagementTab