import React, { useState } from 'react'

const MonitorDroneIcon = ({ width, height, state, setState }) => {
    const [isHover, setIsHover] = useState(false);
    return (
        <div className='home-main-map__GoogleMapContainer__navbar__MapNavIcons__container'>
            <svg width={width} height={height} viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg"
                className='home-main-map__GoogleMapContainer__navbar__MapNavIcons'
                onMouseOver={() => { setIsHover(true) }}
                onMouseOut={() => { setIsHover(false) }}
                onClick={() => { setState() }}>
                <circle cx="48.3591" cy="48.2424" r="46.1186" fill={state ? "#08030A" : "#FD6125"} stroke={isHover || !state ? "#FD6125" : "#08030A"} strokeWidth="4" />
                <path d="M21.9127 28.6436C20.6007 28.6436 19.5391 29.7063 19.5391 31.0172V66.2271C19.5391 67.538 20.6007 68.6007 21.9127 68.6007H42.5357C42.5357 68.6115 42.531 68.6225 42.531 68.6335C42.4545 69.1704 42.3131 70.0289 42.1045 70.7872C41.9956 71.1792 41.8681 71.5446 41.7221 71.825C41.3674 72.5048 40.7207 73.2141 40.1226 73.7843C39.7101 74.1781 39.9813 74.943 40.5515 74.943H56.7564C57.3127 74.943 57.5862 74.2186 57.1968 73.8223C56.6567 73.272 56.0657 72.5935 55.7249 71.9618C55.259 71.0937 54.9507 69.4552 54.8163 68.621C54.8163 68.6144 54.8116 68.6074 54.8116 68.6007H74.8018C76.1138 68.6007 77.1754 67.538 77.1754 66.2271V31.0172C77.1754 30.1146 76.6724 29.33 75.9307 28.9284C75.5946 28.7467 75.2098 28.6436 74.8018 28.6436H21.9127Z" fill={state ? "#FD6125" : "#08030A"} />
                <rect x="23.1016" y="31.8804" width="50.5147" height="33.3403" rx="2.29123" fill="#08030A" />
                <path d="M31.8008 51.7697L43.4067 44.5758L50.9017 51.3528L61.0031 43.7896" stroke="#FD6125" strokeWidth="1.04286" />
                <rect x="29.8203" y="49.8242" width="3.9372" height="3.9372" fill="#F2F2F2" />
                <rect x="41.0234" y="41.9829" width="3.9372" height="3.9372" fill="#F2F2F2" />
                <rect x="48.7344" y="49.7124" width="3.9372" height="3.9372" fill="#F2F2F2" />
                <rect x="58.3203" y="41.9829" width="3.9372" height="3.9372" fill="#F2F2F2" />
            </svg>
            <div className='home-main-map__GoogleMapContainer__navbar__MapNavIcons__container__dot'
                style={{ backgroundColor: state?null:"#FD6125", opacity:state?0:1}}
                >
            </div>
        </div>

    )
}

export default MonitorDroneIcon