import React from 'react'

import "./LocationDropDown.scss"
function LocationDropDown({ AllLocations, setLocation,location, label }) {
    
    return (
        <div className='RouteSelect'>
            <div className='RouteSelect__fields-label'>{label}</div>
            <select style={{outline:"none",border:"none"}}
                onChange={(e) => {
                    // console.log(e.currentTarget.value)
                    setLocation(e.currentTarget.value)
                }}
                className='RouteSelect__fields-options'
                value={location}>
                {/* <div>{endLocation!==""?endLocation:"Landing Location"}</div> */}
                {AllLocations.map((loc) => {
                    return <option key={label+loc}  value={loc}>{loc}</option>

                })
                }
            </select>
        </div>
    )
}

export default LocationDropDown