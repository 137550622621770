import React, { useEffect, useRef, useState } from 'react'
import { Circle, GoogleMap, GoogleMarkerClusterer, InfoBox, Marker, MarkerClusterer, Polyline } from '@react-google-maps/api';
import { useJsApiLoader } from '@react-google-maps/api';
import { mapOptions } from './MyConfiguration';
import ElevationGraph from './ElevationGraph/ElevationGraph';
import WaypointMarker from './GoogleMapsCustomComponents/WaypointMarker';
import OriginWaypointMarker from './GoogleMapsCustomComponents/OriginWaypointMarker';
import DestinationWaypointMarker from './GoogleMapsCustomComponents/DestinationWaypointMarker';
import DroneMarker from './GoogleMapsCustomComponents/DroneMarker';
import { getDistanceBetweenPoints, getTotalDistance } from './utility';
// import markerDrone from 'drone2vector.svg'



function GMapViewOnly(props) {
    const { routePoints,
        droneLat, droneLong,
        heading, centerToDrone, fitRoute,
        SetCenterToDrone,
        PageOpen,
        isConnected, isMissionPlaning,
    } = props;
    const [prevDroneLocation, SetPrevDroneLocation] = useState({ lat: droneLat ? droneLat : 25.85573935116686, lng: droneLong ? droneLong : 90.35195512885268 });
    const [droneLastLocation, setDroneLastLocation] = useState({ lat: droneLat ? droneLat : 25.85573935116686, lng: droneLong ? droneLong : 90.35195512885268 })
    const [isDroneLatSet, SetisDroneLatSet] = useState(false);
    const [locDiff, SetlocDiff] = useState({ lat: 0, lng: 0 });

    const [prevDroneAngle, SetPrevDroneAngle,] = useState(heading);
    const [isDroneAngleSet, SetisDroneAngleSet] = useState(false);
    const [angleDiff, SetAngleDiff] = useState(0);

    const [positionM1, setPositionM1] = useState({
        lat: 25.85573935116686, lng: 90.35195512885268
    })
    const [imgAngle, setImgAngle] = useState(30);
    const [cardShow, setCardShow] = useState(false);
    const [ownMapCenter, setOwnMapCenter] = useState({ lat: 25.917101231771113, lng: 90.64956864386703 });
    const altitudeInputRef = useRef();
    const [routeOptions, setRouteOptions] = useState({
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 5,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: true,
        visible: true,
        paths: routePoints,
        zIndex: 10
    })
    const polyLineRef = useRef();
    const [markerAnchors, setMarkerAnchors] = useState({
        wp: null,
        owp: null,
        dwp: null
    })

    const routes = [
        // { lat: 25.65554589592577, lng: 90.35196585740401 },
        // { lat: 25.970128750000608, lng: 90.41011400454289 },
        // { lat: 25.917101231771113, lng: 90.64956864386703 },
        // { lat: 25.530009648762853, lng: 90.8591667000106 },
        { lat: 25.45279714240047, lng: 90.7127885290625 },

        { lat: 25.57566372263245, lng: 90.49134440018842 },
        // { lat: 25.45279714240047, lng: 90.7127885290625 }
        // { lat: 25.658606, lng: 90.351402 },
        // { lat: 25.658527, lng: 90.354292 },

        // { lat: 25.654866, lng: 90.354915 }
    ]

    const GmapRef = useRef();


    useEffect(() => {
        if (isDroneLatSet) {
            // if (droneLastLocation.lat !== prevDroneLocation.lat || droneLastLocation.lng !== prevDroneLocation.lng) {
            //     SetPrevDroneLocation({ lat: droneLat, lng: droneLong })
            // }
            let lat = (droneLat - prevDroneLocation.lat) / 15;
            let lng = (droneLong - prevDroneLocation.lng) / 15;
            SetlocDiff({ lat: lat, lng: lng });


            const latLngTimer = setInterval(() => {
                SetPrevDroneLocation((prev) => {
                    let out = {
                        lat: prev.lat,
                        lng: prev.lng
                    }
                    let latsign = 0
                    if (locDiff.lat < 0) {
                        latsign = -1;
                        if (prev.lat >= droneLat) {
                            out.lat = prev.lat + locDiff.lat
                        }
                        else {
                            out.lat = prev.lat
                        }
                    }
                    else {
                        latsign = 1;
                        if (prev.lat <= droneLat) {
                            out.lat = prev.lat + locDiff.lat
                        }
                        else {
                            out.lat = prev.lat
                        }

                    }
                    let lngsign = 0
                    if (locDiff.lng < 0) {
                        lngsign = -1;
                        if (prev.lng >= droneLong) {
                            out.lng = prev.lng + locDiff.lng
                        }
                        else {
                            out.lng = prev.lng
                        }
                    }
                    else {
                        lngsign = 1;
                        if (prev.lng <= droneLong) {
                            out.lng = prev.lng + locDiff.lng
                        }
                        else {
                            out.lng = prev.lng
                        }
                    }
                    return out;

                })


            }, 25);
            // setDroneLastLocation({ lat: droneLat, lng: droneLong })

            return () => { clearInterval(latLngTimer) }
        }
    }, [droneLat, isDroneLatSet])

    const onMapLoad = (mapInstance) => {
        GmapRef.current = mapInstance;
        GmapRef.current.setMapTypeId("satellite")
        const bounds = new window.google.maps.LatLngBounds();
        HUBandDeliveryLandingLocations.forEach((marker) => {
            bounds.extend({ lat: marker.lat, lng: marker.lng })
        })
        GmapRef.current.fitBounds(bounds);
        setMarkerAnchors({
            wp: new window.google.maps.Point(15, 45),
            owp: new window.google.maps.Point(15, 45),
            dwp: new window.google.maps.Point(15, 45)
        })
    }
    useEffect(() => {
        if (GmapRef.current) {

            const bounds = new window.google.maps.LatLngBounds();
            if (routePoints.length) {
                {
                    routePoints.forEach((marker) => {
                        bounds.extend({ lat: marker.lat, lng: marker.lng })
                    })
                }
            } else {
                HUBandDeliveryLandingLocations.forEach((marker) => {
                    bounds.extend({ lat: marker.lat, lng: marker.lng })
                })
            }
            GmapRef.current.fitBounds(bounds);

        }
    }, [fitRoute])


    const { isLoaded } = useJsApiLoader({
        id: mapOptions.googleMapApiKey,
        googleMapsApiKey: mapOptions.googleMapApiKey,
    })

    const containerStyle = {
        width: '100%',
        height: '100%',
        borderRadius: "30px"
        // background: "pink"
    };

    const HUBandDeliveryLandingLocations = [
        {
            tag: "HUB",
            name: "Jengjal",
            lat: 25.64760280,
            lng: 90.32645700,
            hoverFunc: () => { },
            MouseOutFunc: () => { }
        },
        {
            tag: "L1",
            name: "Mendipathar PHC",
            lat: 25.917101231771113,
            lng: 90.64956864386703,
            hoverFunc: () => { },
            MouseOutFunc: () => { }
        },
        {
            tag: "L2",
            name: "Pedaldoba PHC",
            lat: 25.970128750000608,
            lng: 90.41011400454289,
            hoverFunc: () => { },
            MouseOutFunc: () => { }
        },
        {
            tag: "L3",
            name: "Nongalbibra PHC",
            lat: 25.45279714240047,
            lng: 90.7127885290625,
            hoverFunc: () => { },
            MouseOutFunc: () => { }
        },
        {
            tag: "L4",
            name: "Shallang PHC",
            lat: 25.530009648762853,
            lng: 90.8591667000106,
            hoverFunc: () => { },
            MouseOutFunc: () => { }
        },
        {
            tag: "L5",
            name: "Samanda PHC",
            lat: 25.57566372263245,
            lng: 90.49134440018842,
            hoverFunc: () => { },
            MouseOutFunc: () => { }
        }
    ]






    useEffect(() => {
        if (routePoints?.length && isLoaded) {
            const bounds = new window.google.maps.LatLngBounds();
            // HUBandDeliveryLandingLocations.slice(0, 3).forEach((marker) => {
            //     bounds.extend({ lat: marker.lat, lng: marker.lng })
            // })
            routePoints.forEach((marker) => {
                bounds.extend({ lat: marker.lat, lng: marker.lng })
            })
            // if(droneLat){
            //     bounds.extend({ lat: droneLat, lng: droneLong })
            // }
            GmapRef.current.fitBounds(bounds);
        }
    }, [routePoints.length])

    useEffect(() => {
        if (centerToDrone && GmapRef.current) {
            // GmapRef.current.setCenter({ lat: droneLat, lng: droneLong });
            if (prevDroneLocation.lat) {
                GmapRef.current?.setCenter({ lat: prevDroneLocation?.lat, lng: prevDroneLocation?.lng });
            }
        }
        if (!isDroneLatSet) {
            SetPrevDroneLocation((prev) => {
                console.log("inside")
                return { lat: droneLat, lng: droneLong }
            })
        }

        if (droneLat) {
            SetisDroneLatSet(true)
            // isDroneLatSet = true;
        }


    }, [droneLat, droneLong, centerToDrone, prevDroneLocation.lat, heading]);


    const [routeTrailPoints, setRouteTrailPoints] = useState([]);
    const [isTrailTimerOn, setIsTrailTimerOn] = useState(false);
    const routeTrailOptions = {
        strokeColor: '#FFFF00',
        strokeOpacity: 0.6,
        strokeWeight: 4,
        fillColor: '#FFFF00',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 300,
        paths: routeTrailPoints,
        zIndex: 100
    };
    useEffect(() => {
        let trailIntervalTimerId;
        if (routeTrailPoints.length > 5) {
            trailIntervalTimerId = setInterval(() => {
                if (routeTrailPoints.length > 0) {
                    routeTrailPoints.pop();
                }
            }, 400);
            setIsTrailTimerOn(true);
            if (routeTrailPoints.length <= 1) {
                clearInterval(trailIntervalTimerId);
                setRouteTrailPoints([])
                setIsTrailTimerOn(false);
            }
            return () => {
                clearInterval(trailIntervalTimerId)
                setIsTrailTimerOn(false);
            }
        }
        if (routeTrailPoints.length <= 1) {
            clearInterval(trailIntervalTimerId);
            setIsTrailTimerOn(false);
        }
    }, [routeTrailPoints])

    useEffect(() => {
        routeTrailPoints.unshift({ lat: droneLat, lng: droneLong });
        if (routeTrailPoints.length >= 50) {
            routeTrailPoints.pop();
        }
        setRouteTrailPoints([...routeTrailPoints])

    }, [droneLat])

    useEffect(() => {
        if (isConnected) {
            setRouteTrailPoints([])
        }
    }, [isConnected])


    return (isLoaded && (
        <>
            <GoogleMap
                mapContainerStyle={containerStyle}

                center={ownMapCenter}
                // zoom={9}
                options={{
                    styles: mapOptions.mapTheme,
                    disableDefaultUI: false, draggable: true,
                    mapTypeControl: false,
                }}

                onLoad={onMapLoad}
                onZoomChanged={() => { SetCenterToDrone(false) }}
                onDragStart={() => { SetCenterToDrone(false) }}
            // onCenterChanged={() => { GmapRef.current && console.log(GmapRef.current.fitBounds(routes)) }}
            //  onCenterChanged={()=>{window.google.maps.LatLngBounds(GMap).get}}
            >
                {routeTrailPoints.length > 1 && routeTrailPoints.map((point, index) => {
                    if (index < routeTrailPoints.length - 1 && index > 2) {
                        const tempOptions = { ...routeTrailOptions };
                        const tempPaths = [routeTrailPoints[index], routeTrailPoints[index + 1]]
                        tempOptions.paths = tempPaths
                        return <Polyline key={`${"trailpoints"} index ${point.lat} ${point.lng}`}
                            // onLoad={onLoad}
                            path={tempPaths}
                            options={tempOptions}
                        />
                    }
                })}
                <MarkerClusterer gridSize={90} minimumClusterSize={HUBandDeliveryLandingLocations.length}
                    options={
                        {
                            imagePath: './HubMarker',
                            imageExtension: "svg",
                        }
                    }
                    title='Jengjal'
                    calculator={() => {
                        return {
                            text: '',
                            index: 1,
                            title: 'Jengjal',
                        }

                    }}

                >
                    {
                        (clusterer) =>
                            HUBandDeliveryLandingLocations.map((location) => {
                                if (location.tag === "HUB") {
                                    return <Marker cursor={"default"} clickable={false} zIndex={4} label={{ text: `${location.tag}:${location.name}`, className: "markerLabelCss" }} key={`marker${location.tag}1`} position={location}
                                        clusterer={clusterer} icon={{
                                            url: "./HubMarker.svg", anchor: new window.google.maps.Point(17, 40),
                                        }} />
                                }
                                else {
                                    return <Marker cursor={"default"} clickable={false} zIndex={4} label={{ text: `${location.tag}:${location.name}`, className: "markerLabelCss" }} key={`marker${location.tag}1`} position={location}
                                        clusterer={clusterer} icon={{ url: "./NodeMarker.svg", anchor: new window.google.maps.Point(17, 40) }} />
                                }

                            })
                    }
                </MarkerClusterer>
                <>
                    {routePoints?.length && <MarkerClusterer gridSize={40}
                        minimumClusterSize={routePoints.length <= 1 ? 5 : routePoints.length}>
                        {(clusterer) => routePoints.map((loc, index) => {
                            if (index === 0) {
                                return < Marker
                                    key={`routePointswaypoint${loc.tag}${index}`}
                                    // label={{ text: (index + 1).toString(), color: 'white', fontSize: "20px", className: "GmapCustomMarkerLabels" }}
                                    options={{
                                        zIndex: 5,
                                        clickable: false,
                                        draggable: false,
                                        cursor: "default"
                                    }} animation={window.google.maps.Animation.DROP}
                                    clusterer={clusterer} position={loc} icon={{ url: "./Origin.svg", anchor: markerAnchors.wp }} />
                            }
                            else if (index === routePoints.length - 1) {
                                return < Marker key={`routePointswaypoint${loc.tag}${index}`}
                                    // label={{ text: (index + 1).toString(), color: 'white', fontSize: "20px", className: "GmapCustomMarkerLabels" }}
                                    options={{
                                        zIndex: 5,
                                        clickable: false,
                                        draggable: false,
                                        cursor: "default"
                                    }} animation={window.google.maps.Animation.DROP}
                                    clusterer={clusterer} position={loc} icon={{ url: "./Destination.svg", anchor: markerAnchors.wp }} />
                            }
                            else {
                                return < Marker key={`routePointswaypoint${loc.tag}${index}`}
                                    // label={{ text: (index + 1).toString(), color: 'white', fontSize: "20px", className: "GmapCustomMarkerLabels" }}
                                    options={{
                                        zIndex: 5,
                                        clickable: false,
                                        draggable: false,
                                        cursor: "default"
                                    }} animation={window.google.maps.Animation.DROP}
                                    clusterer={clusterer} position={loc} icon={{ url: "./Waypoint.svg", anchor: markerAnchors.wp }} />
                            }
                        })}
                    </MarkerClusterer>}
                    {routePoints?.length ? <Polyline
                        // onLoad={onLoad}
                        path={routePoints}
                        options={{
                            ...routeOptions,
                            clickable: false,
                            draggable: false,
                            editable: false,
                        }}
                    /> : null}
                    {droneLat && <InfoBox

                        position={{

                            lat: droneLat ? prevDroneLocation.lat : positionM1.lat,
                            lng: droneLong ? prevDroneLocation.lng : positionM1.lng
                        }}
                        options={{
                            pixelOffset: new window.google.maps.Size(-100, -80, "px", "px"),
                            closeBoxURL: '',
                            zIndex: 0
                        }}
                    // zIndex={5}
                    >
                        <div style={{ opacity: 1, padding: "50px", zIndex: 0 }}>
                            <img //className='trapezoid-container__img-container__img' 
                                src='./drone_img.png'
                                style={{
                                    scale: 0.5,
                                    transform: `rotate(${heading}deg) translateY(26px)`, zIndex: 0, width: "100px",
                                    // transition: `all 0.5s linear `
                                }}
                            />
                        </div>
                    </InfoBox>}
                </>
            </GoogleMap >
        </>)
    );

}






export default GMapViewOnly;

