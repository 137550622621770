import React, { useEffect, useRef, useState } from 'react'
import { Circle, GoogleMap, GoogleMarkerClusterer, InfoBox, Marker, MarkerClusterer, Polyline } from '@react-google-maps/api';
import { useJsApiLoader } from '@react-google-maps/api';
import { mapOptions } from './MyConfiguration';
import ElevationGraph from './ElevationGraph/ElevationGraph';
import WaypointMarker from './GoogleMapsCustomComponents/WaypointMarker';
import OriginWaypointMarker from './GoogleMapsCustomComponents/OriginWaypointMarker';
import DestinationWaypointMarker from './GoogleMapsCustomComponents/DestinationWaypointMarker';
import DroneMarker from './GoogleMapsCustomComponents/DroneMarker';
import { getDistanceBetweenPoints, getTotalDistance } from './utility';
import HubAndLocMarker from './GmapComponents/HubAndLocMarker';
// import markerDrone from 'drone2vector.svg'



function GMapMissionPlanning(props) {
    const { zoomLevel, setZoomLevel, routePoints,
        droneLat, droneLong,
        heading, centerToDrone, fitRoute,
        hubNodeLocations,
        SetCenterToDrone,
        PageOpen,
        isConnected, isMissionPlaning,
        missionPlanner,
        missionRoutePoints,
        elevationGraphVisibility,
        setelevationGraphVisibility } = props;
    const [prevDroneLocation, SetPrevDroneLocation] = useState({ lat: droneLat ? droneLat : 25.85573935116686, lng: droneLong ? droneLong : 90.35195512885268 });
    const [droneLastLocation, setDroneLastLocation] = useState({ lat: droneLat ? droneLat : 25.85573935116686, lng: droneLong ? droneLong : 90.35195512885268 })
    const [isDroneLatSet, SetisDroneLatSet] = useState(false);
    const [locDiff, SetlocDiff] = useState({ lat: 0, lng: 0 });

    const [prevDroneAngle, SetPrevDroneAngle,] = useState(heading);
    const [isDroneAngleSet, SetisDroneAngleSet] = useState(false);
    const [angleDiff, SetAngleDiff] = useState(0);
    const [allHubAndNodes, setAllHubAndNodes] = useState([])
    const [allLocationsTree, setAllLocationsTree] = useState({})
    const [allLocationsData, setAllLocationsData] = useState({})
    const [myHub, setMyHub] = useState({
        tag: "HUB",
        name: "default",
        lat: 25.85573935116686,
        lng: 90.35195512885268,
        hoverFunc: () => { },
        MouseOutFunc: () => { }
    })
    const [positionM1, setPositionM1] = useState({
        lat: 25.85573935116686, lng: 90.35195512885268
    })
    const [imgAngle, setImgAngle] = useState(30);
    const [cardShow, setCardShow] = useState(false);
    const [ownMapCenter, setOwnMapCenter] = useState({ lat: 25.917101231771113, lng: 90.64956864386703 });
    const altitudeInputRef = useRef();
    const [routeOptions, setRouteOptions] = useState({
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 5,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: true,
        visible: true,
        paths: missionRoutePoints,
        zIndex: 10
    })
    const polyLineRef = useRef();
    const [markerAnchors, setMarkerAnchors] = useState({
        wp: null,
        owp: null,
        dwp: null
    })

    const routes = [
        // { lat: 25.65554589592577, lng: 90.35196585740401 },
        // { lat: 25.970128750000608, lng: 90.41011400454289 },
        // { lat: 25.917101231771113, lng: 90.64956864386703 },
        // { lat: 25.530009648762853, lng: 90.8591667000106 },
        { lat: 25.45279714240047, lng: 90.7127885290625 },

        { lat: 25.57566372263245, lng: 90.49134440018842 },
        // { lat: 25.45279714240047, lng: 90.7127885290625 }
        // { lat: 25.658606, lng: 90.351402 },
        // { lat: 25.658527, lng: 90.354292 },

        // { lat: 25.654866, lng: 90.354915 }
    ]

    const GmapRef = useRef();


    useEffect(() => {
        if (isDroneLatSet) {
            // if (droneLastLocation.lat !== prevDroneLocation.lat || droneLastLocation.lng !== prevDroneLocation.lng) {
            //     SetPrevDroneLocation({ lat: droneLat, lng: droneLong })
            // }
            let lat = (droneLat - prevDroneLocation.lat) / 15;
            let lng = (droneLong - prevDroneLocation.lng) / 15;
            SetlocDiff({ lat: lat, lng: lng });


            const latLngTimer = setInterval(() => {
                SetPrevDroneLocation((prev) => {
                    let out = {
                        lat: prev.lat,
                        lng: prev.lng
                    }
                    let latsign = 0
                    if (locDiff.lat < 0) {
                        latsign = -1;
                        if (prev.lat >= droneLat) {
                            out.lat = prev.lat + locDiff.lat
                        }
                        else {
                            out.lat = prev.lat
                        }
                    }
                    else {
                        latsign = 1;
                        if (prev.lat <= droneLat) {
                            out.lat = prev.lat + locDiff.lat
                        }
                        else {
                            out.lat = prev.lat
                        }

                    }
                    let lngsign = 0
                    if (locDiff.lng < 0) {
                        lngsign = -1;
                        if (prev.lng >= droneLong) {
                            out.lng = prev.lng + locDiff.lng
                        }
                        else {
                            out.lng = prev.lng
                        }
                    }
                    else {
                        lngsign = 1;
                        if (prev.lng <= droneLong) {
                            out.lng = prev.lng + locDiff.lng
                        }
                        else {
                            out.lng = prev.lng
                        }
                    }
                    return out;

                })


            }, 25);
            // setDroneLastLocation({ lat: droneLat, lng: droneLong })

            return () => { clearInterval(latLngTimer) }
        }
    }, [droneLat, isDroneLatSet])

    const onMapLoad = (mapInstance) => {
        GmapRef.current = mapInstance;
        GmapRef.current.setMapTypeId("satellite")
        const bounds = new window.google.maps.LatLngBounds();
        allHubAndNodes.forEach((marker) => {
            bounds.extend({ lat: marker.lat, lng: marker.lng })
        })
        GmapRef.current.fitBounds(bounds);
        setMarkerAnchors({
            wp: new window.google.maps.Point(15, 45),
            owp: new window.google.maps.Point(15, 45),
            dwp: new window.google.maps.Point(15, 45)
        })
    }
    useEffect(() => {
        if (GmapRef.current) {
            if (isMissionPlaning) {

                const bounds = new window.google.maps.LatLngBounds();
                if (missionRoutePoints.length) {
                    missionRoutePoints.forEach((marker) => {
                        bounds.extend({ lat: marker.lat, lng: marker.lng })
                    })
                } else {
                    allHubAndNodes.forEach((marker) => {
                        bounds.extend({ lat: marker.lat, lng: marker.lng })
                    })
                }
                GmapRef.current.fitBounds(bounds);
            }
            else {
                const bounds = new window.google.maps.LatLngBounds();
                if (routePoints.length) {
                    {
                        routePoints.forEach((marker) => {
                            bounds.extend({ lat: marker.lat, lng: marker.lng })
                        })
                    }
                } else {
                    allHubAndNodes.forEach((marker) => {
                        bounds.extend({ lat: marker.lat, lng: marker.lng })
                    })
                }
                GmapRef.current.fitBounds(bounds);
            }
        }
    }, [fitRoute, allHubAndNodes])


    const { isLoaded } = useJsApiLoader({
        id: mapOptions.googleMapApiKey,
        googleMapsApiKey: mapOptions.googleMapApiKey,
    })

    const containerStyle = {
        width: '100%',
        height: '100%',
        borderRadius: "30px"
        // background: "pink"
    };

    const center = { lat: 25.917101231771113, lng: 90.64956864386703 };
    // const center = {
    //     lat: 28.508015,
    //     lng: 77.078819
    // };
    let count = 28.508015;
    // const dronePosition={
    //     lat: 28.508015,
    //     lng: 77.078819
    // }
    const [rightCLickMenuVisibility, setRightCLickMenuVisibility] = useState(false);
    const [clickLocation, SetClickLocation] = useState(center);
    const [goToLocationPopUpVisibility, setGoToLocationPopUpVisibility] = useState(false)
    const [droneTakeoffPopUpVisibility, setDroneTakeoffPopUpVisibility] = useState(false)
    const [totalDistance, setTotalDistance] = useState(0)



    useEffect(() => {
        getTotalDistance(missionRoutePoints);
        // console.log("totalDistance = ", totalDistance, missionRoutePoints)
    }, [missionRoutePoints])
    // const [elevationArr, setElevationArr] = useState([])

    // const getAllElevationMap = () => {

    //     let coordinatesurl = ""
    //     console.log(elevationArr)
    //     const tempArr = elevationArr.slice(0)
    //     tempArr.forEach((loc, i) => {
    //         if(i<tempArr.length-1)
    //         coordinatesurl = `${coordinatesurl}${loc.lat},${loc.lng}|`
    //         if (i === tempArr.length - 1) {
    //             // elevationUrl = `${elevationUrl}${elevationArr[elevationArr.length - 1].lat},${elevationArr[elevationArr.length - 1].lng}&key=${mapOptions.googleMapApiKey}`
    //             coordinatesurl = coordinatesurl + loc.lat + ',' + loc.lng

    //             config.url = config.url + coordinatesurl;
    //             console.log(config.url)
    //         }
    //     })
    // }

    const [elevationData, setElevationData] = useState({
        terrain: [],
        altitude: (() => {
            let distance = 0
            const elevation = missionRoutePoints.map((point, i) => {
                if (i === 0) {
                    return { lat: point.lat, lng: point.lng, x: 0, y: 591 + missionPlanner.mission[i].alt }
                }
                else {
                    distance += getDistanceBetweenPoints({
                        lat: () => missionRoutePoints[i - 1].lat,
                        lng: () => missionRoutePoints[i - 1].lng,
                    }, {
                        lat: () => missionRoutePoints[i].lat,
                        lng: () => missionRoutePoints[i].lng,
                    })

                    return {
                        lat: point.lat,
                        lng: point.lng,
                        x: distance,
                        y: 591 + missionPlanner.mission[i].alt
                    }

                }
            })
            return elevation
        }
        )()
    })
    // const [tempArr, setTempArr] = useState([])
    let tempArr = [];
    const [relatedAltEle, setRelatedAltEle] = useState([0])
    const latLngAddToGetElevationArr = (iLoc, jLoc, locDiff, locAdder, count = tempArr.length, x = 0, y = 0) => {
        const out = {
            lat: iLoc.lat,
            lng: iLoc.lng
        }
        // console.log("out1", out)
        let latsign = 0
        if (locDiff.lat < 0) {
            latsign = -1;
            if (iLoc.lat + x <= jLoc.lat) {
                out.lat = jLoc.lat
            }
            else {
                out.lat += x + locAdder.lat
            }
        }
        else {
            latsign = 1;
            if (iLoc.lat + x >= jLoc.lat) {
                out.lat = jLoc.lat
            }
            else {
                out.lat += x + locAdder.lat
            }

        }
        let lngsign = 0
        if (locDiff.lng < 0) {
            lngsign = -1;
            if (iLoc.lng + y <= jLoc.lng) {
                out.lng = jLoc.lng

            }
            else {
                out.lng += + y + locAdder.lng
            }
        }
        else {
            lngsign = 1;
            if (iLoc.lng + y >= jLoc.lng) {
                out.lng = jLoc.lng

            }
            else {
                out.lng += y + locAdder.lng
            }
        }
        // console.log("out", out)
        if (latsign > 0 && out.lat >= jLoc.lat) {
            out.lat = jLoc.lat
            out.lng = jLoc.lng
            tempArr.push(out)
            // console.log(out.lat >= jLoc.lat, out.lat, jLoc.lat)
            // console.log("locAdder", locAdder)
            // console.log(tempArr[count - 1], tempArr[tempArr.length - 1], count, tempArr.length)
            // relatedAltEle.push({index:tempArr.length,loc:{lat: jLoc.lat,lng: jLoc.lng}})
            relatedAltEle.push(tempArr.length)
            // setTempArr([...tempArr])
            return tempArr.length
        }
        else if (latsign < 0 && out.lat <= jLoc.lat) {
            out.lat = jLoc.lat
            out.lng = jLoc.lng
            tempArr.push(out)
            // console.log(out.lat >= jLoc.lat, out.lat, jLoc.lat)
            // console.log("locAdder", locAdder)
            // console.log(tempArr[count - 1], tempArr[tempArr.length - 1], count, tempArr.length)
            // relatedAltEle.push({index:tempArr.length,loc:{lat: jLoc.lat,lng: jLoc.lng}})
            relatedAltEle.push(tempArr.length)
            // setTempArr([...tempArr])
            return tempArr.length
        }
        tempArr.push(out)
        return latLngAddToGetElevationArr(iLoc, jLoc, locDiff, locAdder, count, x + locAdder.lat, y + locAdder.lng)


    }
    const evaluateAllCoOrdinates = (i, j) => {
        let lat = (j.lat - i.lat);
        let lng = (j.lng - i.lng);
        let y = getDistanceBetweenPoints({ lat: () => i.lat, lng: () => 0 }, { lat: () => j.lat, lng: () => 0 })
        let x = getDistanceBetweenPoints({ lat: () => 0, lng: () => i.lng }, { lat: () => 0, lng: () => j.lng })
        // console.log('i,j', x, y);
        // console.log('angle =', Math.asin(y / j.distance) * (180 / Math.PI))
        // console.log('angle2 =', Math.atan2(lng ,lat))
        const locDiff = {
            lat: lat,
            lng: lng
        }
        // console.log("locDiff", locDiff)

        const theta = Math.atan(locDiff.lng / locDiff.lat)
        const dx = Math.cos(theta) * j.distance;
        const dy = Math.sin(theta) * j.distance;
        const dxx = Math.cos(theta) * 100;
        const dyy = Math.sin(theta) * 100;
        const xDivider = dx / dxx;
        const yDivider = dy / dyy;
        const xLatAdder = locDiff.lat / xDivider;
        const yLngAdder = locDiff.lng / yDivider;
        // console.log("dynamic distance", theta * (180 / Math.PI), dx, dy, dxx, dyy);
        // console.log("dynamic lat lng", xLatAdder, yLngAdder);
        // console.log("dynamic lat", j.lat, i.lat + xLatAdder * xDivider, xDivider);
        // console.log("dynamic lng", j.lng, i.lng + yLngAdder * yDivider, yDivider);

        return latLngAddToGetElevationArr(i, j, locDiff, { lat: xLatAdder, lng: yLngAdder })
    }

    const [elevationRange, setElevationRange] = useState({ x: [0, 600], y: [0, 600] })
    const getTerrainElevation = async () => {
        // await setTempArr([])
        // await setRelatedAltEle([])
        tempArr = [];
        const altIndexes = [0]
        const locationsForElevation = await missionRoutePoints.map((point, i) => {
            if (i > 0) {
                missionRoutePoints[i].distance = getDistanceBetweenPoints(
                    { lat: () => missionRoutePoints[i - 1].lat, lng: () => missionRoutePoints[i - 1].lng },
                    { lat: () => missionRoutePoints[i].lat, lng: () => missionRoutePoints[i].lng })

                altIndexes.push(evaluateAllCoOrdinates(missionRoutePoints[i - 1], missionRoutePoints[i]));
                if (i === missionRoutePoints.length - 1) {

                    // tempArr = [...elevationArr]
                    // console.log("tempArr-before ", tempArr)
                    // setTempArr([...tempArr])
                    // console.log("tempArr-after ", tempArr)

                    console.log(totalDistance)
                    // setElevationArr([{ lat: missionRoutePoints[0].lat, lng: missionRoutePoints[0].lng }, ...tempArr])
                    tempArr = [{ lat: missionRoutePoints[0].lat, lng: missionRoutePoints[0].lng }, ...tempArr]
                    // console.log("missionRoutePoints ", missionRoutePoints)
                    // console.log("altIndexes =", altIndexes)
                    setRelatedAltEle(altIndexes)

                }
            }
            if (i === 0) {
                // setTempArr([]); 
                tempArr = []
            }
        })
        // console.log("relatedAltEle =", relatedAltEle)
        const elevator = new window.google.maps.ElevationService();
        let elevationRequestsNeeded = Math.ceil(tempArr.length / 500)
        let locIndexRange = 0;
        const pathElevationMap = [];
        let pathElevationMapCounter = 0;
        let distance = 0;
        let elevationResult = []
        let maxElevation = -Infinity;
        let minElevation = Infinity;
        const elevationAltitudeMap = []
        const elevationTerrainMap = [];
        const gmapElevationResults = [];
        for (let i = 0; i < elevationRequestsNeeded; i++) {
            let eleArr = await tempArr.slice(locIndexRange, locIndexRange + 500);
            // console.log("eleArr", eleArr)
            await elevator.getElevationForLocations({
                locations: eleArr
                // path: missionRoutePoints,
                // samples: Math.ceil(totalDistance / 100),
            }).then(({ results }) => {

                // console.log("results", results);

                results.forEach((point, i) => {
                    gmapElevationResults.push(point)
                    const lat = point.location.lat();
                    const lng = point.location.lng();

                    if (i > 0 || locIndexRange !== 0) {
                        // console.log(i, locIndexRange)
                        distance += getDistanceBetweenPoints(gmapElevationResults[locIndexRange + i - 1].location, gmapElevationResults[locIndexRange + i].location)
                    }

                    const elevation = point.elevation;

                    if (maxElevation < elevation) {
                        maxElevation = elevation
                    }
                    if (minElevation > elevation) {
                        minElevation = elevation
                    }
                    elevationAltitudeMap.push({ lat: lat, lng: lng, x: distance, y: elevation + 100 })
                    elevationTerrainMap.push({ lat: lat, lng: lng, x: distance, y: elevation })
                    if (locIndexRange + 500 > tempArr.length && results.length - 1 === i) {
                        // setElevationRange({ x: [-1000, distance + 1000], y: [minElevation - 100, maxElevation + 200] })
                        // setRelatedAltEle([...relatedAltEle])
                    }
                })
            }).catch((err) => {
                console.log(err)
            })
            console.log(locIndexRange, locIndexRange + 500)
            // elevationResult = [...elevationResult, ...res.results]
            locIndexRange += 500;
        }


        // console.log(elevationTerrainMap, minElevation, maxElevation)
        // console.log("pathElevationMap =", pathElevationMap)
        // setElevationRange({ x: [-1000, distance + 1000], y: [minElevation - 100, maxElevation + 200] })

        setElevationData({
            ...elevationData,
            terrain: elevationTerrainMap,
        })
    }



    useEffect(() => {
        if (elevationGraphVisibility) {
            let distance = 0
            let minElevation = Infinity;
            let maxElevation = -Infinity;
            const elevation = missionRoutePoints.map((point, i) => {
                if (i === 0) {
                    return { lat: point.lat, lng: point.lng, x: 0, y: (elevationData.terrain[0]?.y !== undefined ? elevationData.terrain[0]?.y : 591) + missionPlanner.mission[i].alt }
                }
                else {
                    distance += getDistanceBetweenPoints({
                        lat: () => missionRoutePoints[i - 1].lat,
                        lng: () => missionRoutePoints[i - 1].lng,
                    }, {
                        lat: () => missionRoutePoints[i].lat,
                        lng: () => missionRoutePoints[i].lng,
                    })
                    if (maxElevation < (elevationData.terrain[0]?.y !== undefined ? elevationData.terrain[0]?.y : 591) + missionPlanner.mission[i].alt) {
                        maxElevation = (elevationData.terrain[0]?.y !== undefined ? elevationData.terrain[0]?.y : 591) + missionPlanner.mission[i].alt
                    }
                    if (minElevation > (elevationData.terrain[0]?.y !== undefined ? elevationData.terrain[0]?.y : 591) + missionPlanner.mission[i].alt) {
                        minElevation = (elevationData.terrain[0]?.y !== undefined ? elevationData.terrain[0]?.y : 591) + missionPlanner.mission[i].alt
                    }

                    return {
                        lat: point.lat,
                        lng: point.lng,
                        x: distance,
                        y: (elevationData.terrain[0]?.y !== undefined ? elevationData.terrain[0]?.y : 591) + missionPlanner.mission[i].alt
                    }

                }
            })
            setElevationData({ ...elevationData, altitude: elevation })
            elevationRange.x[0] = -1000;
            elevationRange.x[1] = distance + 1000;
            elevationRange.y[0] = minElevation >= 0 ? (-100) : (minElevation - 100);
            elevationRange.y[1] = maxElevation + 200;
            // setElevationRange({ x: [-1000, distance + 1000], y: [minElevation >= 0 ? (-100) : (minElevation - 100), maxElevation + 200] })

        }
    }, [elevationGraphVisibility, missionRoutePoints, elevationData.terrain])


    const updateRoutePointElevation = (index, alt) => {
        const point = missionPlanner.mission[index];
        point.alt = Math.ceil(alt - elevationData.terrain[0].y)
        missionPlanner.updatePoint(index, point)
    }


    const rightClickContent = {
        monitor: <InfoBox

            position={clickLocation}
            options={{
                // pixelOffset: new window.google.maps.Size(-50, -75, "%", "px"),
                closeBoxURL: ''
                // boxStyle:{}
            }}
            zIndex={100}
        >
            <div className='mapRightClickPopUp-Container'>
                {/* {clickLocation.lat} {clickLocation.lng} */}
                <button className='mapRightClickPopUp-Container__ClickOption'
                    onClick={() => { setGoToLocationPopUpVisibility(true) }}
                >Go to Location
                </button>
                <button className='mapRightClickPopUp-Container__ClickOption'
                    // onClick={() => { PageOpen.cmdFunc("takeoff",{})  }}
                    onClick={() => {
                        setDroneTakeoffPopUpVisibility(true)
                    }}
                >TakeOff
                </button>
                <button className='mapRightClickPopUp-Container__ClickOption'
                    // onClick={() => { PageOpen.cmdFunc("takeoff",{})  }}
                    onClick={() => {
                        PageOpen.cmdFunc("land", {})
                    }}
                >Land
                </button>

            </div>
        </InfoBox>,
        planMission: <InfoBox

            position={clickLocation}
            options={{
                // pixelOffset: new window.google.maps.Size(-50, -75, "%", "px"),
                closeBoxURL: ''
                // boxStyle:{}
            }}
            zIndex={100}
        >
            <div className='mapRightClickPopUp-Container'>
                {/* {clickLocation.lat} {clickLocation.lng} */}
                <button className='mapRightClickPopUp-Container__ClickOption'
                    onClick={() => { setelevationGraphVisibility(true) }}
                >Show Elevation graph
                </button>
            </div>
        </InfoBox>
    }

    useEffect(() => {
        const keys = Object.keys(hubNodeLocations);
        const formatedLocations = []
        const locationsTree = {}
        const locationsData = {}
        // console.log("keys =", keys)
        keys.forEach((key, i) => {
            // console.log("key =",i,key)
            const loc = hubNodeLocations[key];
            formatedLocations.push({
                tag: loc.tag,
                name: loc.name,
                lat: parseFloat(loc.lat),
                lng: parseFloat(loc.lng),
                hoverFunc: () => { },
                MouseOutFunc: () => { }
            })
            locationsData[key] = {
                tag: loc.tag,
                name: loc.name,
                lat: parseFloat(loc.lat),
                lng: parseFloat(loc.lng),
                hoverFunc: () => { },
                MouseOutFunc: () => { }
            }
            if (loc.tag === "HUB") {
                setMyHub({
                    tag: loc.tag,
                    name: loc.name,
                    lat: parseFloat(loc.lat),
                    lng: parseFloat(loc.lng),
                    hoverFunc: () => { },
                    MouseOutFunc: () => { }
                })
                locationsTree[key] = [key]
                // locationsTree[key].push[key]
            }
            else {
                locationsTree[loc.hub_id]?.push(key)
            }
            // console.log("locationsTree", locationsTree)
        });
        // console.log("formatedLocations =",formatedLocations)
        setAllHubAndNodes(formatedLocations)
        // console.log("locationsTree", locationsTree)
        setAllLocationsTree(locationsTree)
        setAllLocationsData(locationsData)
    }, [hubNodeLocations])



    const MarkerLocationCardStyle = {
        opacity: 1, backgroundColor: "#FD6125", padding: "5px", borderRadius: "5px",
        display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"
    }






    const options = {
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 5,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        // clickable: false,
        // draggable: false,
        // editable: false,
        visible: true,
        radius: 30,
        paths: missionRoutePoints,
        zIndex: 10
    };
    const handleMarkerClick = (e) => {
        console.log(e)
        setCardShow(true)
    }
    const handleMouseOut = (e) => {
        console.log(e)
        setCardShow(false)
    }
    useEffect(() => {
        if (routePoints?.length && isLoaded) {
            const bounds = new window.google.maps.LatLngBounds();
            // HUBandDeliveryLandingLocations.slice(0, 3).forEach((marker) => {
            //     bounds.extend({ lat: marker.lat, lng: marker.lng })
            // })
            routePoints.forEach((marker) => {
                bounds.extend({ lat: marker.lat, lng: marker.lng })
            })
            // if(droneLat){
            //     bounds.extend({ lat: droneLat, lng: droneLong })
            // }
            GmapRef.current.fitBounds(bounds);
        }
    }, [routePoints?.length])

    useEffect(() => {
        if (centerToDrone && GmapRef.current) {
            // GmapRef.current.setCenter({ lat: droneLat, lng: droneLong });
            if (prevDroneLocation?.lat) {
                GmapRef.current?.setCenter({ lat: prevDroneLocation?.lat, lng: prevDroneLocation?.lng });
            }
        }
        if (!isDroneLatSet) {
            SetPrevDroneLocation((prev) => {
                console.log("inside")
                return { lat: droneLat, lng: droneLong }
            })
        }
        // if (!isDroneAngleSet) {
        //     SetPrevDroneAngle((prev) => {
        //         console.log("inside")
        //         return heading
        //     })
        // }
        if (droneLat) {
            SetisDroneLatSet(true)
            // isDroneLatSet = true;
        }
        // if (heading) {
        //     SetisDroneAngleSet(true)
        // }


    }, [droneLat, droneLong, centerToDrone, prevDroneLocation.lat, heading]);


    const [isControlKeyPressed, SetIsControlKeyPressed] = useState(false)
    const [keyPressed, setKeyPressed] = useState(null)
    const [isShiftKeyPressed, SetIsShiftKeyPressed] = useState(false)
    const [isMapDraggle, setIsMapDraggle] = useState(true)
    useEffect(() => {
        const OnKeyPressedDown = (event) => {
            var name = event.key;
            var code = event.code;
            // Alert the key name and key code on keydown
            // alert(`Key pressed ${name} \r\n Key code value: ${code}`);
            if (name == "Control" || name == "Command") {
                SetIsControlKeyPressed(true);
                setIsMapDraggle(false)
            }
            else {
                SetIsControlKeyPressed(false);
                setIsMapDraggle(true)
            }
            if (name == "Shift") {
                SetIsShiftKeyPressed(true);
                setIsMapDraggle(false)
            }
            else {
                SetIsShiftKeyPressed(false);
                setIsMapDraggle(true)
            }
            setKeyPressed(event.key)
        }
        const OnKeyPressedRelease = (event) => {
            var name = event.key;
            var code = event.code;
            // Alert the key name and key code on keydown
            // alert(`Key pressed ${name} \r\n Key code value: ${code}`);
            if (name === "Control" || name == "Command") {
                SetIsControlKeyPressed(false);
                setIsMapDraggle(true)
            }
            if (name === "Shift") {
                SetIsShiftKeyPressed(false);
                setIsMapDraggle(true)
            }
            setKeyPressed(null)

        }
        window.addEventListener('keydown', OnKeyPressedDown);
        window.addEventListener('keyup', OnKeyPressedRelease);
        return () => {
            window.removeEventListener('keydown', OnKeyPressedDown);
            window.removeEventListener('keyup', OnKeyPressedRelease);
        }
    }, [missionPlanner.mission, elevationGraphVisibility, elevationData])
    const polylineInsert = async (e) => {
        const input = await polyLineRef.current.getPath()
        const getPathKeys = Object.keys(input);
        const currentPoints = input[getPathKeys.filter(key => { return Array.isArray(input[key]) })[0]]
        console.log("input polylineInsert", input, currentPoints)
        // input.Hg.forEach((point, i) => {
        //     console.log(`{index:${i},lat:${point.lat()},lng:${point.lng()}}`)
        // })
        missionPlanner.addPointAt({
            cmd: "WAYPOINT",
            p1: 0,
            p2: 0,
            p3: 0,
            yaw: 0,
            lat: currentPoints[e].lat(),
            long: currentPoints[e].lng(),
            alt: missionPlanner.defaultAlt,
            frame: missionPlanner.defaultFrame,
        }, e);
    }
    const polylineRemoveAt = (e) => {
        console.log("polylineRemoveAt", e)
        missionPlanner.deletePoint(e);
    }
    const polylineSetAt = async (e) => {
        console.log("polylineSetAt", e)
        const input = await polyLineRef.current.getPath()
        const getPathKeys = Object.keys(input);
        const currentPoints = input[getPathKeys.filter(key => { return Array.isArray(input[key]) })[0]]
        console.log("input polylineSetAt", input, currentPoints)
        missionPlanner.updatePoint({
            ...missionPlanner.mission[parseInt(e)],
            lat: currentPoints[e].lat(),
            long: currentPoints[e].lng(),
        }, e);
    }

    const onPolylineLoad = (polyInstance) => {
        // polyInstance.setEditable(true);

        polyLineRef.current = polyInstance;

        console.log("inside polyline load ")

    }


    useEffect(() => {
        if (polyLineRef.current) {
            window.google.maps.event.addListener(polyLineRef.current?.getPath(), 'insert_at', polylineInsert);
            window.google.maps.event.addListener(polyLineRef.current?.getPath(), 'remove_at', polylineRemoveAt)
            window.google.maps.event.addListener(polyLineRef.current?.getPath(), 'set_at', polylineSetAt)

        }
    }, [polyLineRef.current, missionRoutePoints])


    const circleOptions = {
        strokeColor: 'white',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: 'none',
        fillOpacity: 0,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: missionPlanner.waypointRadius,
        zIndex: 1
    }

    // const onLoadCircle = circle => {
    //     console.log('Circle onLoad circle: ', circle)
    // }

    // const onUnmountCircle = circle => {
    //     console.log('Circle onUnmount circle: ', circle)
    // }
    const [routeTrailPoints, setRouteTrailPoints] = useState([]);
    const [isTrailTimerOn, setIsTrailTimerOn] = useState(false);
    const routeTrailOptions = {
        strokeColor: '#FFFF00',
        strokeOpacity: 0.6,
        strokeWeight: 4,
        fillColor: '#FFFF00',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 300,
        paths: routeTrailPoints,
        zIndex: 100
    };
    useEffect(() => {
        let trailIntervalTimerId;
        if (routeTrailPoints.length > 5) {
            trailIntervalTimerId = setInterval(() => {
                if (routeTrailPoints.length > 0) {
                    routeTrailPoints.pop();
                }
            }, 400);
            setIsTrailTimerOn(true);
            if (routeTrailPoints.length <= 1) {
                clearInterval(trailIntervalTimerId);
                setRouteTrailPoints([])
                setIsTrailTimerOn(false);
            }
            return () => {
                clearInterval(trailIntervalTimerId)
                setIsTrailTimerOn(false);
            }
        }
        if (routeTrailPoints.length <= 1) {
            clearInterval(trailIntervalTimerId);
            setIsTrailTimerOn(false);
        }
    }, [routeTrailPoints])

    useEffect(() => {
        routeTrailPoints.unshift({ lat: droneLat, lng: droneLong });
        if (routeTrailPoints.length >= 50) {
            routeTrailPoints.pop();
        }
        setRouteTrailPoints([...routeTrailPoints])

    }, [droneLat])

    useEffect(() => {
        if (isConnected) {
            setRouteTrailPoints([])
        }
    }, [isConnected])

    const mouseOverMarker = () => { console.log("mouseover origin") }

    return (isLoaded && (
        <>
            <GoogleMap
                mapContainerStyle={containerStyle}

                center={ownMapCenter}
                zoom={(zoomLevel / 100) * 17 + 2}
                options={{
                    styles: mapOptions.mapTheme,
                    disableDefaultUI: false, draggable: { isMapDraggle },
                    mapTypeControl: false,
                    draggableCursor: isControlKeyPressed && isMissionPlaning ? 'default' : ''
                }}

                onLoad={onMapLoad}
                onZoomChanged={() => {
                    SetCenterToDrone(false);
                    // if (GmapRef.current.zoom) {
                        // setZoomLevel(((GmapRef.current.zoom - 2) / 17) * 100)
                    // }
                }}
                onDragStart={() => { SetCenterToDrone(false) }}
                onClick={(event) => {
                    setRightCLickMenuVisibility(false);
                    if (isControlKeyPressed && isMissionPlaning) {
                        const p = { lat: event.latLng.lat(), lng: event.latLng.lng() }
                        missionPlanner.addPoint({
                            cmd: "WAYPOINT",
                            p1: 0,
                            p2: 0,
                            p3: 0,
                            yaw: 0,
                            lat: p.lat,
                            long: p.lng,
                            alt: missionPlanner.defaultAlt,
                            frame: missionPlanner.defaultFrame,
                        });

                    }
                }}
                onRightClick={(event) => {
                    if (PageOpen.name === "misson-plan") {// var lat = event.latLng.lat();
                        // var lng = event.latLng.lng();
                        setOwnMapCenter({ lat: GmapRef.current.getCenter().lat(), lng: GmapRef.current.getCenter().lng() })
                        // console.log({lat:GmapRef.current.getCenter().lat(),lng:GmapRef.current.getCenter().lng()})
                        SetClickLocation({ lat: event.latLng.lat(), lng: event.latLng.lng() });
                        setRightCLickMenuVisibility(true);
                        var point = GmapRef.current.getProjection().fromLatLngToPoint(clickLocation);
                        // console.log(point.x, GmapRef.current.getBounds())
                        // console.log(lat, lng)}
                    }
                }}
            // onCenterChanged={() => { GmapRef.current && console.log(GmapRef.current.fitBounds(routes)) }}
            //  onCenterChanged={()=>{window.google.maps.LatLngBounds(GMap).get}}
            >
                {routeTrailPoints.length > 1 && routeTrailPoints.map((point, index) => {
                    if (index < routeTrailPoints.length - 1 && index > 2) {
                        const tempOptions = { ...routeTrailOptions };
                        const tempPaths = [routeTrailPoints[index], routeTrailPoints[index + 1]]
                        tempOptions.paths = tempPaths
                        return <Polyline key={`${"trailpoints"} index ${point.lat} ${point.lng}`}
                            // onLoad={onLoad}
                            path={tempPaths}
                            options={tempOptions}
                        />
                    }
                })}
                {/* {routeTrailPoints.length > 1 && ((point, index) => {
                    const tempOptions = { ...routeTrailOptions };
                    const tempPaths = routeTrailPoints.slice(3)
                    tempOptions.paths = tempPaths
                    return <Polyline 
                        path={tempPaths}
                        options={tempOptions}
                    />
                })()} */}
                {<HubAndLocMarker allHubAndNodes={allHubAndNodes} locationsTree={allLocationsTree} allLocationsData={allLocationsData} />}
                {!isMissionPlaning && <>
                    <MarkerClusterer gridSize={40}
                        minimumClusterSize={routePoints?.length <= 1 ? 5 : routePoints?.length}>
                        {(clusterer) => routePoints?.map((loc, index) => {
                            if (index === 0) {
                                return < Marker
                                    key={`routePointswaypoint${loc.tag}${index}`}
                                    // label={{ text: (index + 1).toString(), color: 'white', fontSize: "20px", className: "GmapCustomMarkerLabels" }}
                                    options={{
                                        zIndex: 5,
                                        clickable: false,
                                        draggable: false,
                                        cursor: "default"
                                    }} animation={window.google.maps.Animation.DROP}
                                    clusterer={clusterer} position={loc} icon={{ url: "./Origin.svg", anchor: markerAnchors.wp }} />
                            }
                            else if (index === routePoints.length - 1) {
                                return < Marker key={`routePointswaypoint${loc.tag}${index}`}
                                    // label={{ text: (index + 1).toString(), color: 'white', fontSize: "20px", className: "GmapCustomMarkerLabels" }}
                                    options={{
                                        zIndex: 5,
                                        clickable: false,
                                        draggable: false,
                                        cursor: "default"
                                    }} animation={window.google.maps.Animation.DROP}
                                    clusterer={clusterer} position={loc} icon={{ url: "./Destination.svg", anchor: markerAnchors.wp }} />
                            }
                            else {
                                return < Marker key={`routePointswaypoint${loc.tag}${index}`}
                                    // label={{ text: (index + 1).toString(), color: 'white', fontSize: "20px", className: "GmapCustomMarkerLabels" }}
                                    options={{
                                        zIndex: 5,
                                        clickable: false,
                                        draggable: false,
                                        cursor: "default"
                                    }} animation={window.google.maps.Animation.DROP}
                                    clusterer={clusterer} position={loc} icon={{ url: "./Waypoint.svg", anchor: markerAnchors.wp }} />
                            }
                        })}
                    </MarkerClusterer>
                    {routePoints?.length ? <Polyline
                        // onLoad={onLoad}
                        path={routePoints}
                        options={{
                            ...routeOptions,
                            clickable: false,
                            draggable: false,
                            editable: false,
                        }}
                    /> : null}
                    {droneLat && <InfoBox
                        //   onLoad={onLoad}
                        //   options={options}
                        // position={positionM1}

                        position={{
                            // lat: prevDroneLocation.lat ? prevDroneLocation.lat : positionM1.lat,
                            // lng: prevDroneLocation.lng ? prevDroneLocation.lng : positionM1.lng
                            lat: droneLat ? prevDroneLocation.lat : positionM1.lat,
                            lng: droneLong ? prevDroneLocation.lng : positionM1.lng
                        }}
                        options={{
                            pixelOffset: new window.google.maps.Size(-100, -80, "px", "px"),
                            closeBoxURL: '',
                            zIndex: 0
                        }}
                    // zIndex={5}
                    >
                        <div style={{ opacity: 1, padding: "50px", zIndex: 0 }}>
                            <img //className='trapezoid-container__img-container__img' 
                                src='./drone_img.png'
                                style={{
                                    scale: 0.5,
                                    transform: `rotate(${heading}deg) translateY(26px)`, zIndex: 0, width: "100px",
                                    // transition: `all 0.5s linear `
                                }}
                            />
                        </div>
                    </InfoBox>}
                </>}
                {/* {!isMissionPlaning && }
                {isMissionPlaning ? null : }

                {!isMissionPlaning && } */}
                {isMissionPlaning && <>
                    <Circle
                        center={{
                            lat: 25.64760280,
                            lng: 90.32645700,
                        }}
                        // required
                        options={{ ...circleOptions, radius: 60000, strokeColor: "yellow" }}
                        radius={60000}
                    />
                    {missionRoutePoints?.length ? <Polyline
                        onLoad={onPolylineLoad}
                        onDrag={(e) => { console.log("yes") }}
                        path={missionRoutePoints}
                        draggable={true}
                        editable={true}
                        options={{ ...routeOptions, strokeColor: "blue" }}

                    // onClick={()=>{console.log(polyLineRef.current.getPath())}}
                    /> : null}
                    {droneLat ?
                        <DroneMarker posi={prevDroneLocation} isMultipleDrone={false}
                            anchor={new window.google.maps.Point(170, 0)} zIndex={6} scale={0.25} rotation={heading} /> : null}
                    <MarkerClusterer gridSize={40}
                        minimumClusterSize={missionRoutePoints.length <= 1 ? 5 : (missionRoutePoints.length)}>
                        {(clusterer) =>
                        (missionRoutePoints.map((loc, index) => {
                            if (index === 0) {
                                return < Marker key={`waypoint${loc.tag}${index}`}
                                    onMouseOver={isControlKeyPressed ? null : () => { missionPlanner.setSelectedWayPoint(index) }}
                                    onMouseOut={isControlKeyPressed ? null : () => { missionPlanner.setSelectedWayPoint(null) }}
                                    label={{
                                        text: (index + 1).toString(), color: 'white', fontSize: "20px",
                                        className: missionPlanner.selectedWayPoint === index ? "GmapCustomMarkerLabelsSelected" : "GmapCustomMarkerLabels"
                                    }} options={{
                                        zIndex: 5,

                                        clickable: isControlKeyPressed ? false : true,
                                        draggable: false,
                                        cursor: isControlKeyPressed ? "default" : "pointer"
                                    }} animation={window.google.maps.Animation.DROP}
                                    // onMouseOver={handleMarkerClick}
                                    // onMouseOut={handleMouseOut}
                                    clusterer={clusterer} position={loc} icon={{ url: "./Origin.svg", anchor: markerAnchors.wp }} />
                            }
                            else if (index === missionRoutePoints.length - 1) {
                                return < Marker key={`waypoint${loc.tag}${index}`}
                                    onMouseOver={isControlKeyPressed ? null : () => { missionPlanner.setSelectedWayPoint(index) }}
                                    onMouseOut={isControlKeyPressed ? null : () => { missionPlanner.setSelectedWayPoint(null) }}
                                    label={{ text: (index + 1).toString(), color: 'white', fontSize: "20px", className: missionPlanner.selectedWayPoint === index ? "GmapCustomMarkerLabelsSelected" : "GmapCustomMarkerLabels" }}
                                    options={{
                                        zIndex: 5,
                                        clickable: isControlKeyPressed ? false : true,
                                        draggable: false,
                                        cursor: isControlKeyPressed ? "default" : "pointer"
                                    }} animation={window.google.maps.Animation.DROP}
                                    clusterer={clusterer} position={loc} icon={{ url: "./Destination.svg", anchor: markerAnchors.wp }} />
                            }
                            else {
                                return < Marker key={`waypoint${loc.tag}${index}`}
                                    onMouseOver={isControlKeyPressed ? null : () => { missionPlanner.setSelectedWayPoint(index) }}
                                    onMouseOut={isControlKeyPressed ? null : () => { missionPlanner.setSelectedWayPoint(null) }}
                                    label={{ text: (index + 1).toString(), color: 'white', fontSize: "20px", className: missionPlanner.selectedWayPoint === index ? "GmapCustomMarkerLabelsSelected" : "GmapCustomMarkerLabels" }}
                                    options={{
                                        zIndex: 5,
                                        clickable: isControlKeyPressed ? false : true,
                                        draggable: false,
                                        cursor: isControlKeyPressed ? "default" : "pointer"
                                    }} animation={window.google.maps.Animation.DROP}
                                    clusterer={clusterer} position={loc} icon={{ url: "./Waypoint.svg", anchor: markerAnchors.wp }} />
                            }
                        }))
                        }
                    </MarkerClusterer>
                    {missionRoutePoints.map((loc, index) => {
                        return <Circle
                            // optional
                            // onLoad={onLoadCircle}
                            // // optional
                            // onUnmount={onUnmountCircle}
                            // required
                            center={{
                                lat: loc.lat,
                                lng: loc.lng
                            }}
                            key={`${loc.lat}${missionPlanner.waypointRadius}${index}`}
                            // required
                            options={circleOptions}
                            radius={missionPlanner.waypointRadius}
                        />
                    })}
                </>}
                {rightCLickMenuVisibility ? isMissionPlaning ? rightClickContent.planMission : rightClickContent.monitor : null}

            </GoogleMap >
            {isMissionPlaning && elevationGraphVisibility && <ElevationGraph
                keyPressed={keyPressed}
                setKeyPressed={setKeyPressed}
                elevationData={elevationData}
                range={elevationRange}
                setElevationGraphVisibility={setelevationGraphVisibility}
                getTerrainElevation={getTerrainElevation}
                updatePointElevation={updateRoutePointElevation}
                addPointAt={missionPlanner.addPointAt}
                defaultFrame={missionPlanner.defaultFrame}
                isControlKeyPressed={isControlKeyPressed}
                relatedAltEle={relatedAltEle}
                setRelatedAltEle={setRelatedAltEle} />
            }

            {
                goToLocationPopUpVisibility &&
                <div className='goToLocationPopUp-Container'>
                    <div className='goToLocationPopUp-Container__card'>

                        <div className='goToLocationPopUp-Container__card__taskLabel'>
                            Send Drone to Location
                        </div>
                        <div className='goToLocationPopUp-Container__card__para'>
                            <div className='goToLocationPopUp-Container__card__para__title'>Latitude</div>
                            <div className='goToLocationPopUp-Container__card__para__value'>{clickLocation.lat}</div>
                        </div>
                        <div className='goToLocationPopUp-Container__card__para'>
                            <div className='goToLocationPopUp-Container__card__para__title'>Longitude</div>
                            <div className='goToLocationPopUp-Container__card__para__value'>{clickLocation.lng}</div>
                        </div>
                        <div className='goToLocationPopUp-Container__card__para'>
                            <div className='goToLocationPopUp-Container__card__para__title'>{"Altitude(m)"}</div>
                            <input className='goToLocationPopUp-Container__card__para__input'
                                ref={altitudeInputRef}
                                min="10" max="100" defaultValue={15}
                                type={"number"}></input>
                            {/* <div className='goToLocationPopUp-Container__card__para__value'></div> */}
                        </div>
                        <div className='goToLocationPopUp-Container__card__buttons'>
                            <button className='goToLocationPopUp-Container__card__buttons__OkayBtn'
                                onClick={() => {
                                    setGoToLocationPopUpVisibility(false);
                                    PageOpen.cmdFunc("go_to_location", { ...clickLocation, altitude: altitudeInputRef.current.value })
                                }}
                            >Okay
                            </button>
                            <button className='goToLocationPopUp-Container__card__buttons__CloseBtn'
                                onClick={() => { setGoToLocationPopUpVisibility(false) }}
                            >Close
                            </button>
                        </div>
                    </div>
                </div>
            }

            {
                droneTakeoffPopUpVisibility &&
                <div className='goToLocationPopUp-Container'>
                    <div className='goToLocationPopUp-Container__card'>

                        <div className='goToLocationPopUp-Container__card__taskLabel'>
                            Drone TakeOff
                        </div>
                        <div className='goToLocationPopUp-Container__card__para'>
                            <div className='goToLocationPopUp-Container__card__para__title'>{"Altitude(m)"}</div>
                            <input className='goToLocationPopUp-Container__card__para__input'
                                ref={altitudeInputRef}
                                min="10" max="100" defaultValue={15}
                                type={"number"}></input>
                            {/* <div className='goToLocationPopUp-Container__card__para__value'></div> */}
                        </div>
                        <div className='goToLocationPopUp-Container__card__buttons'>
                            <button className='goToLocationPopUp-Container__card__buttons__OkayBtn'
                                onClick={() => {
                                    setDroneTakeoffPopUpVisibility(false);
                                    PageOpen.cmdFunc("takeoff", { altitude: altitudeInputRef.current.value })
                                }}
                            >Okay
                            </button>
                            <button className='goToLocationPopUp-Container__card__buttons__CloseBtn'
                                onClick={() => { setDroneTakeoffPopUpVisibility(false) }}
                            >Close
                            </button>
                        </div>
                    </div>
                </div>
            }
        </>)
    );

}






export { GMapMissionPlanning, Marker }

