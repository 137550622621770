import React, { useEffect, useLayoutEffect, useState } from 'react'
import './FlightDataViewer.scss'
import dayjs from 'dayjs'
import 'rsuite/dist/rsuite.min.css';
import { Dropdown, DatePicker, InputGroup, Table } from 'rsuite';
import axios from 'axios';
import { backEndIP } from '../MyConfiguration';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import { VictoryLabel, VictoryPie, VictoryTheme, VictoryTooltip } from 'victory';
import { Doughnut, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import FlightsPerDayChart from './FlightsPerDayChart';
import AllFlightsPieChart from './AllFlightsPieChart';
import FlightsPerDayHistogramChart from './FlightsPerDayHistogramChart';

// Register required Chart.js components and plugins
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const durationMap = {
  "Month": "MMM",
  "Day": "DD",
  "Year": "YYYY"
}

const datePickerMap = {
  "Month": "MMM-yyyy",
  "Day": "dd-MMM-yyyy",
}

// const datePickerTimeFunctions = {
//   "Month": handleMonthSelect,
//   "Day": handleMonthSelect,
// }
const fieldToDisplay = {
  "Number of trips": 0,
  "Number of forward flights": 1,
  "Number of reverse flights": 2,
  "Number of Test flights": 3,
  "Number of Order flights": 4,
  "Number of empty flights(less than 300g)": 5,
  "Number of flights/Trips per person per day": 6,
  "Number of preflight checklist not filled": 7,
  "Number of postflight checklist not filled": 8,
  "Number of timely log files not uploaded": 9,
  "Total number of unflown Flights": 10,
  "Total cumulative payload": 11,
  "Number of KMs flown": 12,
  "Number of unflown Flights:Because of Pilot": 13,
  "Number of unflown Flights:Because of component damage": 14,
  "Number of unflown Flights:Because of Equipment": 15,
  "Number of unflown Flights:Because of weather": 16,
  "Number of unflown Flights:Because of ATC/MLC. or other Authorities": 17,
  "Number of unflown Flights:Because of lack of demand": 18,
  "Number of unflown Flights:Because of Client": 19,
  "Number of unflown Flights:Because of Techeagle": 20
}

const fieldToDisplayMap = [
  { name: "Number of trips", value: 0 },
  { name: "Number of forward flights", value: 0 },
  { name: "Number of reverse flights", value: 0 },
  { name: "Number of Test flights", value: 0 },
  { name: "Number of Order flights", value: 0 },
  { name: "Number of empty flights(less than 300g)", value: 0 },
  { name: "Number of flights/Trips per person per day", value: 0 },
  { name: "Number of preflight checklist not filled", value: 0 },
  { name: "Number of postflight checklist not filled", value: 0 },
  { name: "Number of timely log files not uploaded", value: 0 },
  { name: "Total number of unflown Flights", value: 0 },
  { name: "Total cumulative payload", value: 0 },
  { name: "Number of KMs flown", value: 0 },
  { name: "Number of unflown Flights:Because of Pilot", value: 0 },
  { name: "Number of unflown Flights:Because of component damage", value: 0 },
  { name: "Number of unflown Flights:Because of Equipment", value: 0 },
  { name: "Number of unflown Flights:Because of weather", value: 0 },
  { name: "Number of unflown Flights:Because of ATC/MLC. or other Authorities", value: 0 },
  { name: "Number of unflown Flights:Because of lack of demand", value: 0 },
  { name: "Number of unflown Flights:Because of Client", value: 0 },
  { name: "Number of unflown Flights:Because of Techeagle", value: 0 },
]

// const functionMap = [
//   { name: "Number of trips", func: 0 },
//   { name: "Number of forward flights", func: 0 },
//   { name: "Number of reverse flights", func: 0 },
//   { name: "Number of empty flights(less than 300g)", func: 0 },
//   { name: "Number of flights/Trips per person per day", func: 0 },
//   { name: "Number of preflight checklist not filled", func: 0 },
//   { name: "Number of postflight checklist not filled", func: 0 },
//   { name: "Number of timely log files not uploaded", func: 0 },
//   { name: "Number of unflown Flights", func: 0 }
// ]

const abortReasonsArray = [{ key: "0", value: "Because of Pilot" },
{ key: "1", value: "Because of component damage" },
{ key: "2", value: "Because of Equipment" },
{ key: "3", value: "Because of weather" },
{ key: "4", value: "Because of ATC/MLC. or other Authorities" },
{ key: "5", value: "Because of lack of demand" },
{ key: "6", value: "Because of Client" },
{ key: "7", value: "Because of Techeagle" }
]
function calculateTotalPayload(flights) {
  let totalPayload = 0;
  flights.forEach(flight => {
    // console.log("totalPayload", totalPayload ,flight?.payload)
    totalPayload += flight?.payload ? flight.payload : 0
  })
  return totalPayload
}

function calculateTotalDistanceFlown(flights) {
  let totalDistance = 0;
  flights.forEach(flight => {
    // console.log("totalPayload", totalPayload ,flight?.payload)
    totalDistance += flight?.distance_coverved ? flight.distance_coverved : 0
  })
  return totalDistance
}
function CalculateFlights(fields, flights) {
  fields[fieldToDisplay["Number of trips"]].value = flights.filter((flight) => { return flight.isCompleted }).length;;
  fields[fieldToDisplay["Number of forward flights"]].value = flights.filter((flight) => { return flight.flight_type === "Forward Flight" }).length;
  fields[fieldToDisplay["Number of reverse flights"]].value = flights.filter((flight) => { return flight.flight_type === "Return Flight" }).length;
  fields[fieldToDisplay["Number of Test flights"]].value = flights.filter((flight) => { return flight.flight_type === "Test Flight" }).length;
  fields[fieldToDisplay["Number of Order flights"]].value = flights.filter((flight) => { return flight.flight_type === "Order Flight" }).length;
  fields[fieldToDisplay["Number of empty flights(less than 300g)"]].value = flights.filter((flight) => { return flight?.payload <= 300 }).length;
  fields[fieldToDisplay["Number of preflight checklist not filled"]].value = flights.filter((flight) => { return !flight.isPreFlightChecklistCompleted }).length;
  fields[fieldToDisplay["Number of postflight checklist not filled"]].value = flights.filter((flight) => { return !flight.isPostFlightChecklistCompleted }).length;
  fields[fieldToDisplay["Number of timely log files not uploaded"]].value = flights.filter((flight) => { return !flight.flightLog.isFlightLogAdded }).length;
  fields[fieldToDisplay["Total number of unflown Flights"]].value = flights.filter((flight) => { return flight.isAborted }).length;

  fields[fieldToDisplay["Number of unflown Flights:Because of Pilot"]].value = flights.filter((flight) => { return flight.isAborted && flight?.finalStatus?.status && flight?.finalStatus?.reason.includes("Because of Pilot") }).length;
  fields[fieldToDisplay["Number of unflown Flights:Because of component damage"]].value = flights.filter((flight) => { return flight.isAborted && flight?.finalStatus?.status && flight?.finalStatus?.reason.includes("Because of component damage") }).length;
  fields[fieldToDisplay["Number of unflown Flights:Because of Equipment"]].value = flights.filter((flight) => { return flight.isAborted && flight?.finalStatus?.status && flight?.finalStatus?.reason.includes("Because of Equipment") }).length;
  fields[fieldToDisplay["Number of unflown Flights:Because of weather"]].value = flights.filter((flight) => { return flight.isAborted && flight?.finalStatus?.status && flight?.finalStatus?.reason.includes("Because of weather") }).length;
  fields[fieldToDisplay["Number of unflown Flights:Because of ATC/MLC. or other Authorities"]].value = flights.filter((flight) => { return flight.isAborted && flight?.finalStatus?.status && flight?.finalStatus?.reason.includes("Because of ATC/MLC. or other Authorities") }).length;
  fields[fieldToDisplay["Number of unflown Flights:Because of lack of demand"]].value = flights.filter((flight) => { return flight.isAborted && flight?.finalStatus?.status && flight?.finalStatus?.reason.includes("Because of lack of demand") }).length;
  fields[fieldToDisplay["Number of unflown Flights:Because of Client"]].value = flights.filter((flight) => { return flight.isAborted && flight?.finalStatus?.status && flight?.finalStatus?.reason.includes("Because of Client") }).length;
  fields[fieldToDisplay["Number of unflown Flights:Because of Techeagle"]].value = flights.filter((flight) => { return flight.isAborted && flight?.finalStatus?.status && flight?.finalStatus?.reason.includes("Because of Techeagle") }).length;


  fields[fieldToDisplay["Total cumulative payload"]].value = (calculateTotalPayload(flights) / 1000).toFixed(2) + "Kg"
  fields[fieldToDisplay["Number of KMs flown"]].value = (calculateTotalDistanceFlown(flights) / 1000).toFixed(2) + "Km"
}
const durationType = ["Month", "Day", "Custom"]
const flightType = ["All", "Forward", "Return", "Empty"]



//################################################################################


const FlightDataViewer = ({ userCookie }) => {
  const [filter, setFilter] = useState({
    flightType: "All",
    durationType: "Month",
    duration: {
      start: null,
      end: null
    },
    pilot: "All Pilots"
  })

  const [tableData, setTableData] = useState(fieldToDisplayMap)
  const [flights, setFlights] = useState([])
  const dimentionMultiplier = 100;
  const [SizeMultiplier, setSizeMultiplier] = useState({
    "--widthmultiplier": (parseInt(window.innerWidth * dimentionMultiplier / 1920) / dimentionMultiplier),
    "--heightmultiplier": (parseInt(window.innerHeight * dimentionMultiplier / 1080) / dimentionMultiplier),
    height: window.innerHeight.toString() + "px",
    width: window.innerWidth.toString() + "px"
  });
  useLayoutEffect(() => {
    function handleResize() {
      let calFontHeight = window.innerHeight.toString() + "px";
      let calFontWidth = window.innerWidth.toString() + "px";
      let calMulHeight = (parseInt(window.innerHeight * dimentionMultiplier / 1080) / dimentionMultiplier);
      let calmulWidth = (parseInt(window.innerWidth * dimentionMultiplier / 1920) / dimentionMultiplier);
      setSizeMultiplier({ "--widthmultiplier": calmulWidth, "--heightmultiplier": calMulHeight, height: calFontHeight, width: calFontWidth });
      console.log(calmulWidth, calMulHeight);
      console.log(calFontHeight, calFontWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleApplyButtonClick = () => {
    axios({
      url: backEndIP + "/flight/get_all_flight_data",
      method: "POST",
      headers: {
        authorization: userCookie.token,
      },
      data: { ...filter }
    }).then((resp) => {
      console.log("resp", resp.data)
      if (Array.isArray(resp.data.data)) {
        setFlights(resp.data.data)
      }

    }).catch((err) => {
      console.log(err)
      // handleNotifyAlert(
      //     "notify",
      //     `${err.response.data.message}`,
      //     "failed",
      //     () => { }
      // );
    })
  }

  function handleChangeStartDate(value, event) {
    console.log('handleChangeStartDate:value,event', value, event)
    const newFilter = { ...filter }
    newFilter.duration.start = dayjs(value).second(1).minute(0).hour(0)
    setFilter(newFilter)
  }
  function handleChangeEndDate(value, event) {
    console.log('handleChangeEndDate:value,event', value, event);
    const newFilter = { ...filter }
    newFilter.duration.end = dayjs(value).second(59).minute(59).hour(23)
    setFilter(newFilter)
  }

  function handleChangeDateSelection(value, event) {
    if (filter.durationType === "Month") {
      console.log(filter.durationType, 'Month:value,event', value, event);
      const newFilter = { ...filter }
      newFilter.duration.start = dayjs(value).set("date", 1).set("second", 0).set("minute", 0).set("hour", 0)
      newFilter.duration.end = dayjs(value).set("date", dayjs(value).daysInMonth()).set("second", 59).set("minute", 59).set("hour", 23)
      setFilter(newFilter)
    }
    else if (filter.durationType === "Day") {
      console.log(filter.durationType, 'Day:value,event', value, event);
      const newFilter = { ...filter }
      newFilter.duration.start = dayjs(value).second(1).minute(0).hour(0)
      newFilter.duration.end = dayjs(value).second(59).minute(59).hour(23)
      setFilter(newFilter)
    }

  }

  // useEffect(() => {
  //   console.log("filter", filter)
  // }, [filter])

  // useEffect(() => {
  //   console.log("tableData", tableData)
  // }, [tableData])

  useEffect(() => {
    const newTableData = [...tableData]
    CalculateFlights(newTableData, flights)
    console.log("newTableData", newTableData)
    setTableData(newTableData)
  }, [flights])

  return (
    <div className='FlightDataViewer-container'>
      <div className='FlightDataViewer-container__filter'>
        {/* <div className='FlightDataViewer-container__filter__duration'>
          <div className='FlightDataViewer-container__filter__duration__label'>Flight Type</div>
          <div className='FlightDataViewer-container__filter__duration__label'>:</div>
          <Dropdown size="sm" title={filter.flightType} onSelect={(eventKey) => { setFilter({ ...filter, flightType: eventKey }) }} activeKey={filter.flightType}>
            {flightType.map((type) => {
              return <Dropdown.Item eventKey={type}>{type}</Dropdown.Item>
            })}
          </Dropdown>
        </div> */}


        <div className='FlightDataViewer-container__filter__duration'>
          <div className='FlightDataViewer-container__filter__duration__label'>Duration</div>
          <div className='FlightDataViewer-container__filter__duration__label'>:</div>
          <Dropdown size="sm" title={filter.durationType} onSelect={(eventKey) => { setFilter({ ...filter, durationType: eventKey }) }} activeKey={filter.durationType}>
            {durationType.map((type) => {
              return <Dropdown.Item eventKey={type}>{type}</Dropdown.Item>
            })}
          </Dropdown>
          {datePickerMap[filter.durationType] ? <DatePicker oneTap size="sm" format={datePickerMap[filter.durationType]} style={{ width: 150 }} limitStartYear={dayjs().year() - 2022} limitEndYear={1} onChange={handleChangeDateSelection} />
            : <InputGroup style={{ width: 300, backgroundColor: "white" }}>
              <DatePicker oneTap size="sm" format="yyyy-MM-dd" block appearance="subtle" style={{ width: 150 }} limitStartYear={dayjs().year() - 2022} limitEndYear={1}  onChange={handleChangeStartDate} />
              <InputGroup.Addon>to</InputGroup.Addon>
              <DatePicker oneTap size="sm" format="yyyy-MM-dd" block appearance="subtle" style={{ width: 150 }} limitStartYear={dayjs().year() - 2022} limitEndYear={1}  onChange={handleChangeEndDate} />
            </InputGroup>}
        </div>
        <button className='FlightDataViewer-container__filter__applyBtn' onClick={handleApplyButtonClick}>apply</button>

      </div>
      <div className='FlightDataViewer-container__viewer-cont'>
        <div className='FlightDataViewer-container__viewer-cont__charts'>
          {/* <Table
          width={400}
          height={430}
            data={fieldToDisplayMap}
            onRowClick={rowData => {
              console.log(rowData);
            }}
          >
            <Column width={300} align="center" fixed>
              <HeaderCell style={{ fontSize: "16px", fontWeight: 600, borderBottom:"2px solid black"}}>Field</HeaderCell>
              <Cell dataKey="name" style={{ fontSize: "12px", color: "black" }} />
            </Column>

            <Column width={100}>
              <HeaderCell style={{ fontSize: "16px", fontWeight: 600, borderBottom:"2px solid black" }}>Value</HeaderCell>
              <Cell dataKey="value" style={{ fontSize: "12px", color: "black" }} />
            </Column>
          </Table> */}

          <table className='FlightDataViewer-container__viewer-cont__charts__table'>
            <thead className='FlightDataViewer-container__viewer-cont__charts__table__thead'>
              <tr className='FlightDataViewer-container__viewer-cont__charts__table__thead__tr'>
                <th className='FlightDataViewer-container__viewer-cont__charts__table__thead__tr__th'>Name</th>
                <th className='FlightDataViewer-container__viewer-cont__charts__table__thead__tr__th'>Value</th>
              </tr>
            </thead>
            <tbody className='FlightDataViewer-container__viewer-cont__charts__table__tbody'>
              {tableData.map((row,i) => {
                return <tr key={`${row.name}${row.value} ${i}`} className='FlightDataViewer-container__viewer-cont__charts__table__tbody__tr'>
                  <td className='FlightDataViewer-container__viewer-cont__charts__table__tbody__tr__td'>{row.name}</td>
                  <td className='FlightDataViewer-container__viewer-cont__charts__table__tbody__tr'>{row.value}</td>
                </tr>
              })}
            </tbody>
          </table>

          {flights.length ? <AllFlightsPieChart tableData={tableData} SizeMultiplier={SizeMultiplier} /> : null}
          {/* <FlightsPerDayHistogramChart flights={flights} duration={filter.duration}/> */}
          {flights.length ? <FlightsPerDayChart flights={flights} duration={filter.duration} SizeMultiplier={SizeMultiplier} /> : null}
        </div>
        {/* <div className='FlightDataViewer-container__viewer-cont__settings'>settings</div> */}
      </div>
    </div>
  )
}

export default FlightDataViewer