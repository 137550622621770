import React, { useEffect, useRef, useState } from 'react'
import "./ImportExportBtn.scss"
const ImportExportBtn = ({getWPFileData,readMissionFile}) => {
    const fileInputElement = useRef();
    const fileExportAnchorRef = useRef()
    // const [exportFileURL, setExportFileURL] = useState("");
    // const [exportFileName,setExportFileName] = useState("");
    const onExportFileClick = () => {
        const str = getWPFileData();
        const blob = new Blob([str], { type: "text/plain" });
        const urlFile = URL.createObjectURL(blob);
        console.log(getWPFileData())
        // setExportFileURL(urlFile);
        fileExportAnchorRef.current.href =urlFile;
        fileExportAnchorRef.current.click();
        // setExportFileName( "testFile.waypoints")

    }
    // useEffect(() => {
    //     if (fileExportAnchorRef.current) {
    //         fileExportAnchorRef.current.click();
    //         console.log("file link", exportFileURL)
    //     }
    //     if(exportFileURL){
    //         return ()=>{
    //             URL.revokeObjectURL(exportFileURL);
    //         }
    //     }

    // }, [exportFileURL])

    const handleFileOnChange = () => {
        const reader = new FileReader();
        // setItem_image(e.target.value);
        // if (fileNameTextBox.current.value === "") {

        // }
        if (fileInputElement.current.files.length) {
            // fileNameTextBox.current.value = fileInputElement.current.files[0].name;
            console.log(fileInputElement.current.value, fileInputElement.current.files[0].name);
            reader.onload = () => {
                console.log(reader.result);
                readMissionFile(reader.result);
                fileInputElement.current.value = "";
            };
            reader.readAsText(fileInputElement.current.files[0]);
        } else {
            console.log("No File Selected");
        }
    };
    return (
        <div className='ImportExportBtn-container'>
            <button className='ImportExportBtn-container__import-exportBtn ' onClick={onExportFileClick}>Export
                <a style={{ display: "none" }} ref={fileExportAnchorRef} download={"testFile.waypoints"}
                    onClick={(e) => { e.stopPropagation() }}></a>
            </button>
            <button className='ImportExportBtn-container__import-exportBtn ' onClick={() => { fileInputElement.current.click() }}>Import
                <input
                    ref={fileInputElement}
                    type="file"
                    name="uploaded_file"
                    onChange={handleFileOnChange}
                    style={{ display: "none" }}
                    accept='.waypoints'
                />
            </button>
        </div>
    )
}

export default ImportExportBtn