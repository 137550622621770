import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectAllhelpContentPages, selectAllhelpContentPagesLoading, selectAllhelpContentTopics, selectAllhelpContentTopicsLoading } from './HelpContentDataSlice';
import HelpContentTopics from './HelpContentTopics';
import HelpTopicPoints from './HelpTopicPoints';
import "./HelpContentPage.scss"
const HelpContentPage = ({ contentPageId, WebsiteName }) => {

  const dispatch = useDispatch();
  // const [currentWebsiteName, setCurrentWebsiteName] = useState("Clearsky");
  // const [currentSelectedPage, setCurrentSelectedPage] = useState("Clearsky");
  const allhelpContentPages = useSelector(selectAllhelpContentPages);
  const allhelpContentPagesLoading = useSelector(selectAllhelpContentPagesLoading);
  const allhelpContentTopicsLoading = useSelector(selectAllhelpContentTopicsLoading)
  const allhelpContentTopics = useSelector(selectAllhelpContentTopics)
  const [scrollToElement, setScrollToElement] = useState(null)
  const [scrollToTopic, setScrollToTopic] = useState(null)
  // const [pageContentTopics,se]

  const [currentPageData, setCurrentPageData] = useState({ pageName: "" })

  const allTopicsContainerRef = useRef()

  useEffect(() => {
    console.log("HelpContentPage =", contentPageId)
    const data = allhelpContentPages[WebsiteName];
    if (allhelpContentPagesLoading === "done") {
      for (let i = 0; i < data.length; i++) {
        if (data[i]._id == contentPageId) {
          setCurrentPageData(data[i])
          break;
        }
      }
    }
  }, [allhelpContentPagesLoading, contentPageId])
  useEffect(() => {
    console.log("currentPageData =", currentPageData)
  }, [currentPageData])

  useEffect(() => {
    if (scrollToElement) {
      topicStartToScrollTo()
    }
  }, [scrollToElement])



  const topicStartToScrollTo = () => {
    if (scrollToTopic && scrollToElement) {
      scrollToElement.scrollIntoView({ behavior: 'smooth' })
    }

  }
  const ScrollToALlTopics = () => {
    if (allTopicsContainerRef.current) {
      allTopicsContainerRef.current.scrollIntoView({ behavior: 'smooth' })
    }

  }
  const registerTopicScrollToElement = (element) => {
    setScrollToElement(element)
  }
  const registerTopicScrollToTopic = (topic) => {
    setScrollToTopic(topic)
  }


  return (
    <>
      <div>{currentPageData.pageName}</div>
      <div className='HelpContentPage-container'>

        <div className='HelpContentPage-container__allTopics' ref={allTopicsContainerRef}>
          <HelpContentTopics pageData={currentPageData} registerScrollTo={registerTopicScrollToTopic} />
        </div>
        {allhelpContentTopicsLoading === "done" && allhelpContentTopics[currentPageData._id]?.map((topic, tIndex) => {
          return <HelpTopicPoints topic={topic} registerScrollToElement={registerTopicScrollToElement} selectedTopic={scrollToTopic} scrollToStart={ScrollToALlTopics} />
        })}
      </div>
    </>
  )
}

export default HelpContentPage