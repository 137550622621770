import React, { useEffect, useRef, useState } from 'react'
import "./FlightStatusIndicator.scss"
const FlightStatusIndicator = ({ flightData, refresh }) => {
    const statusMap = ["Flight Created", "Pre Flight Check done", "Mission Completed", "Post Flight Check done"];
    const [statusNumber, setStatusNumber] = useState(1);
    const timelineBarRef = useRef();

    useEffect(() => {
        if (flightData.isAborted) {
            setStatusNumber(5)
        }
        else if (flightData.isPostFlightChecklistCompleted) {
            setStatusNumber(4)
        }
        else if (flightData.isCompleted) {
            setStatusNumber(3)
        }
        else if (flightData.isPreFlightChecklistCompleted) {
            setStatusNumber(2)
        }

    }, [flightData, refresh])
    useEffect(() => {
        // console.log("statusNumber =", statusNumber)//12.5+ (statusNumber === 4 ? 12.5 : 0)
        timelineBarRef.current.style.width = `${(100 / (statusMap.length - 1)) * (statusNumber - 1)}%`;

    }, [statusNumber])
    // console.log(flightData)

    return (
        <div className='FlightStatusIndicator-container'>
            <div className='FlightStatusIndicator-container__timeline'>
                <div className='FlightStatusIndicator-container__timeline__bar'
                    ref={timelineBarRef}
                ></div>
            </div>
            <div className='FlightStatusIndicator-container__status-tags'>

                {statusMap.map((item, i) => {
                    if (i === 0) {
                        return <div className='FlightStatusIndicator-container__status-tags__start' style={{ left: "0px" }}>
                            <div className='FlightStatusIndicator-container__status-tags__start__circle'
                                style={{ backgroundColor: statusNumber >= i+1 ? "#FD6125" : "#FDD5C5" }}
                            ></div>
                            <div className='FlightStatusIndicator-container__status-tags__start__tag'
                                style={{ fontWeight: statusNumber >= 1 ? "500" : "400" }}
                            >{item}</div>
                        </div>
                    }
                    else if (i !== statusMap.length - 1) {
                        return <div className='FlightStatusIndicator-container__status-tags__intermediate' style={{ left: `${(100 / (statusMap.length - 1)) * i}%` }}>
                            <div className='FlightStatusIndicator-container__status-tags__intermediate__circle'
                                style={{ backgroundColor: statusNumber >= i+1 ? "#FD6125" : "#FDD5C5" }}
                            ></div>
                            <div className='FlightStatusIndicator-container__status-tags__intermediate__tag'
                                style={{ fontWeight: statusNumber >= 1 ? "500" : "400" }}
                            >{item}</div>
                        </div>
                    }
                    else {
                        return <div className='FlightStatusIndicator-container__status-tags__end' style={{ right: "0px" }}>
                            <div className='FlightStatusIndicator-container__status-tags__end__circle'
                                style={{ backgroundColor: statusNumber >= i+1 ? "#FD6125" : "#FDD5C5" }}
                            ></div>
                            <div className='FlightStatusIndicator-container__status-tags__end__tag'
                                style={{ fontWeight: statusNumber >= 1 ? "500" : "400" }}
                            >{item}</div>
                        </div>
                    }
                })}
            </div>
        </div>
    )
}

export default FlightStatusIndicator