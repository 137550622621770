import { createSlice } from '@reduxjs/toolkit'

export const ordersFilterBySlice = createSlice({
    name: 'ordersFilterBy',
    initialState: {
        value: "ALL"
    },
    reducers: {
        setOrdersFilterBy: (state, action) => {
            // console.log("setOrdersFilterBy",action.payload)
            state.value = action.payload
        }
    }
})

export const { setOrdersFilterBy } = ordersFilterBySlice.actions
// console.log(ordersFilterBySlice)
export const selectordersFilterBy = (state) => state.ordersFilterBy.value
export default ordersFilterBySlice.reducer