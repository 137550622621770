import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { VictoryArea, VictoryAxis, VictoryBrushContainer, VictoryChart, VictoryLabel, VictoryLine, VictoryScatter, VictoryTheme, VictoryZoomContainer, createContainer } from 'victory'

import './ElevationGraph.scss'

const ScatterPoint = ({ x, y, datum, index, selected, setSelected, setShowCursor }) => {

    const [hovered, setHovered] = React.useState(false);
    // console.log("point scatter data =", x, y, datum)
    return (

        <circle
            cx={x}
            cy={y}
            r={5}
            draggable="true"
            style={{ cursor: hovered ? "pointer" : "default", zIndex: 100 }}
            stroke={hovered ? "purple" : "white"}
            strokeWidth={1}

            fill={selected?.x === x && selected?.y === y ? "cyan" : "black"}
            onClick={() => {
                if (selected?.x === x && selected?.y === y) {
                    setSelected(-1)
                    setShowCursor(false)
                }
                else {
                    setSelected(index)
                    setShowCursor(true)
                }
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        />
    );
};

const VerticleLine = ({ x, y, datum, index, selected, setSelected, SizeMultiplier, cursorDatum, elevationData, updatePointElevation, mouseDown }) => {

    const [hovered, setHovered] = React.useState(false);
    useEffect(() => {
        if (selected == index && mouseDown && hovered) {
            elevationData.altitude[index].y = cursorDatum?.y
            updatePointElevation(index, cursorDatum?.y)

        }
        if (mouseDown) {
            // console.log("cursorDatum", cursorDatum)
        }


    }, [cursorDatum?.y])

    return (

        <line
            x1={x}
            y1={0}
            x2={x}
            y2={550 * SizeMultiplier['--heightmultiplier']}
            z={5}
            style={{ cursor: hovered || mouseDown ? "pointer" : "default" }}

            draggable="true"
            stroke={hovered ? "purple" : "white"}
            strokeWidth={5 * SizeMultiplier['--heightmultiplier']}
            display={selected === index ? "inline" : "none"}
            fill={selected === index ? "cyan" : "black"}
            onClick={() => {
                elevationData.altitude[index] = { ...elevationData.altitude[index], x: datum?.x, y: cursorDatum?.y }
                updatePointElevation(index, cursorDatum?.y)
                console.log("cursorDatum", cursorDatum)
            }}
            onMouseEnter={() => {
                setHovered(true); console.log("point scatter data =", x, y, datum)
            }}
            onMouseLeave={() => setHovered(false)}
        />
    );
};

const VictoryZoomCurserContainer = createContainer("zoom", "cursor");

const ElevationGraph = ({
    keyPressed,
    setKeyPressed,
    elevationData,
    range,
    setElevationGraphVisibility,
    getTerrainElevation,
    updatePointElevation,
    addPointAt,
    defaultFrame,
    isControlKeyPressed, relatedAltEle, setRelatedAltEle }) => {

    const [mouseButtonDown, setMouseButtonDown] = useState(false)
    const [selected, setSelected] = useState(-1);
    const [zoomDomain, setZoomDomain] = useState(range);
    const [showCursor, setShowCursor] = useState(false);
    const [cursorDatum, setCursorDatum] = useState({});
    // console.log(range)
    const dimentionMultiplier = 100;
    const [SizeMultiplier, setSizeMultiplier] = useState({
        "--widthmultiplier": (parseInt(window.innerWidth * dimentionMultiplier / 1920) / dimentionMultiplier),
        "--heightmultiplier": (parseInt(window.innerHeight * dimentionMultiplier / 1080) / dimentionMultiplier),
        height: window.innerHeight.toString() + "px",
        width: window.innerWidth.toString() + "px"
    });

    // useEffect(() => {
    //     let minElevation = Infinity;
    //     let maxElevation = -Infinity;
    //     const homeAlt = elevationData.terrain[0]?.y;
    //     console.log("homeAlt",homeAlt)
    //     if (homeAlt) {
    //         elevationData.altitude.forEach((point, i) => {
    //             if (point.alt + homeAlt > maxElevation) {
    //                 maxElevation = point.alt + homeAlt
    //             }
    //             if (point.alt + homeAlt < minElevation) {
    //                 minElevation = point.alt + homeAlt
    //             }
    //         })
    //         setZoomDomain({ x: [-1000, elevationData.altitude[elevationData.altitude.length - 1]?.x + 1000], y: [minElevation - 100, maxElevation + 200] })
    //     }

    // }, [elevationData])
    useLayoutEffect(() => {
        function handleResize() {
            let calFontHeight = window.innerHeight.toString() + "px";
            let calFontWidth = window.innerWidth.toString() + "px";
            let calMulHeight = (parseInt(window.innerHeight * dimentionMultiplier / 1080) / dimentionMultiplier);
            let calmulWidth = (parseInt(window.innerWidth * dimentionMultiplier / 1920) / dimentionMultiplier);
            setSizeMultiplier({ "--widthmultiplier": calmulWidth, "--heightmultiplier": calMulHeight, height: calFontHeight, width: calFontWidth });
            console.log(calmulWidth, calMulHeight);
            console.log(calFontHeight, calFontWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {

        console.log("keyDownAction", keyPressed,selected)
        if (keyPressed === "ArrowUp" && selected !== -1) {
            elevationData.altitude[selected].y = elevationData.altitude[selected].y + 0.5
            updatePointElevation(selected, elevationData.altitude[selected].y + 0.5)
        }
        else if (keyPressed === "ArrowDown" && selected !== -1) {
            elevationData.altitude[selected].y = elevationData.altitude[selected].y - 0.5
            updatePointElevation(selected, elevationData.altitude[selected].y - 0.5)
        }
        // setKeyPressed(null)

    }, [keyPressed])

    useEffect(() => {
        setZoomDomain(range)
    }, [range])

    // useEffect(() => {
    //     if(selected?.x===null)
    //     setShowCursor(false);
    //     else
    //     setShowCursor(true)
    // }, [selected])



    // const [isControlKeyPressed, SetIsControlKeyPressed] = useState(false)
    // useEffect(() => {
    //     const OnKeyPressedDown = (event) => {
    //         var name = event.key;
    //         var code = event.code;
    //         // Alert the key name and key code on keydown
    //         // alert(`Key pressed ${name} \r\n Key code value: ${code}`);
    //         if (name == "Control" || name == "Command") {
    //             SetIsControlKeyPressed(true);
    //         }
    //         else {
    //             SetIsControlKeyPressed(false);
    //         }
    //     }
    //     const OnKeyPressedRelease = (event) => {
    //         var name = event.key;
    //         var code = event.code;
    //         // Alert the key name and key code on keydown
    //         // alert(`Key pressed ${name} \r\n Key code value: ${code}`);
    //         if (name === "Control") {
    //             SetIsControlKeyPressed(false);
    //         }
    //     }
    //     window.addEventListener('keydown', OnKeyPressedDown);
    //     window.addEventListener('keyup', OnKeyPressedRelease);
    //     return () => {
    //         window.removeEventListener('keydown', OnKeyPressedDown);
    //         window.removeEventListener('keyup', OnKeyPressedRelease);
    //     }
    // })
    const getColorForLine = (start, end) => {
        let x = end?.x - start?.x;
        let y = end?.y - start?.y;
        let angle = Math.atan(y / x) * (180 / Math.PI)
        // console.log(angle)
        if (Math.abs(angle) > 10) return "#FF0000"
        else if (Math.abs(angle) > 6) return "yellow"
        return "green"
    }
    const [elevationDiff, setElevationDiff] = useState([]);
    useEffect(() => {
        if (elevationData.terrain.length) {
            const eleDiff = []
            let k = 0;
            const ele = elevationData.terrain;
            const alt = elevationData.altitude;
            // console.log("elevationData =", elevationData)
            for (let i = 0; i < elevationData.terrain.length; i++) {
                if (ele[i].lat === alt[k].lat && ele[i].lng === alt[k].lng) {
                    eleDiff.push([{ x: alt[k]?.x, y: ele[i]?.y }, { x: alt[k]?.x, y: alt[k]?.y }])
                    k++;
                }
            }
            // console.log("eleDiff =", eleDiff)
        }

    }, [elevationData.terrain, elevationData.altitude])


    // console.log([elevationData.altitude[0], [elevationData.altitude[1]]])

    // const innerRangex = useMemo(()=>{
    //     return range.x
    // })
    // const innerRangey = useMemo(()=>{
    //     return range.y
    // })
    return (
        <div className='ElevationGraph-container'>
            <div className='ElevationGraph-container__elevationgraph'
                onMouseDown={() => { setMouseButtonDown(true) }}
                onMouseUp={async () => {
                    setSelected(-1);
                    setMouseButtonDown(false);
                    if (isControlKeyPressed) {
                        const dist = parseInt(cursorDatum?.x)
                        let startIndex = parseInt(cursorDatum?.x / 100) - 2;
                        const endIndex = parseInt(cursorDatum?.x / 100) + 2;
                        let closest = {}
                        while (startIndex <= endIndex && elevationData.terrain[startIndex]?.x <= dist) {

                            console.log("newpoint", dist, startIndex, elevationData.terrain[startIndex]?.x)
                            startIndex++;
                        }
                        let pointIndex = 0
                        for (let i = 0; i < elevationData.altitude.length - 1; i++) {
                            if (elevationData.altitude[i]?.x <= dist && elevationData.altitude[i + 1]?.x >= dist) {
                                pointIndex = i + 1;
                                console.log(elevationData.altitude[i]?.x, dist, elevationData.altitude[i + 1]?.x)
                                break;
                            }
                            console.log("index", i, pointIndex)
                            console.log(elevationData.altitude[i], dist, elevationData.altitude[i + 1])
                        }
                        await addPointAt({
                            cmd: "WAYPOINT",
                            p1: 0,
                            p2: 0,
                            p3: 0,
                            yaw: 0,
                            lat: elevationData.terrain[startIndex].lat,
                            long: elevationData.terrain[startIndex].lng,
                            alt: Math.round(cursorDatum?.y - elevationData.terrain[0]?.y, 1),
                            frame: defaultFrame
                        }, pointIndex);
                        console.log("old", relatedAltEle)
                        const newRel = [...relatedAltEle.slice(0, pointIndex), startIndex, ...relatedAltEle.slice(pointIndex)]
                        console.log("new", newRel)
                        await setRelatedAltEle(newRel)


                    }
                }}
                style={{ cursor: mouseButtonDown || isControlKeyPressed ? "pointer" : "default" }}>
                <VictoryChart
                    width={1200 * SizeMultiplier['--widthmultiplier']}
                    height={550 * SizeMultiplier['--heightmultiplier']}
                    padding={{ top: 50, left: 50, right: 50, bottom: 30 }}
                    style={{ width: "100%" }}
                    theme={VictoryTheme.material}
                    scale={{ x: "linear", y: "linear" }}
                    domain={range}

                    containerComponent={
                        <VictoryZoomCurserContainer
                            cursorLabel={({ datum }) => {
                                setCursorDatum(datum)
                                return showCursor ? `${Math.round(datum?.x, 2)}m, alt=${Math.round(datum?.y, 2)}m` : ""
                            }}
                            cursorDimension=""
                            responsive={false}
                            zoomDimension="x"
                            zoomDomain={zoomDomain}
                            onZoomDomainChange={(data) => {
                                // console.log(data?.x);
                                setZoomDomain(data)
                            }}
                        />


                    }
                >

                    <VictoryAxis
                        // orientation='bottom'
                        tickFormat={(x) => `${x}m`}
                        theme={VictoryTheme.material}

                    />
                    <VictoryAxis
                        dependentAxis
                        tickFormat={(y) => `${y}m`}
                        theme={VictoryTheme.material}
                        style={{
                            axisLabel: { fontSize: 15, padding: 10, color: "black" },
                        }}
                        label={"Altitude(m)"}
                    />
                    <VictoryArea
                        style={{
                            data: { fill: "#fd6125" }
                        }}
                        // interpolation ={"monotoneX"}

                        data={elevationData.terrain}
                    />

                    <VictoryArea
                        style={{
                            data: { stroke: "tomato", opacity: 0.5 }
                        }}
                        // labels={({ datum }) => parseInt(datum?.y)}
                        data={elevationData.altitude}

                    />
                    {elevationData.altitude.map((dat, i) => {
                        if (i > 0) {

                            return <VictoryLine
                                style={{
                                    data: {
                                        stroke: getColorForLine(elevationData.altitude[i - 1], elevationData.altitude[i]),
                                    }
                                }}
                                // interpolation ={"monotoneX"}

                                data={[elevationData.altitude[i - 1], elevationData.altitude[i]]}
                            />


                        }
                        return null
                    })}
                    {elevationData.terrain.length !== 0 && elevationData.altitude !== 0 && relatedAltEle.map((dat, i) => {
                        // console.log(dat, i, [
                        //     { x: elevationData.altitude[i]?.x, y: elevationData.terrain[dat]?.y },
                        //     { x: elevationData.altitude[i]?.x, y: elevationData.altitude[i]?.y },relatedAltEle
                        // ])
                        return <VictoryLine
                            style={{
                                data: {
                                    stroke: "red"
                                }
                            }}
                            // interpolation ={"monotoneX"}

                            data={[
                                { x: elevationData.altitude[i]?.x, y: elevationData.terrain[dat]?.y },
                                { x: elevationData.altitude[i]?.x, y: elevationData.altitude[i]?.y },
                            ]}
                        />
                    })}
                    {elevationData.terrain.length !== 0 && relatedAltEle.map((dat, i) => {
                        // console.log(dat, i, [
                        //     { x: elevationData.altitude[i]?.x, y: elevationData.terrain[dat]?.y },
                        //     { x: elevationData.altitude[i]?.x, y: elevationData.altitude[i]?.y },relatedAltEle
                        // ])
                        return <VictoryScatter
                            labels={parseInt(elevationData.altitude[i]?.y - elevationData.terrain[dat]?.y) + "m"}
                            style={{
                                labels: { color: "white" },
                                data: { fill: "none" }
                            }}
                            labelComponent={
                                <VictoryLabel angle={0} textAnchor="start" dx={2} verticalAnchor={"start"}
                                    style={{ fill: "white", fontSize: 20 * SizeMultiplier['--heightmultiplier'] }} />
                            }

                            data={[
                                { x: elevationData.altitude[i]?.x, y: elevationData.terrain[dat]?.y + (elevationData.altitude[i]?.y - elevationData.terrain[dat]?.y) / 2 },
                            ]}
                        />
                    })}
                    <VictoryAxis
                        orientation='top'
                        tickValues={[...elevationData.altitude.map((point) => { return Math.round(point?.x) })]}
                        style={{
                            axis: { stroke: "yellow" },
                            tickLabels: { fill: "black" },
                            axisLabel: { fontSize: 20, padding: 30, color: "black" },
                            grid: { stroke: "none" },
                            ticks: { stroke: "yellow" },
                            // tickLabels: { fontSize: 15, padding: 5 }
                        }}
                        axisValue={range?.y[1]}
                        label={"Distance(m)"}
                    />
                    {/* {elevationData.altitude.map((dat, i) => {
                        if (i > 0 && i < elevationData.altitude.length - 1) {
                            return <VictoryAxis dependentAxis
                                // label={d}
                                // style={{ tickLabels: { fill: "none" } }}
                                style={{
                                    axis: { stroke: "yellow" },
                                    tickLabels: { fill: "none" },
                                    // axisLabel: { fontSize: 20, padding: 5, transform: "rotate(90px)", color: "white" },
                                    grid: { stroke: "none" },
                                    // ticks: { stroke: "none" },
                                    // tickLabels: { fontSize: 15, padding: 5 }
                                }}
                                // label={parseInt(dat?.x)}
                                axisValue={dat?.x}

                            />
                        }
                        return null
                    })} */}

                    <VictoryScatter
                        data={elevationData.altitude}

                        // size={5}
                        // style={{ zIndex:1000}}
                        labels={({ datum }) => parseInt(datum?.y) + "m"}
                        dataComponent={
                            <VerticleLine selected={selected}
                                setSelected={setSelected}
                                SizeMultiplier={SizeMultiplier}
                                elevationData={elevationData}
                                cursorDatum={cursorDatum}
                                updatePointElevation={updatePointElevation}
                                mouseDown={mouseButtonDown} />

                        }
                    />
                    <VictoryScatter
                        data={elevationData.altitude}

                        // size={5}
                        // style={{ zIndex:1000}}
                        labels={({ datum }) => parseInt(datum?.y) + "m"}
                        dataComponent={
                            <ScatterPoint
                                selected={selected} s
                                setSelected={setSelected}
                                SizeMultiplier={SizeMultiplier}
                                setShowCursor={setShowCursor} />
                        }
                    />




                </VictoryChart >

            </div>

            <div className='ElevationGraph-container__elevationbrush'>
                <VictoryChart
                    padding={{ top: 0, left: 50, right: 50, bottom: 50 }}
                    width={800} height={100} scale={{ x: "x" }}
                    domain={range}
                    theme={VictoryTheme.material}
                    containerComponent={
                        <VictoryBrushContainer
                            brushDimension="x"
                            brushDomain={zoomDomain}
                            onBrushDomainChange={(data) => {
                                // console.log(data?.x);
                                setZoomDomain(data)
                            }}
                        />
                    }
                >
                    <VictoryAxis
                        tickFormat={(x) => `${x}m`}
                    />
                    <VictoryLine
                        style={{
                            data: { stroke: "#fd6125" }
                        }}
                        data={elevationData.terrain}
                    // x="x"
                    // y="y"
                    />
                    <VictoryLine
                        style={{
                            data: { stroke: "black" }
                        }}
                        data={elevationData.altitude}
                    // x="x"
                    // y="y"
                    />
                </VictoryChart>
            </div>
            <div className='ElevationGraph-container__buttons'>
                <button className='ElevationGraph-container__buttons__terrainBtn'
                    onClick={() => { getTerrainElevation() }}
                >Get Terrain</button>
                <button className='ElevationGraph-container__buttons__closeBtn'
                    onClick={() => { setElevationGraphVisibility(false) }}
                >X</button>
            </div>
        </div >
    )
}

export default ElevationGraph